import {
  List,
  Avatar,
  Button,
  Form,
  Col,
  Select,
  Popconfirm,
  message,
  Input,
  Row,
  Pagination,
  Popover,
  Tooltip,
  Checkbox,
  Alert,
} from "antd";
import { AddCircle } from "@styled-icons/fluentui-system-filled/AddCircle";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";
import { Delete } from "@styled-icons/fluentui-system-regular/Delete";
import { Copy } from "@styled-icons/fa-regular/Copy";
import React, { useContext, useState } from "react";
import { Table, Radio, Divider, Modal } from "antd";
import { MoreVerticalOutline } from "@styled-icons/evaicons-outline/MoreVerticalOutline";
import {
  CopyContractDetails,
  deleteBrandDetails,
  deleteContractDetails,
  getAllContractsList,
} from "./service-helper";
import { getAllProductDetailsData } from "../../services/SimulatorConfiguration";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../../store";
import AppContext2 from "../../store/index2";
import background from "../../globalTheme/background2.jpg";
import "../Landing/index.css";
import SimpleTable from "../../libraries/TableMod";

const { Option } = Select;

const columns = [
  {
    title: <span className="text-base">Contract Name</span>,
    dataIndex: "contractName",
  },
  {
    title: <span className="text-base">Start Date</span>,
    dataIndex: "startDate",
  },
  {
    title: <span className="text-base">End Date</span>,
    dataIndex: "endDate",
  },
  {
    title: <span className="text-base">Product Name</span>,
    dataIndex: "productName",
  },
  {
    title: <span className="text-base">Country</span>,
    dataIndex: "country",
  },
  {
    title: <span className="text-lg">Action</span>,
    dataIndex: "action",
  },
];

const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {},
  getCheckboxProps: (record: any) => ({
    disabled: record.name === "Disabled User", // Column configuration not to be checked
    name: record.name,
  }),
};

export default function ContractsList() {
  const context = React.useContext(AppContext);
  const context2 = React.useContext(AppContext2);
  let navigate = useNavigate();
  const [fetchedData, setFetchedData] = React.useState<any>();
  const [listOfProducts, setListOfProducts] = React.useState<any>();
  const [contractIdForCopy, setContractIdForCopy] = React.useState(null);

  const FetchData = async () => {
    const contractList: any = await getAllContractsList();
    let data = await getAllProductDetailsData();
    setListOfProducts([...data]);
    // console.log("Contract List and Data:", contractList, data);
    setFetchedData(contractList);
  };

  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  React.useEffect(() => {
    FetchData();
  }, []);

  React.useEffect(() => {
    // FetchData();
  }, [fetchedData]);

  //delete contract
  const confirm = async (contractId: any) => {
    console.log(contractId);
    let data: any = await deleteContractDetails(contractId);

    if (data) {
      const data2 = await getAllContractsList();
      setFetchedData(data2);
      message.info("Successfully deleted");
    } else {
      message.info("Deletion Failed!");
    }
  };

  const getDateInFormat = (dateTime: any) => {
    let date: any = dateTime.substring(0, 10);
    let datePart = date.match(/\d+/g),
      year = datePart[0].substring(0, 4), // get only two digits
      month = datePart[1],
      day = datePart[2];

    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // function dateFormat(d) {
    //   var t = new Date(d);
    //   return t.getDate() + "-" + monthNames[t.getMonth()] + "-" + t.getFullYear();
    // }
    console.log(month.substring(0, 1));

    if (month.substring(0, 1) === "0") {
      month = month.substring(1, 2);
    }
    console.log(day + "-" + monthNames[month] + "-" + year, month);
    return day + "-" + monthNames[month] + "-" + year;
  };

  let dataShownForContractList: any = [];
  const [dataSource, setDataSource] = React.useState(dataShownForContractList);

  React.useEffect(() => {
    if (fetchedData) {
      fetchedData.map((d: any) => {
        let contractObj: any = {};
        contractObj.contractName = d.name;
        contractObj.startDate = getDateInFormat(d.startDate);
        contractObj.endDate = getDateInFormat(d.endDate);
        contractObj.productName = d.product_Name;
        contractObj.country = d.country;

        console.log(d);

        contractObj.action = (
          <>
            <Button
              className="mr-20 mt-2"
              onClick={() => contractPerformance(d.name)}
              type="primary"
            >
              View Performance
            </Button>
            <Popover
              placement="topLeft"
              content={
                <>
                  <Tooltip placement="topLeft" title="Edit ">
                    <Button
                      type="link"
                      // className=" mr-6"
                      key={"edit_" + d.id}
                      shape="circle"
                      onClick={() => {
                        handleClick(d.id, d.product_Name);
                      }}
                      icon={
                        <EditOutline
                          style={{ color: "black" }}
                          key={"edit_" + d.id}
                          className="mb-1"
                          size={22}
                        />
                      }
                    />
                  </Tooltip>
                  <Popconfirm
                    placement="top"
                    title={"Are you sure you want to delete this contract?"}
                    onConfirm={() => {
                      confirm(d.id);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip placement="topLeft" title="Delete ">
                      <Button
                        danger
                        type="link"
                        className=""
                        key={"delete_" + d.id}
                        shape="circle"
                        icon={<Delete key={"delete_" + d.id} className="mb-1" size={22} />}
                      />
                    </Tooltip>
                  </Popconfirm>
                  {/* <Copy
              onClick={() => copyContract(d.id)}
              key={"copy_" + d.id}
              className="ml-4"
              size={18}
            /> */}
                  <Tooltip placement="topLeft" title="Copy ">
                    <Button
                      type="link"
                      // className="ml-8 mr-6"
                      key={"copy_" + d.id}
                      shape="circle"
                      onClick={() => copyContract(d.id)}
                      icon={
                        <Copy
                          style={{ color: "black" }}
                          key={"edit_" + d.id}
                          className="mb-1"
                          size={18}
                        />
                      }
                    />
                  </Tooltip>
                </>
              }
            >
              <Button type="link" icon={<MoreVerticalOutline />}></Button>
            </Popover>
          </>
        );
        contractObj.key = d.id;
        dataShownForContractList.push(contractObj);
      });
      setDataSource(dataShownForContractList);
    }
  }, [fetchedData]);

  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">("checkbox");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [pageNo, setPageNo] = useState<any>(0);

  const showModal = async () => {
    setIsModalVisible(true);
  };

  const showModal2 = async () => {
    setIsModalVisible2(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleOk2 = () => {
    setIsModalVisible2(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };

  context.setBrandId(null);

  const onFinish = (values: any) => {
    handleOk();
    context2.setBrandId(values.productName);
    console.log(values);
    navigate("/contract-input", {
      state: { productId: values.productName, country: "US", country_ID: values.country },
    });
    context.setContractId(null);
  };

  const handleClick = (contractId: any, productId: any) => {
    console.log("Btn clicked!");

    navigate("/contract-input", {
      state: { productId: productId, country: "US", country_ID: "abc123", contractId: contractId },
    });

    context.setContractId(contractId);
  };

  const handleChange = (value: string) => {
    // console.log(`selected ${value}`);
  };

  const contractPerformance = (name: any) => {
    navigate("/contract-performance", {
      state: { name: name },
    });
  };

  const copyContract = (contractId: any) => {
    showModal2();
    setContractIdForCopy(contractId);
  };

  const [form] = Form.useForm();

  const warning = () => {
    Modal.warning({
      title: "Can only compare 2 contracts at a time!",
      // content: "Uncheck contracts to continue",
    });
  };

  const handleFinish = async (values: any) => {
    // console.log(values);
    let data: any = await CopyContractDetails(contractIdForCopy, values.contractName);
    if (data) {
      const data2 = await getAllContractsList();
      context.setTableData(data2);
      message.info("Successfully Copied Product!");
    } else {
      message.info("Copy Failed!");
    }
    setIsModalVisible2(false);
  };

  const handlePageChange = (e: any) => {
    setPageNo(e);
  };

  // React.useEffect(() => {}, [pageNo]);

  const checkBoxChange = (e: any) => {
    // if (pageNo < 2) {
    //   setPageNo(pageNo + 1);
    // }

    if (e.target.checked === true) {
      setPageNo(pageNo + 1);
    }
    if (e.target.checked === false) {
      setPageNo(pageNo - 1);
    }
  };

  React.useEffect(() => {
    if (pageNo > 2) {
      warning();
    }
  }, [pageNo]);

  return (
    <>
      {dataSource && (
        <div
          style={{
            height: "95vh",
            // background: "white",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            marginLeft: "1rem",
            marginRight: "1rem",
            marginTop: "1rem",
          }}
          className="back"
        >
          {console.log(pageNo)}
          <div>
            <Modal
              title="Copy Contract"
              visible={isModalVisible2}
              onOk={handleOk2}
              onCancel={handleCancel2}
              footer={[
                <Button form="copyForm" className="mr-2" onClick={handleCancel2}>
                  Cancel
                </Button>,
                <Button type="primary" form="copyForm" key="submit" htmlType="submit">
                  Submit
                </Button>,
              ]}
            >
              <Form layout="vertical" form={form} name="copyForm" onFinish={handleFinish}>
                <Form.Item name="contractName" label="Contract Name">
                  <Input placeholder="" />
                </Form.Item>
              </Form>
            </Modal>
          </div>
          <div>
            <h4 className="ml-12 pt-12 text-2xl" style={{ fontWeight: "700" }}>
              All Contracts
            </h4>
            {listOfProducts && (
              <Modal
                title="Create new contract"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                  <Button form="myForm" className="mr-2" onClick={handleCancel}>
                    Cancel
                  </Button>,
                  <Button type="primary" form="myForm" key="submit" htmlType="submit">
                    Submit
                  </Button>,
                ]}
              >
                <Form
                  className="contract-form"
                  layout="vertical"
                  id="myForm"
                  name="newContract"
                  onFinish={onFinish}
                >
                  <Form.Item
                    required
                    rules={[{ required: true, message: "Please select a product!" }]}
                    name="productName"
                    label="Select Product"
                  >
                    <Select
                      placeholder=""
                      allowClear
                      className="float-left"
                      style={{ width: 210 }}
                      onSelect={handleChange}
                    >
                      {listOfProducts.map(
                        (x: any) => (
                          // x.isProductSetup && (
                          <Option key={x.productId} value={x.productId}>
                            {x.productName}
                          </Option>
                        )
                        // )
                      )}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    required
                    rules={[{ required: true, message: "Please select a country!" }]}
                    name="country"
                    label="Select Country"
                  >
                    <Select
                      placeholder=""
                      allowClear
                      className="float-left"
                      style={{ width: 210 }}
                      onSelect={handleChange}
                    >
                      <Option value="abc123">Italy</Option>
                      <Option value="def123">Canada</Option>
                      <Option value="ghi123">Croatia</Option>
                      <Option value="jkl123">France</Option>
                    </Select>
                  </Form.Item>
                </Form>
              </Modal>
            )}

            <Radio.Group
              onChange={({ target: { value } }) => {
                setSelectionType(value);
              }}
              value={selectionType}
            ></Radio.Group>
            <div>
              <Link to={"/contract-comparison"}>
                <Button
                  style={{ float: "right", marginRight: "4rem", marginTop: "-4rem" }}
                  // onClick={compareContract}
                  disabled={pageNo > 1 && pageNo <= 2 ? false : true}
                  className="ml-12"
                  type="primary"
                >
                  Compare
                </Button>
              </Link>

              {/* <Table
              size="small"
              rowSelection={{
                hideSelectAll: true,
                type: selectionType,
                ...rowSelection,
              }}
              style={{ padding: "2rem" }}
              columns={columns}
              pagination={{ pageSize: 8 }}
              dataSource={dataSource}
            /> */}

              {dataSource.map((i: any, index: any) => (
                <div
                  className={`font-lato border-l-8  rounded-md border-dashboard-blue} w-full my-2`}
                  style={{
                    background: "#D7EFFF",
                    borderColor: "#27a6a4",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    height: "4.5rem",
                    width: "97%",
                  }}
                >
                  <div className="w-full grid grid-cols-12 p-2 items-center">
                    <div className="col-span-3">
                      <div className="w-full flex items-center justify-left gap-2">
                        <div className="w-1/10 text-zsYellow button-favourite"></div>
                        <div className="w-9/10">
                          <div
                            className="text-xl font-bold text-zsBlack80"
                            style={{ color: "rgb(71, 69, 84)" }}
                          >
                            <Checkbox
                              onChange={checkBoxChange}
                              key={index}
                              // disabled = {pageNo ?}
                              // checked={item.value == value}
                              // value={item.value}
                            ></Checkbox>
                            <span className="ml-4">{i.contractName}</span>
                            {/* </Link> */}
                          </div>
                          <div
                            className="text-xs text-zsBlack50 leading-6"
                            style={{ color: "#888888" }}
                          >
                            {/* {i.productName} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="grid grid-cols-4 px-2">
                        <div className="text-center">
                          <div className="text-zsBlack50 text-xs" style={{ color: "#888888" }}>
                            Start Date
                          </div>
                          <div
                            className="text-zsBlack60 text-base font-semibold"
                            style={{ color: "#75737d" }}
                          >
                            {i.startDate}
                            {/* {props.item.lotId} */}
                          </div>
                        </div>
                        <div className="text-center">
                          <div className="text-zsBlack50 text-xs" style={{ color: "#888888" }}>
                            End Date
                          </div>
                          <div
                            className="text-zsBlack60 text-base font-semibold"
                            style={{ color: "#75737d" }}
                          >
                            {i.endDate}
                          </div>
                        </div>
                        <div className="text-center">
                          <div className="text-zsBlack50 text-xs" style={{ color: "#888888" }}>
                            Product Name
                          </div>
                          <div
                            className="text-zsBlack60 text-base font-semibold"
                            style={{ color: "#75737d" }}
                          >
                            {i.productName}
                          </div>
                        </div>
                        <div className="text-center">
                          <div className="text-zsBlack50 text-xs" style={{ color: "#888888" }}>
                            Country
                          </div>
                          <div
                            className="text-zsBlack60 text-base font-semibold"
                            style={{ color: "#75737d" }}
                          >
                            Italy
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 text-right" style={{ width: "17rem" }}>
                      {i.action}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Button
            className="float-left ml-8 mt-8"
            type="primary"
            onClick={() => {
              showModal();
            }}
            icon={
              <AddCircle
                className="float-left mr-4"
                size={30}
                style={{ marginTop: "-2px" }}
                onClick={() => context.setCurrent(0)}
                // color={"#27a6a4"}
              />
            }
            size="large"
          >
            Add New Contract
          </Button>
          <Pagination
            style={{ marginRight: "3rem" }}
            className="float-right mt-4"
            defaultCurrent={1}
            onChange={handlePageChange}
            total={10}
          />
        </div>
      )}
    </>
  );
  // );
}
