import React, { useRef, useEffect } from "react";
import jspreadsheet from "jspreadsheet-ce";
import "../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
// import "../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
import "../../../node_modules/jsuites/dist/jsuites.css";

import "../KmCurveData/index.css";
import { Button, Input } from "antd";
import AppContext from "../../store";

export default function KmCurveData(props: {
  KMCurve1: string;
  KMCurve2: string;
  KMCurve3: string;
  kmData: any;
  handleInputChange: any;
  indication: any;
  subpop: any;
  id?: any;
  data: any;
  onClose?: any;
}) {
  let options: any = {};
  const jRef: any = useRef(null);

  const [random, setRandom] = React.useState(1);

  let context = React.useContext(AppContext);
  useEffect(() => {
    setRandom(random + 1);

    options = {
      data: props.data,
      minDimensions: [1, 1],
      columns: [
        { title: "Month", readOnly: true },
        { title: "1" },
        { title: "2" },
        { title: "3" },
        { title: "4" },
        { title: "5" },
        { title: "6" },
        { title: "7" },
        { title: "8" },
        { title: "9" },
        { title: "10" },
        { title: "11" },
        { title: "12" },
        { title: "13" },
        { title: "14" },
        { title: "15" },
        { title: "16" },
        { title: "17" },
        { title: "18" },
        { title: "19" },
        { title: "20" },
        { title: "21" },
        { title: "22" },
        { title: "23" },
        { title: "24" },
        { title: "25" },
        { title: "26" },
        { title: "27" },
        { title: "28" },
        { title: "29" },
        { title: "30" },
        { title: "31" },
        { title: "32" },
        { title: "33" },
        { title: "34" },
        { title: "35" },
        { title: "36" },
        { title: "37" },
        { title: "38" },
        { title: "39" },
        { title: "40" },
      ],
      columnSorting: false,
      search: false,
      filters: false,
      allowComments: false,
      allowManualInsertColumn: true,
      allowManualInsertRow: false,
      tableOverflow: true,
      tableWidth: "90rem",

      contextMenu: function (obj: any, x: any, y: any, e: any) {
        var items = [];

        if (y == null) {
          // Insert a new column
          if (obj.options.allowInsertColumn == true) {
            items.push({
              title: obj.options.text.insertANewColumnBefore,
              onclick: function () {
                obj.insertColumn(1, parseInt(x), 1);
              },
            });
          }

          if (obj.options.allowInsertColumn == true) {
            items.push({
              title: obj.options.text.insertANewColumnAfter,
              onclick: function () {
                obj.insertColumn(1, parseInt(x), 0, [{ title: parseInt(x) + 1 }]);
              },
            });
          }

          // Delete a column
          if (obj.options.allowDeleteColumn == true) {
            items.push({
              title: obj.options.text.deleteSelectedColumns,
              onclick: function () {
                obj.deleteColumn(obj.getSelectedColumns().length ? undefined : parseInt(x));
              },
            });
          }

          // Rename column
          if (obj.options.allowRenameColumn == true) {
            items.push({
              title: obj.options.text.renameThisColumn,
              onclick: function () {
                obj.setHeader(x);
              },
            });
          }
        }

        // Line
        items.push({ type: "line" });

        // Save
        if (obj.options.allowExport) {
          items.push({
            title: obj.options.text.saveAs,
            shortcut: "Ctrl + S",
            onclick: function () {
              obj.download();
            },
          });
        }

        return items;
      },
    };
    //@ts-ignore

    if (!jRef.current.jspreadsheet) {
      //@ts-ignore
      jspreadsheet(jRef.current, options);
    }
  }, [props.KMCurve1, props.KMCurve2, props.KMCurve3]);

  const [x, setX] = React.useState(40);
  const addColumn = () => {
    //@ts-ignore

    jRef.current.jexcel.insertColumn(1, parseInt(x), 0, [{ title: parseInt(x) + 1 }]);

    setX(x + 1);
  };

  const insert = () => {
    const x: any = jRef.current.jexcel.getJson();
    console.log("Saved Excel Data format:", x);
    let values: any = [...props.kmData];
    props.handleInputChange(props.indication, props.subpop, x);
    props.onClose();
    // context.setDosingData(x);
  };

  return (
    <div>
      <p className="italic">Enter values for KM Curve corresponding to each month index:</p>
      <br />
      <div id={props.id} key={props.id} ref={jRef} />
      <div>
        <br />
        <br />

        <Button onClick={insert}>Save</Button>
      </div>
    </div>
  );
}
