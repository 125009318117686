import { request } from "../layout";

export const checkLoginData = async (credentials: { username: any; password: any }) => {
  const fetchUrl = "https://api.icp.dev.zsservices.com/Home/Authentication";
  // const sessionItem: any = window.sessionStorage.getItem("login");
  // const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const data = await request
    .post(fetchUrl, credentials, {
      headers: {
        // "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
        "Content-Type": "application/json",
        // "user-details": loginDetails,
      },
    })
    .then((response) => {
      console.log("Response...", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};
