import React, { useEffect, useState } from "react";
import {
  ArrowsAltOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  SaveOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";

import "./style.css";
import { AddCircleOutline } from "@styled-icons/material/AddCircleOutline";
import { Sort } from "@styled-icons/material-rounded/Sort";
import { Cross } from "@styled-icons/entypo/Cross";
import { Button, Col, message, Modal, Row, Select, Table } from "antd";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "antd";
import ReactMarkdown from "react-markdown";
// import { editTableData } from "../../../../../services/supportingMessage";

/**
 * Renders the Responsive Table Component. The component makes use of react-table hooks.
 *
 * @@remarks
 * It is mandatory to assign height to the Container Element of this Component, else nothing will be rendered.
 *
 * @returns Table Component
 */

interface LooseObject {
  [key: string]: any;
}

const contentStyle: React.CSSProperties = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const RightArrow = () => {
  return <Button icon={<RightCircleOutlined style={{ fontSize: "2rem" }} />} size="middle" />;
};

const LeftArrow = () => {
  return <Button icon={<LeftCircleOutlined style={{ fontSize: "2rem" }} />} size="middle" />;
};

const SimpleTable = (props: {
  tableData?: any;
  rows?: number;
  addButton?: any;
  editFunction?: any;
  saveFunction?: any;
  hideActions?: boolean[];
  deleteColumn?: any;
  tableColumns?: any;
}) => {
  // const columns = React.useMemo(
  // 	() => props.tableData.column,
  // 	[props.tableData.column]
  // );
  // const data = React.useMemo(
  // 	() => props.tableData.data,
  // 	[props.tableData.data]
  // );
  const columnsForMonthlyDistribution: any = [
    {
      title: "Year",
      dataIndex: "year",
    },
    {
      title: "Jan",
      dataIndex: "jan",
    },
    {
      title: "Feb",
      dataIndex: "feb",
    },
    {
      title: "Mar",
      dataIndex: "mar",
    },
    {
      title: "Apr",
      dataIndex: "apr",
    },
    {
      title: "May",
      dataIndex: "may",
    },
    {
      title: "Jun",
      dataIndex: "jun",
    },
    {
      title: "Jul",
      dataIndex: "jul",
    },
    {
      title: "Aug",
      dataIndex: "aug",
    },
    {
      title: "Sep",
      dataIndex: "sep",
    },
    {
      title: "Oct",
      dataIndex: "oct",
    },
    {
      title: "Nov",
      dataIndex: "nov",
    },
    {
      title: "Dec",
      dataIndex: "dec",
    },
    {
      title: "Sum",
      dataIndex: "sum",
    },
  ];

  console.log(props.tableColumns);

  const [filteredTableData, setFilteredTableData] = useState<any>(props.tableData);
  const [columns, setColumns] = useState<any>(props.tableColumns);
  const [searchValue, setSearchValue] = useState("");
  const [inEditMode, setInEditMode] = useState(false);
  const [isModalVisibleColumn, setIsModalVisibleColumn] = useState(false);
  const [isModalVisibleCell, setIsModalVisibleCell] = useState(false);
  const [isModalVisibleAddRow, setIsModalVisibleAddRow] = useState(false);
  const [editColumnKey, setEditColumnKey] = useState<string>("");
  const [editColumnName, setEditColumnName] = useState<string>();
  const [editCellKey, setEditCellKey] = useState<any>();
  const [editCellDataIndex, setEditCellDataIndex] = useState<string>("");
  const [editCellValue, setEditCellValue] = useState<string>("");
  const [newRowData, setNewRowData] = useState<LooseObject>();

  useEffect(() => {}, [props.tableData]);

  const showModalColumn = (key: any, name: any) => {
    setEditColumnKey(key);
    setEditColumnName(name);
    setIsModalVisibleColumn(true);
  };

  const handleOkColumn = () => {
    let temp = columns;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["key"] === editColumnKey) {
        temp[i]["title"] = editColumnName;
        temp[i]["dataIndex"] = editColumnName?.split(" ").join("");
        temp[i]["key"] = editColumnName?.split(" ").join("");
      }
    }
    setColumns(temp);
    setIsModalVisibleColumn(false);
    // props.saveFunction({ tableData: tableData, columns: columns });
    setEditColumnName("");
    setEditColumnKey("");
  };

  const handleCancelColumn = () => {
    setIsModalVisibleColumn(false);
    setEditColumnName("");
    setEditColumnKey("");
  };

  const showModalCell = (key: any, dataIndex: any, value: any) => {
    console.log("editing cell", key, dataIndex, value);
    setEditCellKey(key);
    setEditCellDataIndex(dataIndex);
    setEditCellValue(value);
    setIsModalVisibleCell(true);
  };

  const handleOkCell = () => {
    let temp = filteredTableData;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["key"] === editCellKey) {
        console.log("found");
        temp[i][editCellDataIndex] = editCellValue;
      }
    }
    setIsModalVisibleCell(false);
    // props.saveFunction({ tableData: tableData, columns: columns });
    setEditCellValue("");
  };

  const handleCancelCell = () => {
    setIsModalVisibleCell(false);
    setEditCellValue("");
  };

  const showModalAddRow = () => {
    let tempObj: LooseObject = { key: filteredTableData.length };
    columns.map((c: any) => {
      tempObj[c["dataIndex"]] = "";
    });
    setNewRowData(tempObj);
    setIsModalVisibleAddRow(true);
  };

  const handleOkAddRow = () => {
    setIsModalVisibleAddRow(false);
    console.log("newRowData", newRowData);
    addRows(newRowData);
  };

  const handleCancelAddRow = () => {
    setIsModalVisibleAddRow(false);
  };

  const addColumn = () => {
    let check = false;
    for (const col of columns) {
      if (col["dataIndex"] === "accessor") check = true;
    }
    if (check) {
      message.warning("Please update previously added column header first!");
      return;
    }
    const temp = {
      key: "mynewcolumn",
      title: "Enter Column Name",
      dataIndex: "accessor",
      sort: false,
      tableId: 1,
    };
    // console.log("Received values of form:", value);
    const col = columns;
    col.push(temp);

    const col2: any = [];
    columns.map((c: any) => col2.push(c));
    setColumns(col2);
  };

  const deleteColumn = (id: string) => {
    if (id === "") return;

    let name = "";
    let temp = columns;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["key"] === id) {
        name = temp[i].dataIndex;
        temp.splice(i, 1);
      }
    }
    let temp2: any = [];
    columns.map((c: any) => temp2.push(c));
    for (let i = 0; i < temp2.length; i++) {
      if (temp2[i]["key"] === id) {
        name = temp2[i].dataIndex;
        temp2.splice(i, 1);
      }
    }
    setColumns(temp2);
    const d = filteredTableData;
    d.map((item: any) => delete item[name]);
    setFilteredTableData(d);
    setIsModalVisibleColumn(false);
  };

  const addRows = (newRow: any) => {
    // let temp: LooseObject = { key: filteredTableData.length };

    // for (const column of columns) {
    //   temp[column["dataIndex"]] = "";
    // }
    // console.log("temp add rows", temp);

    let tempData = filteredTableData;
    tempData.push(newRow);

    let temp2: any = [];
    filteredTableData.map((d: any) => temp2.push(d));
    setFilteredTableData(temp2);
    // setVisible(false);
    let obj: LooseObject = {};
    setNewRowData(obj);
  };

  const removeRow = (key: number) => {
    console.log("removing row", key);
    let temp = filteredTableData;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["key"] === key) {
        temp.splice(i, 1);
        break;
      }
    }
    let temp2: any = [];
    filteredTableData.map((d: any) => temp2.push(d));
    // setVisible(false);
    setFilteredTableData(temp2);
  };

  function searchData(value: any) {
    //search in question bank
    const searchText = value.toLowerCase();
    if (searchText === "") {
      setFilteredTableData(props.tableData.data);
      return;
    }
    let tempFilteredData: any = [];
    for (let i = 0; i < props.tableData.data.length; i++) {
      let tempObj = props.tableData.data[i];
      let keys = Object.keys(tempObj);
      // console.log("keys", keys, tempObj);
      for (const key of keys) {
        if (key === "key") continue;
        if (tempObj[key].toLowerCase().search(searchText) >= 0) {
          tempFilteredData.push(tempObj);
          break;
        }
      }
    }
    setFilteredTableData([...tempFilteredData]);
  }

  return (
    <>
      <div className="flex">
        <div className="w-1/2 flex justify-start items-center"></div>
        <div className="w-1/2 flex justify-end items-center">
          {inEditMode ? (
            <div className="flex justify-end">
              <p>
                <SaveOutlined
                  onClick={() => {
                    console.log("saving data", columns, filteredTableData);
                    setInEditMode(false);
                  }}
                />
              </p>
            </div>
          ) : (
            <p className="-ml-12">
              <EditOutline size={22} onClick={() => setInEditMode(true)} />
            </p>
          )}

          {/* )} */}
        </div>
      </div>

      {columns.length === 0 ? (
        <div
          className="flex justify-center items-center border border-green-400 rounded-md"
          style={{ overflowY: "auto" }}
        >
          <div>
            <p className="text-center">No Records Found!</p>
            {/* <p className="text-lg font-semibold">Click "Edit" to add records.</p> */}
          </div>
        </div>
      ) : (
        <div className="fixedHeader" style={{ overflowY: "auto" }}>
          <table>
            <thead>
              <tr className="roundedRows ">
                {inEditMode && (
                  <th
                    style={{
                      backgroundColor: "#27a6a4",
                      color: "whitesmoke",
                      width: "10rem",
                    }}
                    className="font-bold text-center px-2 w-6"
                  ></th>
                )}
                {columns.map((col: any) =>
                  typeof col.title === "string" ? (
                    <>
                      <th
                        style={{
                          backgroundColor: "#27a6a4",
                          color: "whitesmoke",
                          width: "10rem",
                        }}
                        className="font-bold text-center px-2 "
                      >
                        {col.title}
                      </th>{" "}
                    </>
                  ) : (
                    <th
                      style={{
                        backgroundColor: "#e65c4f",
                        color: "whitesmoke",
                        width: "10rem",
                      }}
                      className="font-bold text-center px-2 "
                    >
                      <span> {col.title}</span>
                    </th>
                  )
                )}
              </tr>
            </thead>
            {/* <div className="h-1"></div> */}
            <tbody>
              {inEditMode
                ? filteredTableData.map((row: any, index: any) => {
                    const color = index % 2 === 0 ? "#eaf8f8" : "white";
                    const keys: any = [];
                    columns.map((c: any) => keys.push(c["dataIndex"]));
                    const temp = keys.indexOf("key");
                    if (temp > -1) {
                      keys.splice(temp, 1);
                    }
                    return (
                      <tr
                        className="hover:bg-transparent border-b-8 roundedRows"
                        style={{ backgroundColor: color }}
                      >
                        {inEditMode && (
                          <td>
                            <span
                              // onClick={() => removeRow(row.key)}
                              style={{
                                color: "teal",
                                fontSize: "1.2rem",
                                justifyContent: "center",
                                alignSelf: "center",
                              }}
                            />
                          </td>
                        )}
                        {
                          //@ts-ignore
                          keys.map((key) => {
                            if (searchValue !== "") {
                              if (row[key].toLowerCase().search(searchValue) >= 0)
                                return (
                                  <td
                                    onClick={() => {
                                      if (inEditMode) showModalCell(row.key, key, row[key]);
                                    }}
                                    className="align-top p-2"
                                  >
                                    <mark className="bg-yellow-100">{row[key]}</mark>
                                  </td>
                                );
                              else
                                return (
                                  <td
                                    onClick={() => {
                                      if (inEditMode) showModalCell(row.key, key, row[key]);
                                    }}
                                    className="align-top p-2"
                                  >
                                    <ReactMarkdown children={row[key]}></ReactMarkdown>
                                  </td>
                                );
                            }
                            return (
                              <td
                                onClick={() => {
                                  if (inEditMode) showModalCell(row.key, key, row[key]);
                                }}
                                className="align-top p-2"
                              >
                                <ReactMarkdown>{row[key]}</ReactMarkdown>
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })
                : filteredTableData.map((row: any, index: any) => {
                    const color = index % 2 === 0 ? "#eaf8f8" : "white";
                    const keys: any = [];
                    columns.map((c: any) => keys.push(c["dataIndex"]));
                    const temp = keys.indexOf("key");
                    if (temp > -1) {
                      keys.splice(temp, 1);
                    }
                    return (
                      <tr
                        className="hover:bg-transparent border-b-8 roundedRows"
                        style={{ backgroundColor: color }}
                      >
                        {inEditMode && (
                          <td>
                            {" "}
                            <span
                              // onClick={() => removeRow(row.key)}
                              style={{
                                color: "teal",
                                fontSize: "1.2rem",
                                justifyContent: "center",
                                alignSelf: "center",
                              }}
                            />
                          </td>
                        )}
                        {
                          //@ts-ignore
                          keys.map((key) => {
                            if (searchValue !== "") {
                              if (row[key].toLowerCase().search(searchValue) >= 0)
                                return (
                                  <td
                                    onClick={() => {
                                      if (inEditMode) showModalCell(row.key, key, row[key]);
                                    }}
                                    className="align-top p-2"
                                  >
                                    <mark className="bg-yellow-100">{row[key]}</mark>
                                  </td>
                                );
                              else
                                return (
                                  <td
                                    onClick={() => {
                                      if (inEditMode) showModalCell(row.key, key, row[key]);
                                    }}
                                    className="align-top p-2"
                                  >
                                    <ReactMarkdown children={row[key]}></ReactMarkdown>
                                  </td>
                                );
                            }
                            return (
                              <td
                                onClick={() => {
                                  if (inEditMode) showModalCell(row.key, key, row[key]);
                                }}
                                className="align-top p-2"
                              >
                                <ReactMarkdown>{row[key]}</ReactMarkdown>
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })}
            </tbody>
          </table>

          {editColumnName && (
            <Modal
              title="Edit Column"
              visible={isModalVisibleColumn}
              onOk={handleOkColumn}
              onCancel={handleCancelColumn}
              footer={[
                <Button key="1" onClick={() => deleteColumn(editColumnKey)}>
                  Delete Column
                </Button>,
                <Button key="2" type="primary" onClick={handleOkColumn}>
                  OK
                </Button>,
              ]}
            >
              <div className="flex justify-center items-center">
                <p className="mr-2">Enter Column Header:</p>
                <textarea
                  className="border pl-2"
                  rows={5}
                  cols={40}
                  style={{ minHeight: "2rem" }}
                  onChange={(e) => setEditColumnName(e.target.value)}
                  defaultValue={editColumnName}
                ></textarea>
              </div>
            </Modal>
          )}
          {(editCellValue === undefined || editCellValue) && (
            <Modal
              title="Edit Data"
              visible={isModalVisibleCell}
              onOk={handleOkCell}
              onCancel={handleCancelCell}
            >
              <div className="flex justify-center items-center">
                <p className="mr-2">Enter Data:</p>
                <textarea
                  className="border pl-2"
                  rows={5}
                  cols={40}
                  style={{ minHeight: "2rem" }}
                  onChange={(e) => setEditCellValue(e.target.value)}
                  defaultValue={editCellValue}
                ></textarea>
              </div>
            </Modal>
          )}
          <Modal
            title="Add Row"
            visible={isModalVisibleAddRow}
            onOk={handleOkAddRow}
            onCancel={handleCancelAddRow}
            width={"40rem"}
          >
            <Carousel arrows prevArrow={<LeftCircleOutlined />} nextArrow={<RightCircleOutlined />}>
              {columns.map((c: any) => {
                return (
                  <div>
                    <div className="flex justify-center">
                      <div className="p-4">
                        <p className="text-center text-sm ">Enter value for</p>
                        <p
                          style={{
                            backgroundColor: "#27a6a4",
                            color: "whitesmoke",
                            width: "10rem",
                          }}
                          className="text-center text-lg font-seimbold mt-2 rounded-md p-2 mb-4"
                        >
                          {c.title}
                        </p>
                        <textarea
                          // style={contentStyle}
                          className="border pl-2 w-full"
                          rows={5}
                          cols={40}
                          // style={{ minHeight: "2rem" }}
                          onChange={(e) => {
                            let temp: any = newRowData;
                            temp[c["dataIndex"]] = e.target.value;
                            // let temp2: LooseObject = {};
                            // temp.map((t: any) => temp2.push(t));
                            setNewRowData(temp);
                          }}
                          defaultValue={""}
                          placeholder="Enter data"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </Modal>
        </div>
      )}
    </>
  );
};
export default SimpleTable;
