import { Button, Layout } from "antd";
import "antd/dist/antd.css";
import TabHeader from "./tabHeader";
import TopHeader from "./topHeader";
import "./body-layout/index.css";
import { Routing } from "../routes";
import LoginPage from "../pages/Landing";
import React from "react";
import LandingPage from "../pages/Landing/LandingPage";
import ContractsList from "../pages/Landing/ContractsList";
import AppContext from "../store";
import SideHeader from "./SideHeader";
import axios from "axios";

const { Header, Content, Footer } = Layout;

export const request = axios.create({
  //get hosted base url to connect it to the backend
  baseURL: process.env.REACT_APP_API_URL,
});

const PageLayout = () => {
  const [isIn, setIsIn] = React.useState<boolean>(false);

  return (
    <>
      <div style={{ background: "#E5E5E5" }}>
        <SideHeader />
        {/* <div
          className="ml-60 h-screen"
          style={{ overflowY: "hidden", overflowX: "hidden", background: "#E5E5E5" }}
        > */}
          <Routing isIn={isIn} setIsIn={setIsIn} />
        {/* </div> */}
      </div>
    </>
  );
};

export default PageLayout;
