import React from "react";
import { Steps, Button, message } from "antd";
import "../body-layout/index.css";
import BrandProductSetup from "../../pages/SimulatorConfiguration/BrandProductSetup";
import AppContext from "../../store";
import background from "../../globalTheme/background2.png";

const { Step } = Steps;
export default function StepsLayout(props: { subTabs?: any; subTabNames?: string; step?: number }) {
  const context = React.useContext(AppContext);

  let steps: any = [];

  steps = props.subTabs;

  const next = () => {
    context.setCurrent(context.current + 1);
    if (context.current === 1) {
      context.setSubmit1(true);
    }
  };

  const prev = () => {
    context.setCurrent(context.current - 1);
  };

  const handleChange = (current: any) => {
    context.setCurrent(current);
  };
  return (
    <div className="pl-4 pr-12 pt-3">
      <div className=" ">
        <Steps onChange={handleChange} current={context.current}>
          {steps.map((item: any) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>
      <div style={{ overflowY: "auto", height: "43.5rem", width: "104%" }}>
        <div
          className="steps-content"
          style={{
            marginRight: "1rem",
            marginTop: "-1rem",
            minHeight: "43rem",
            backgroundImage: `url(${background})`,
          }}
        >
          {steps[context.current].content}
        </div>
        <div className="steps-action" style={{ background: "white" }}>
          {context.current < steps.length - 1 && (
            <Button
              style={{
                backgroundColor: "#27a6a4",
                borderColor: "#27a6a4",
                marginLeft: "2rem",
                marginTop: "1rem",
                marginBottom: "1rem",
                display: "none",
                width : "5rem"
              }}
              type="primary"
              onClick={() => next()}
            >
              Next
            </Button>
          )}
          {context.current === steps.length - 1 && (
            <Button
              style={{
                backgroundColor: "#27a6a4",
                marginLeft: "2rem",
                marginTop: "1rem",
                marginBottom: "1rem",
                display: "none",
              }}
              type="primary"
              onClick={() => message.success("Processing complete!")}
            >
              Done
            </Button>
          )}
          {context.current > 0 && (
            <Button style={{ margin: "0 8px", display: "none" }} onClick={() => prev()}>
              Previous
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
