import React from "react";
import { Button, Checkbox, Divider, Form, Tabs, Tooltip } from "antd";
import AppContext from "../../../store";
import TablesForEpidemiology from "./tablesForEpidemiology";
import { getEpidemiologyDetails, saveEpidemiologyDetails } from "./service-helper";
import AppContext2 from "../../../store/index2";
import "../Epidemiology/index.css";
import background from "../../../globalTheme/background2.png";
import SimpleTable from "../../../libraries/TableMod";
const CheckboxGroup = Checkbox.Group;
const columnsForMonthlyDistribution: any = [
  {
    title: "Year",
    dataIndex: "year",
  },
  {
    title: "Jan",
    dataIndex: "jan",
  },
  {
    title: "Feb",
    dataIndex: "feb",
  },
  {
    title: "Mar",
    dataIndex: "mar",
  },
  {
    title: "Apr",
    dataIndex: "apr",
  },
  {
    title: "May",
    dataIndex: "may",
  },
  {
    title: "Jun",
    dataIndex: "jun",
  },
  {
    title: "Jul",
    dataIndex: "jul",
  },
  {
    title: "Aug",
    dataIndex: "aug",
  },
  {
    title: "Sep",
    dataIndex: "sep",
  },
  {
    title: "Oct",
    dataIndex: "oct",
  },
  {
    title: "Nov",
    dataIndex: "nov",
  },
  {
    title: "Dec",
    dataIndex: "dec",
  },
  {
    title: "Total",
    dataIndex: "sum",
  },
];

export default function Epidemiology() {
  const context = React.useContext(AppContext);
  const context2 = React.useContext(AppContext2);
  const [fetchedData, setFetchedData] = React.useState<any>();
  const [saveFinalData, setSaveFinalData] = React.useState<any>();
  const [columnsForComplianceRate, setColumnsForComplianceRate] = React.useState<any>();
  const [columnsForAvgDistribution, setColumnsForAvgDistribution] = React.useState<any>();

  const [columnsForDiscontinuationRate, setColumnsForDiscontinuationRate] = React.useState<any>();
  const [columnsForDiscontinuationEdit, setColumnsForDiscontinuationEdit] = React.useState<any>();

  const [columnsForComplianceEdit, setColumnsForComplianceEdit] = React.useState<any>();
  const [dataForEpidemiology, setDataForEpidemiology] = React.useState<any>();
  const [startYear, setStartYear] = React.useState<any>();
  const [endYear, setEndYear] = React.useState<any>();
  const [followUp, setFollowUp] = React.useState<any>();

  const [checkedList, setCheckedList] = React.useState([""]);
  const [dataForPatientNumbers, setDataForPatientNumbers] = React.useState<any>([]);
  const [columnsForPatientNumbers, setColumnsForPatientNumbers] = React.useState<any>([
    {
      title: "Subpopulation",
      dataIndex: "subpopulation",
      name: "subpopulation",
    },
  ]);

  let columnForEditingCompliance: any = [{ name: "complianceRate", title: "complianceRate" }];
  let columnForEditingDiscontinuation: any = [
    { name: "discontinuationRate", title: "Discontinuation Rate" },
  ];
  let arrForPatientNumberColumn: any = [
    { title: "Subpopulation", dataIndex: "subpopulation", name: "subpopulation" },
  ];
  let arrForComplianceRateColumn: any = [
    { title: "Compliance Rate %", dataIndex: "complianceRate", name: "complianceRate" },
  ];

  let arrForDiscontinuationRateColumn: any = [
    {
      title: "Discontinuation Rate",
      dataIndex: "discontinuationRate",
      name: "discontinuationRate",
    },
  ];

  let arrForAvgDistribution: any = [
    { title: "Average distribution per SKU", dataIndex: "dose", name: "dose" },
  ];

  let indications: any = [];

  const FetchData = async () => {
    // call api for getting values here
    getEpidemiologyDetails(context.contractId).then((val) => {
      setDataForEpidemiology(val);
      console.log(val);
    });
  };

  React.useEffect(() => {}, [fetchedData]);

  React.useEffect(() => {
    console.log(context.contractId);
    FetchData();
  }, [context.contractId]);

  const resetPatientsOnClick = () => {
    setDataForPatientNumbers([]);
  };
  React.useEffect(() => {
    if (dataForEpidemiology) {
      dataForEpidemiology.map((ind: any) => {
        let tempIndicationObj: any = {};
        tempIndicationObj.indication_ID = ind.indication_ID;
        tempIndicationObj.indication_Name = ind.indication_Name;
        indications.push(tempIndicationObj);
      });

      //defining start year, end year and follow up
      let sy = parseInt(dataForEpidemiology[0].contractStartDate.substring(0, 4));
      setStartYear(sy);
      let ey = parseInt(dataForEpidemiology[0].contractEndDate.substring(0, 4));
      setEndYear(ey);
      let fu = Math.ceil(dataForEpidemiology[0].contractFollowUpMonths / 12);
      setFollowUp(fu);

      let arrForCheck: any = [];

      // getting initial values for avgDistOfPatients Table
      dataForEpidemiology.map((i: any) => {
        let arrIndWisePatientDist: any = [];
        let arrForSelectedSub: any = [];

        let arrMonthlyDist: any = [];
        dataForEpidemiology
          .filter((ind: any) => ind.indication_ID === i.indication_ID)[0]
          .dosageSKU.map((dose: any) => {
            let dosageSku = dose.dosageSKU_mg;
            let arrAvgPatientDistSku: any = {};
            arrAvgPatientDistSku.dose = dosageSku;
            // console.log("startYear", startYear);
            for (let year = sy; year <= ey + fu; year++) {
              arrAvgPatientDistSku[year + ""] =
                dose.averageDistributionOnPatients.filter((x: any) => x.year === year)[0] !==
                undefined
                  ? dose.averageDistributionOnPatients.filter((x: any) => x.year === year)[0]
                      .distribution + "%"
                  : "0%";
            }
            arrIndWisePatientDist.push(arrAvgPatientDistSku);
          });

        let dataForComplianceArr: any = [];
        let dataForDiscontRate: any = [];

        for (let year = sy; year <= ey; year++) {
          let dist = dataForEpidemiology
            .filter((ind: any) => ind.indication_ID === i.indication_ID)[0]
            .monthlyDistribution.filter((yr: any) => yr.year === year)[0];
          // .map((dist: any) => {
          // comp.monthlyDistribution.map((dist: any) => {
          let arrMonthlyDistObj: any = {};
          if (dist) {
            arrMonthlyDistObj.year = dist.year + "";
            arrMonthlyDistObj.jan = dist.jan + "%";
            arrMonthlyDistObj.feb = dist.feb + "%";
            arrMonthlyDistObj.mar = dist.mar + "%";
            arrMonthlyDistObj.apr = dist.apr + "%";
            arrMonthlyDistObj.may = dist.may + "%";
            arrMonthlyDistObj.jun = dist.jun + "%";
            arrMonthlyDistObj.jul = dist.jul + "%";
            arrMonthlyDistObj.aug = dist.aug + "%";
            arrMonthlyDistObj.sep = dist.sep + "%";
            arrMonthlyDistObj.oct = dist.oct + "%";
            arrMonthlyDistObj.nov = dist.nov + "%";
            arrMonthlyDistObj.dec = dist.dec + "%";
            arrMonthlyDistObj.sum = dist.sum + "%";
          } else {
            arrMonthlyDistObj.year = year;
            arrMonthlyDistObj.jan = "0%";
            arrMonthlyDistObj.feb = "0%";
            arrMonthlyDistObj.mar = "0%";
            arrMonthlyDistObj.apr = "0%";
            arrMonthlyDistObj.may = "0%";
            arrMonthlyDistObj.jun = "0%";
            arrMonthlyDistObj.jul = "0%";
            arrMonthlyDistObj.aug = "0%";
            arrMonthlyDistObj.sep = "0%";
            arrMonthlyDistObj.oct = "0%";
            arrMonthlyDistObj.nov = "0%";
            arrMonthlyDistObj.dec = "0%";
            arrMonthlyDistObj.sum = "0%";
          }
          arrMonthlyDist.push(arrMonthlyDistObj);

          // console.log(arrMonthlyDist);
          // });
        }

        let complianceRateObj: any = {};
        let discontRateObj: any = {};

        discontRateObj.discontinuationRate = indications.filter(
          (ind: any) => ind.indication_ID === i.indication_ID
        )[0].indication_Name;

        complianceRateObj.complianceRate = indications.filter(
          (ind: any) => ind.indication_ID === i.indication_ID
        )[0].indication_Name;

        for (let year = sy; year <= ey + fu; year++) {
          dataForEpidemiology
            .filter((ind: any) => ind.indication_ID === i.indication_ID)[0]
            .compliance.map((comp: any) => {
              if (comp) {
                complianceRateObj[comp.yearIndex] = comp.complianceRate + "%";
              }
            });

          if (
            dataForEpidemiology.filter((ind: any) => ind.indication_ID === i.indication_ID)[0]
              .compliance.length === 0
          ) {
            complianceRateObj[year - sy + 1] = "0";
          }

          if (i.isKMcurve === false) {
            dataForEpidemiology
              .filter((ind: any) => ind.indication_ID === i.indication_ID)[0]
              .discontinuation.map((dis: any) => {
                if (dis) {
                  // console.log("reading saved values");
                  discontRateObj[year - sy + 1] = dis.discontinuationRate + "%";
                }
              });

            if (
              dataForEpidemiology.filter((ind: any) => ind.indication_ID === i.indication_ID)[0]
                .discontinuation.length === 0
            ) {
              // console.log("populating 0 values");
              discontRateObj[year - sy + 1] = 0 + "%";
            }
          }
        }

        dataForComplianceArr.push(complianceRateObj);
        dataForDiscontRate.push(discontRateObj);

        //for already selected subpopulations
        i.subpopulation.map((sub: any) => {
          if (sub.patientsCount.length !== 0) {
            let obj: any = {};
            obj.subID = sub.patientsCount[0].subpopulation_ID;
            obj.indID = sub.indication_ID;
            obj.name = sub.subpopulation_Name;
            arrForSelectedSub.push(sub.subpopulation_Name);
            arrForCheck.push(obj);
          }
        });
        let arrForSelectedPatientsCount: any = [];

        console.log(arrForCheck);
        arrForCheck.forEach((afc: any) => {
          // console.log(afc, dataForEpidemiology, i);

          i.subpopulation.map((sub: any) => {
            if (sub.subpopulation_ID === afc.subID) {
              let objForSelectedPatientsCount: any = {};
              objForSelectedPatientsCount.subpopulation = afc.name;
              objForSelectedPatientsCount.indicationId = afc.indID;
              objForSelectedPatientsCount.indication = i.indication_Name;
              for (let year = sy; year <= ey; year++) {
                if (sub.patientsCount.length > 0) {
                  objForSelectedPatientsCount[year] = sub.patientsCount.filter(
                    (yr: any) => yr.year === year
                  )[0].numberOfPatients;
                }
              }
              arrForSelectedPatientsCount.push(objForSelectedPatientsCount);
            }
          });
        });

        console.log(arrForSelectedPatientsCount);

        dataForEpidemiology
          .filter((ind: any) => ind.indication_ID === i.indication_ID)
          .map((i: any) => {
            i.complianceRateData = dataForComplianceArr;
            i.dataForAvgDistribution = arrIndWisePatientDist;
            i.dataForMonthlyDist = arrMonthlyDist;
            let arrNew: any = [];
            arrForSelectedPatientsCount
              .filter((arr: any) => arr.indicationId === i.indication_ID)
              .map((mp: any) => {
                arrNew.push(mp);
              });

            arrNew.forEach((object: any) => {
              delete object["indicationId"];
            });
            i.selectedPatientsCountData = arrNew;
            i.selectedBoxes = arrForSelectedSub;
            i.dataForDiscontinuationRate = dataForDiscontRate;
          });
      });

      console.log(dataForEpidemiology);
      setFetchedData(dataForEpidemiology);
      setSaveFinalData(dataForEpidemiology);

      //for patient nos. columns
      for (let year = sy; year <= ey; year++) {
        let temp: any = {};
        temp.title = year + "";
        temp.dataIndex = year + "";
        temp.name = year + "";
        // temp.width = 100;
        arrForPatientNumberColumn.push(temp);
        if (year === endYear) {
          arrForPatientNumberColumn.push({
            title: "Total",
            dataIndex: "total",
            name: "total",
            width: "1rem",
          });
        }
      }

      //for avg dist columns
      for (let year = sy; year <= ey + fu; year++) {
        let temp3: any = [];
        if (year > ey) {
          temp3.title = (
            <p className="font-bold" style={{ color: "whitesmoke", marginTop: "14px" }}>
              {year + ""}
            </p>
          );
          temp3.dataIndex = year + "";
          temp3.name = year + "";
        } else {
          temp3.title = year + "";
          temp3.dataIndex = year + "";
          temp3.name = year + "";
        }
        arrForAvgDistribution.push(temp3);
      }

      // columns for compliance rate & discont rate
      for (let year = sy; year <= ey + fu; year++) {
        let temp2: any = {};
        let temp: any = {};
        let objectForEditingCompliance: any = [];
        let objectForEditingDiscontinuation: any = [];
        if (year > ey) {
          temp2.title = (
            <p className="font-bold" style={{ color: "whitesmoke", marginTop: "12px" }}>
              {year - sy + 1 + ""}
            </p>
          );

          temp2.dataIndex = year - sy + "";
          temp2.name = year - sy + "";

          temp.title = (
            <p className="font-bold" style={{ color: "whitesmoke", marginTop: "12px" }}>
              {year - sy + 1 + ""}
            </p>
          );

          temp.dataIndex = year - sy + "";
          temp.name = year - sy + "";

          objectForEditingDiscontinuation.name = year - sy + 1 + "";
          objectForEditingDiscontinuation.title = year - sy + 1 + "";

          objectForEditingCompliance.name = year - sy + 1 + "";
          objectForEditingCompliance.title = year - sy + 1 + "";
        } else {
          temp2.title = year - sy + 1 + "";
          temp2.dataIndex = year - sy + 1 + "";
          temp2.name = year - sy + 1 + "";

          temp.title = year - sy + 1 + "";
          temp.dataIndex = year - sy + 1 + "";
          temp.name = year - sy + 1 + "";

          objectForEditingCompliance.name = year - sy + 1 + "";
          objectForEditingCompliance.title = year - sy + 1 + "";

          objectForEditingDiscontinuation.name = year - sy + 1 + "";
          objectForEditingDiscontinuation.title = year - sy + 1 + "";
        }
        columnForEditingCompliance.push(objectForEditingCompliance);
        columnForEditingDiscontinuation.push(objectForEditingDiscontinuation);
        arrForComplianceRateColumn.push(temp2);
        arrForDiscontinuationRateColumn.push(temp);
      }

      setColumnsForAvgDistribution(arrForAvgDistribution);
      setColumnsForPatientNumbers(arrForPatientNumberColumn);
      setColumnsForComplianceRate(arrForComplianceRateColumn);
      setColumnsForComplianceEdit(columnForEditingCompliance);
      setColumnsForDiscontinuationRate(arrForDiscontinuationRateColumn);
      setColumnsForDiscontinuationEdit(columnForEditingDiscontinuation);

      // console.log(dataForEpidemiology);
    }
  }, [dataForEpidemiology]);

  let dataForPatientArr: any = [];

  const onChange = (list: any, indicationId: any, indicationName: any) => {
    setCheckedList(list);

    let dataForPatientNos: any = [];

    fetchedData.map((i: any) => {
      if (i.indication_ID === indicationId) {
        i.selectedBoxes = list;

        i.selectedPatientsCountData.map((spc: any) => {
          let objTemp: any = {};
          objTemp.subpopulation = spc.subpopulation;
          objTemp.indication = i.indication_Name;
          for (let year = startYear; year <= endYear; year++) {
            objTemp[year + ""] = spc[year + ""];
          }
          dataForPatientNos.push(objTemp);
        });
      }
    });

    let fetchedDataCopy: any = [...fetchedData];

    list.forEach((subName: any) => {
      fetchedDataCopy
        .filter((i: any) => i.indication_ID === indicationId)
        .map((ind: any) => {
          ind.patientsCountData.map((pc: any) => {
            console.log("For this indication", pc);
            if (pc.indication === indicationName && pc.subpopulation === subName) {
              if (ind.selectedPatientsCountData.length > 0) {
                let flag = true;
                console.log(ind.selectedPatientsCountData, "selected patiendt count data");
                ind.selectedPatientsCountData.map((spc: any) => {
                  if (spc.subpopulation === subName) {
                    flag = false;
                  }
                });

                if (flag) {
                  let objForPatientsData: any = {};
                  objForPatientsData.subpopulation = pc.subpopulation;
                  objForPatientsData.indication = pc.indication;

                  for (let year = startYear; year <= endYear; year++) {
                    if (pc[year + ""]) {
                      objForPatientsData[year] = pc[year + ""];
                    } else {
                      objForPatientsData[year] = 0;
                    }
                  }
                  dataForPatientNos.push(objForPatientsData);
                  setDataForPatientNumbers([...dataForPatientNos]);
                }
              } else {
                let objForPatientsData: any = {};
                objForPatientsData.subpopulation = pc.subpopulation;
                objForPatientsData.indication = pc.indication;

                for (let year = startYear; year <= endYear; year++) {
                  objForPatientsData[year] = pc[year + ""];
                }
                dataForPatientNos.push(objForPatientsData);
                setDataForPatientNumbers([...dataForPatientNos]);
              }

              ind.selectedPatientsCountData = dataForPatientNos;
            }
          });
        });
    });

    //removing unselected subpopulations rows from patient numbers -> check code

    console.log(indicationId, indicationName, list);

    // fetchedData.map((i: any) => {
    //   if (i.indication_ID === indicationId) {
    //     // i.selectedBoxes.map((box: any) => {
    //     i.selectedPatientsCountData.map((spcd: any) => {
    //       let arrayForNewSelectedPatientsCount: any = [];

    //       let subpop = i.selectedBoxes.filter((box: any) => box === spcd.subpopulation)[0];
    //       console.log("Subpop here", subpop);
    //       if (spcd.subpopulation === subpop && subpop !== undefined) {
    //         let objForSelectedPatientsCountNew: any = {};
    //         console.log(spcd);
    //         for (let year = startYear; year <= endYear; year++) {
    //           objForSelectedPatientsCountNew = { ...spcd };
    //         }
    //         arrayForNewSelectedPatientsCount.push(objForSelectedPatientsCountNew);
    //         i.selectedPatientsCountData = [...arrayForNewSelectedPatientsCount];
    //         console.log(arrayForNewSelectedPatientsCount);
    //       }
    //     });
    //     // });
    //   }
    // });

    // let arrayForNewSelectedPatientsCount: any = [];

    let arrayForNewSelectedPatientsCount: any = [];

    fetchedData
      .filter((i: any) => i.indication_ID === indicationId)
      .map((ind: any) => {
        ind.selectedPatientsCountData.map((spcd: any) => {
          if (
            ind.selectedBoxes.indexOf(spcd.subpopulation) !== -1 &&
            ind.selectedBoxes.length > 0
          ) {
            let objForSelectedPatientsCountNew: any = {};
            for (let year = startYear; year <= endYear; year++) {
              objForSelectedPatientsCountNew = { ...spcd };
            }
            arrayForNewSelectedPatientsCount.push(objForSelectedPatientsCountNew);
          }
        });
        ind.selectedPatientsCountData = [...arrayForNewSelectedPatientsCount];
      });

    console.log(fetchedDataCopy);
    setFetchedData([...fetchedDataCopy]);
  };

  const saveButton = (
    <Button className="tabs-extra-demo-button" type="primary" htmlType="submit">
      Submit
    </Button>
  );

  const onSave = (indicationId: any, x: any, tableName: any) => {
    let dataAfterSave: any = [...fetchedData];
    console.log("Data After Save", indicationId, x, tableName, fetchedData);

    if (tableName === "monthlyPatient") {
      x.map((element: any) => {
        dataAfterSave
          .filter((i: any) => i.indication_ID === indicationId)
          .map((ind: any) => {
            ind.dataForMonthlyDist.map((d: any) => {
              if (d.year === element.year) {
                d.jan = element.jan;
                d.feb = element.feb;
                d.mar = element.mar;
                d.apr = element.apr;
                d.may = element.may;
                d.jun = element.jun;
                d.jul = element.jul;
                d.aug = element.aug;
                d.sep = element.sep;
                d.oct = element.oct;
                d.nov = element.nov;
                d.dec = element.dec;
                d.sum = element.sum;
              }
            });
          });
      });

      setFetchedData(dataAfterSave);
    } else if (tableName === "complianceRate") {
      dataAfterSave
        .filter((i: any) => i.indication_ID === indicationId)
        .map((ind: any) => {
          ind.complianceRateData.splice(0, 1);
          ind.complianceRateData.push(x[0]);
        });

      dataAfterSave
        .filter((i: any) => i.indication_ID === indicationId)
        .map((ind: any) => {
          ind.complianceRateData.map((x: any) => {
            for (let year = startYear; year <= endYear + followUp; year++) {
              x[year] += "%";
            }
          });
        });
    } else if (tableName === "avgDist") {
      dataAfterSave
        .filter((i: any) => i.indication_ID === indicationId)
        .map((ind: any) => {
          ind.dataForAvgDistribution.splice(0, ind.dataForAvgDistribution.length);
          x.map((editedVal: any) => {
            ind.dataForAvgDistribution.push(editedVal);
          });
        });

      dataAfterSave
        .filter((i: any) => i.indication_ID === indicationId)
        .map((ind: any) => {
          ind.dataForAvgDistribution.map((x: any) => {
            for (let year = startYear; year <= endYear + followUp; year++) {
              x[year] += "%";
            }
          });
        });
    } else if (tableName === "patientNo") {
      // console.log("Changed value", x);
      dataAfterSave
        .filter((i: any) => i.indication_ID === indicationId)
        .map((ind: any) => {
          ind.selectedPatientsCountData = x;
          setFetchedData([...dataAfterSave]);
        });
    } else if ((tableName = "discontinuationRate")) {
      dataAfterSave
        .filter((i: any) => i.indication_ID === indicationId)
        .map((ind: any) => {
          ind.dataForDiscontinuationRate.splice(0, 1);
          ind.dataForDiscontinuationRate.push(x[0]);
        });
    }
    // setSaveFinalData([...dataAfterSave]);

    setFetchedData([...dataAfterSave]);
  };

  const onFinish = () => {
    let finalData: any = [];
    saveFinalData.map((data: any) => {
      let finalDataObj: any = {};
      let arrToCheckDataForCompliance: any = [];
      let arrToCheckDataForDiscontinuation: any = [];

      let arrForMonthlyDistribution: any = [];
      let arrForPatientsCount: any = [];

      finalDataObj.contractEndDate = data.contractEndDate;
      finalDataObj.contractFollowUpMonths = data.contractFollowUpMonths;
      finalDataObj.contractIndication_ID = data.contractIndication_ID;
      finalDataObj.contractStartDate = data.contractStartDate;
      finalDataObj.indication_ID = data.indication_ID;
      finalDataObj.indication_Name = data.indication_Name;
      // commented here - check!
      // finalDataObj.subpopulation = [...data.subpopulation];
      finalDataObj.subpopulation = [];
      data.subpopulation.map((sub: any) => {
        // for new contract - patients count
        data.selectedPatientsCountData.map((pcData: any) => {
          if (pcData.subpopulation === sub.subpopulation_Name) {
            let objForSub: any = {};

            arrForPatientsCount = [];
            objForSub.indication_ID = sub.indication_ID;
            objForSub.subpopulation_ID = sub.subpopulation_ID;
            objForSub.subpopulation_Name = sub.subpopulation_Name;

            for (let year = startYear; year <= endYear; year++) {
              let objForPatientsCountData: any = {};
              objForPatientsCountData.id = null;
              objForPatientsCountData.contractIndication_ID = data.contractIndication_ID;
              objForPatientsCountData.subpopulation_ID = sub.subpopulation_ID;
              objForPatientsCountData.year = year;
              objForPatientsCountData.numberOfPatients = pcData[year];
              arrForPatientsCount.push(objForPatientsCountData);
              objForPatientsCountData.indication = data.indication_Name;
            }
            objForSub.patientsCount = arrForPatientsCount;
            finalDataObj.subpopulation.push(objForSub);
          }
          // for (let year = startYear; year <= endYear; year++) {
          //   let objForPatientsCountData: any = {};
          //   objForPatientsCountData.id = null;
          //   objForPatientsCountData.contractIndication_ID = data.contractIndication_ID;
          //   objForPatientsCountData.subpopulation_ID = sub.subpopulation_ID;
          //   objForPatientsCountData.year = year;
          //   objForPatientsCountData.numberOfPatients = pcData[year];
          //   arrForPatientsCount.push(objForPatientsCountData);
          //   objForPatientsCountData.indication = data.indication_Name;
          // }

          // objForSub.patientsCount = [...arrForPatientsCount];
          console.log(arrForPatientsCount, sub);

          // console.log(objForSub);
        });

        // data.subpopulation.map((s: any) => {
        //   data.selectedPatientsCountData.map((pcData: any) => {
        //     if (s.subpopulation_Name === pcData.subpopulation) {
        //       for (let year = startYear; year <= endYear; year++) {
        //         let objForPatientsCountData: any = {};
        //         objForPatientsCountData.id = null;
        //         objForPatientsCountData.contractIndication_ID = data.contractIndication_ID;
        //         objForPatientsCountData.subpopulation_ID = sub.subpopulation_ID;
        //         objForPatientsCountData.year = year;
        //         objForPatientsCountData.numberOfPatients = pcData[year];
        //         arrForPatientsCount.push(objForPatientsCountData);
        //         objForPatientsCountData.indication = data.indication_Name;
        //       }
        //     }
        //   });
        //   objForSub.patientsCount = [...arrForPatientsCount];
        // });

        // add condition when to apply
        // for edit
        data.selectedPatientsCountData.map((spcd: any) => {
          if (
            // if only values change i.e. checked box value changes
            data.subpopulation.filter((s: any) => s.subpopulation_Name === spcd.subpopulation)[0]
              .patientsCount.length > 0
          ) {
            data.subpopulation
              .filter((s: any) => s.subpopulation_Name === spcd.subpopulation)[0]
              .patientsCount.map((pc: any) => {
                pc.numberOfPatients = spcd[pc.year];
              });
          } else {
            // console.log("Goes inside else!");
            // let objForPatientCount: any = {};
            // objForPatientCount.id = null;
            // objForPatientCount.contractIndication_ID= data.contractIndication_ID;
            // objForPatientCount.subpopulation_ID=
            // objForPatientCount.year =
            // objForPatientCount.numberOfPatients=
          }
        });
      });

      if (data.compliance.length === 0) {
        // for compliance when creating for the first time
        for (let count = startYear; count <= endYear + followUp; count++) {
          let objForCompliance: any = {};
          objForCompliance.id = null;
          objForCompliance.contractIndication_ID = data.contractIndication_ID;
          objForCompliance.yearIndex = count - startYear + 1;
          objForCompliance.complianceRate = parseInt(
            data.complianceRateData[0][count - startYear + 1]
          );
          arrToCheckDataForCompliance.push(objForCompliance);
        }
        console.log(arrToCheckDataForCompliance);
      }

      data.compliance.map((comp: any) => {
        if (data.compliance.length > 0) {
          // for compliance when editing values
          let objForCompliance: any = {};
          objForCompliance.id = comp.id ? comp.id : null;
          // objForCompliance.id = null;

          objForCompliance.contractIndication_ID = comp.contractIndication_ID;
          objForCompliance.yearIndex = comp.yearIndex;
          objForCompliance.complianceRate = parseInt(data.complianceRateData[0][comp.yearIndex]);
          arrToCheckDataForCompliance.push(objForCompliance);
        }
      });

      data.discontinuation.map((disc: any) => {
        if (data.discontinuation.length > 0) {
          // for discontinuation when editing values
          let objForDiscontinuation: any = {};
          // objForCompliance.id = comp.id;
          objForDiscontinuation.id = disc.id ? disc.id : null;

          objForDiscontinuation.contractIndication_ID = disc.contractIndication_ID;
          objForDiscontinuation.yearIndex = disc.yearIndex;
          objForDiscontinuation.discontinuationRate = parseInt(
            data.dataForDiscontinuationRate[0][disc.yearIndex]
          );

          arrToCheckDataForDiscontinuation.push(objForDiscontinuation);
        }
      });

      if (data.discontinuation.length === 0) {
        // for discontinuation when creating for the first time
        for (let count = startYear; count <= endYear + followUp; count++) {
          let objForDiscontinuation: any = {};
          objForDiscontinuation.id = null;
          objForDiscontinuation.contractIndication_ID = data.contractIndication_ID;
          objForDiscontinuation.yearIndex = count - startYear + 1;
          objForDiscontinuation.discontinuationRate = parseInt(
            data.dataForDiscontinuationRate[0][count - startYear + 1]
          );
          arrToCheckDataForDiscontinuation.push(objForDiscontinuation);
        }
      }

      finalDataObj.compliance = [...arrToCheckDataForCompliance];
      finalDataObj.discontinuation = saveFinalData[0].isKMcurve
        ? null
        : [...arrToCheckDataForDiscontinuation];
      let arrForObjectsOfAvg: any = [];
      // for avg distribution of patients
      data.dosageSKU.map((dose: any) => {
        // console.log(dose, data);
        let arrForAvgDistributionOfPatients: any = [];
        let objForDosageSKU: any = {};
        objForDosageSKU.dosageSKU_ID = dose.dosageSKU_ID;
        objForDosageSKU.dosageSKU_mg = dose.dosageSKU_mg;

        if (dose.averageDistributionOnPatients.length === 0) {
          // for new
          data.dataForAvgDistribution.map((dist: any) => {
            if (dist.dose === dose.dosageSKU_mg) {
              arrForAvgDistributionOfPatients = [];

              for (let year = startYear; year <= endYear + followUp; year++) {
                let objForAvgDist: any = {};
                objForAvgDist.year = year;
                objForAvgDist.id = null;
                objForAvgDist.contractIndication_ID = data.contractIndication_ID;
                objForAvgDist.dosageSKU_ID = dose.dosageSKU_ID;
                // objForAvgDist.distribution = parseInt(dist[year].replace("%", ""));

                objForAvgDist.distribution = parseInt(dist[year].replace("%", ""));
                arrForAvgDistributionOfPatients.push(objForAvgDist);
              }
              objForDosageSKU.averageDistributionOnPatients = [...arrForAvgDistributionOfPatients];
              arrForObjectsOfAvg.push(objForDosageSKU);
            }
          });
        } else {
          // for edit
          for (let year = startYear; year <= endYear + followUp; year++) {
            let dist: any = dose.averageDistributionOnPatients.filter(
              (distAvg: any) => distAvg.year === year && distAvg.dosageSKU_ID === dose.dosageSKU_ID
            )[0];

            if (dist) {
              let objForAvgDist: any = {};
              objForAvgDist.id = dist.id;
              objForAvgDist.contractIndication_ID = dist.contractIndication_ID;

              let distValTemp: any = data.dataForAvgDistribution.filter(
                (d: any) => d.dose === dose.dosageSKU_mg
              )[0][dist.year];

              objForAvgDist.year = dist.year;
              objForAvgDist.dosageSKU_ID = dist.dosageSKU_ID;
              objForAvgDist.distribution = distValTemp.replace("%", "");

              arrForAvgDistributionOfPatients.push(objForAvgDist);
            } else {
              let objForAvgDist: any = {};
              objForAvgDist.year = year;
              objForAvgDist.id = null;
              objForAvgDist.contractIndication_ID = data.contractIndication_ID;
              objForAvgDist.dosageSKU_ID = dose.dosageSKU_ID;
              objForAvgDist.distribution = null;
              arrForAvgDistributionOfPatients.push(objForAvgDist);
            }
          }
          objForDosageSKU.averageDistributionOnPatients = [...arrForAvgDistributionOfPatients];
          arrForObjectsOfAvg.push(objForDosageSKU);
        }
      });

      finalDataObj.dosageSKU = [...arrForObjectsOfAvg];

      //for monthly Distribution (edit and new)
      if (data.monthlyDistribution.length > 0) {
        data.monthlyDistribution.map((monthly: any) => {
          let objForMonthlyDist: any = {};
          objForMonthlyDist.id = monthly.id;
          objForMonthlyDist.contractIndication_ID = monthly.contractIndication_ID;
          objForMonthlyDist.year = monthly.year;

          data.dataForMonthlyDist
            .filter((y: any) => monthly.year === y.year)
            .map((dataForThatYear: any) => {
              objForMonthlyDist.jan = parseInt(dataForThatYear.jan.replace("%", ""));
              objForMonthlyDist.feb = parseInt(dataForThatYear.feb.replace("%", ""));
              objForMonthlyDist.mar = parseInt(dataForThatYear.mar.replace("%", ""));
              objForMonthlyDist.apr = parseInt(dataForThatYear.apr.replace("%", ""));
              objForMonthlyDist.may = parseInt(dataForThatYear.may.replace("%", ""));
              objForMonthlyDist.jun = parseInt(dataForThatYear.jun.replace("%", ""));
              objForMonthlyDist.jul = parseInt(dataForThatYear.jul.replace("%", ""));
              objForMonthlyDist.aug = parseInt(dataForThatYear.aug.replace("%", ""));
              objForMonthlyDist.sep = parseInt(dataForThatYear.sep.replace("%", ""));
              objForMonthlyDist.oct = parseInt(dataForThatYear.oct.replace("%", ""));
              objForMonthlyDist.nov = parseInt(dataForThatYear.nov.replace("%", ""));
              objForMonthlyDist.dec = parseInt(dataForThatYear.dec.replace("%", ""));
              objForMonthlyDist.sum = parseInt(dataForThatYear.sum.replace("%", ""));
            });

          arrForMonthlyDistribution.push(objForMonthlyDist);
        });
      } else {
        for (let year = startYear; year <= endYear; year++) {
          let objForMonthlyDist: any = {};
          objForMonthlyDist.id = null;
          objForMonthlyDist.contractIndication_ID = data.contractIndication_ID;
          objForMonthlyDist.year = year;

          data.dataForMonthlyDist
            .filter((y: any) => year === y.year)
            .map((dataForThatYear: any) => {
              objForMonthlyDist.jan = parseInt(dataForThatYear.jan);
              objForMonthlyDist.feb = parseInt(dataForThatYear.feb);
              objForMonthlyDist.mar = parseInt(dataForThatYear.mar);
              objForMonthlyDist.apr = parseInt(dataForThatYear.apr);
              objForMonthlyDist.may = parseInt(dataForThatYear.may);
              objForMonthlyDist.jun = parseInt(dataForThatYear.jun);
              objForMonthlyDist.jul = parseInt(dataForThatYear.jul);
              objForMonthlyDist.aug = parseInt(dataForThatYear.aug);
              objForMonthlyDist.sep = parseInt(dataForThatYear.sep);
              objForMonthlyDist.oct = parseInt(dataForThatYear.oct);
              objForMonthlyDist.nov = parseInt(dataForThatYear.nov);
              objForMonthlyDist.dec = parseInt(dataForThatYear.dec);
              objForMonthlyDist.sum = parseInt(dataForThatYear.sum);
            });

          arrForMonthlyDistribution.push(objForMonthlyDist);
        }
      }

      finalDataObj.monthlyDistribution = [...arrForMonthlyDistribution];
      finalData.push(finalDataObj);
    });

    console.log(finalData);

    //api to save all the data for epidemiology
    saveEpidemiologyDetails(finalData);
    context.setCurrent(context.current + 1);
  };

  const goPrevious = () => {
    context.setCurrent(context.current - 1);
  };

  const [uniformDist, setUniformDist] = React.useState<boolean>(false);

  const setUniformDistribution = () => {
    setUniformDist(true);
  };

  return (
    <div
    // style={{ backgroundImage: `url(${background})` }}
    >
      <div style={{ minHeight: "80rem", backgroundImage: `url(${background})` }}>
        <div style={{ padding: "2rem" }}>
          {fetchedData && startYear && (
            <Form name="item_form" onFinish={onFinish} layout={"vertical"}>
              {/* {console.log("fetchedData", fetchedData)} */}

              <Tabs type="card" className="card-tabs mt-8" tabBarExtraContent={saveButton}>
                {fetchedData.map((ind: any) => {
                  return (
                    <Tabs.TabPane
                      forceRender={true}
                      tab={
                        <p onClick={resetPatientsOnClick} className="uppercase font-bold">
                          {ind.indication_Name}
                        </p>
                      }
                      key={ind.indication_ID}
                    >
                      <div style={{ marginTop: "-4rem" }}>
                        <div className="text-l font-bold float-left mt-20 mb-8">
                          {" "}
                          Patient Numbers
                        </div>
                        <div className="text-sm font-normal float-left mt-28 -ml-28">
                          Select the subpopulations to be configured for this indication
                        </div>

                        <br></br>
                        <br></br>
                        {fetchedData
                          .filter((i: any) => i.indication_ID === ind.indication_ID)
                          .map((indication: any) => {
                            indication.subpopulation.map((s: any) => {
                              let dataObjTemp: any = {};
                              dataObjTemp.subpopulation = s.subpopulation_Name;
                              dataObjTemp.indication = ind.indication_Name;

                              if (s.patientsCount.length > 0) {
                                s.patientsCount.map((pc: any) => {
                                  dataObjTemp[pc.year] = pc.numberOfPatients;
                                });
                              } else {
                                for (let year = startYear; year <= endYear; year++) {
                                  dataObjTemp[year] = 0;
                                }
                              }

                              // for (let year = startYear; year <= endYear; year++) {
                              //   dataObjTemp[year + ""] = 0;
                              // }
                              // console.log(dataObjTemp);
                              dataForPatientArr.push(dataObjTemp);
                            });

                            indication.patientsCountData = dataForPatientArr;
                          })}

                        {/* <Checkbox
                    indeterminate={indeterminate}
                    // onChange={onCheckAllChange}
                    checked={checkAll}
                    className="float-left"
                  >
                    ITT
                  </Checkbox>
                  <br></br> */}

                        <Divider />

                        {console.log(fetchedData, ind)}
                        <CheckboxGroup
                          className="float-left"
                          options={fetchedData
                            .filter((i: any) => i.indication_ID === ind.indication_ID)[0]
                            .subpopulation.map((s: any) => s.subpopulation_Name)}
                          // value={ind.selectedBoxes}
                          value={ind.selectedBoxes}
                          onChange={(list: any) =>
                            onChange(list, ind.indication_ID, ind.indication_Name)
                          }
                        />
                        <br></br>

                        <div className="text-sm font-normal float-left mt-8 mb-8">
                          Specify the number of patients in treatment for each of the selected
                          subpopulations
                        </div>

                        {/* <div className="text-sm font-normal float-left mb-8"> Patient Numbers</div> */}
                        {console.log("fetchedData", ind)}
                        {ind.selectedPatientsCountData && (
                          <div style={{ width: "78.5%" }}>
                            <TablesForEpidemiology
                              key={ind.indication_ID + "patientNo"}
                              name={"patientNo"}
                              onSave={onSave}
                              data={fetchedData}
                              indicationId={ind.indication_ID}
                              indicationName={ind.indication_Name}
                              tableData={ind.selectedPatientsCountData}
                              tableColumns={columnsForPatientNumbers}
                            />
                          </div>
                        )}
                      </div>

                      <div className="text-l font-bold float-left mt-20 mb-8">
                        {" "}
                        Compliance Rate %
                      </div>
                      {console.log(
                        "col, edit col, data for comp",
                        columnsForComplianceRate,
                        columnsForComplianceEdit,
                        ind.complianceRateData
                      )}
                      <>
                        {/* <TablesForEpidemiology
                        key={ind.indication_ID + "complianceRate"}
                        tableData={ind.complianceRateData}
                        onSave={onSave}
                        name={"complianceRate"}
                        indicationId={ind.indication_ID}
                        tableColumns={columnsForComplianceRate}
                        tableColumnsEdit={columnsForComplianceEdit}
                      /> */}
                      </>

                      <div style={{ marginTop: "7rem", width: "75%" }}>
                        <SimpleTable
                          tableData={ind.complianceRateData}
                          tableColumns={columnsForComplianceRate}
                        />
                      </div>

                      {fetchedData[0].isKMcurve === false && (
                        <>
                          <div className="text-l font-bold float-left mt-20 mb-8">
                            Discontinuation Rate %
                          </div>
                          <>
                            <TablesForEpidemiology
                              key={ind.indication_ID + "discontinuationRate"}
                              tableData={ind.dataForDiscontinuationRate}
                              // tableData={ind.discontRateData}
                              onSave={onSave}
                              name={"discontinuationRate"}
                              indicationId={ind.indication_ID}
                              tableColumns={columnsForDiscontinuationRate}
                              tableColumnsEdit={columnsForDiscontinuationEdit}
                            />
                          </>
                        </>
                      )}

                      <div className=" text-l font-bold float-left mt-20 mb-8">
                        Average distribution of patients across dosage forms
                      </div>
                      {console.log("data For avg dist", ind.dataForAvgDistribution)}
                      <TablesForEpidemiology
                        indicationId={ind.indication_ID}
                        key={ind.indication_ID + "avgDist"}
                        name={"avgDist"}
                        onSave={onSave}
                        tableData={ind.dataForAvgDistribution}
                        tableColumns={columnsForAvgDistribution}
                      />
                      {/* <div style={{ marginTop: "7rem", width: "75%" }}>
                        <SimpleTable
                          tableData={ind.dataForAvgDistribution}
                          tableColumns={columnsForAvgDistribution}
                        />
                      </div> */}

                      <div className=" text-l font-bold float-left mt-20 mb-8">
                        Monthly distribution of new patients across contract year
                      </div>

                      <TablesForEpidemiology
                        indicationId={ind.indication_ID}
                        name={"monthlyPatient"}
                        onSave={onSave}
                        key={ind.indication_ID + "monthlyPatient"}
                        tableData={ind.dataForMonthlyDist}
                        tableColumns={columnsForMonthlyDistribution}
                        data={fetchedData}
                        uniformDist={uniformDist}
                      />

                      <Button
                        onClick={setUniformDistribution}
                        type="primary"
                        className="float-left mt-8 "
                      >
                        Set Uniform Monthly Distribution
                      </Button>
                      {/* <div style={{ marginTop: "7rem" }}>
                        <SimpleTable
                          tableData={ind.dataForMonthlyDist}
                          tableColumns={columnsForMonthlyDistribution}
                        />
                      </div> */}

                      <div className="mt-24 pb-16">
                        <Button className="float-left" onClick={goPrevious}>
                          Previous
                        </Button>
                        <Button
                          type="primary"
                          style={{ width: "5rem" }}
                          htmlType="submit"
                          className="ml-8  float-left"
                        >
                          Next
                        </Button>
                      </div>
                    </Tabs.TabPane>
                  );
                })}
              </Tabs>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}
