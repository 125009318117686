import React from "react";
import "antd/dist/antd.css";
// import "./index.css";
import { Alert, Button, Col, Input, InputNumber, Row, Table } from "antd";
import { AddCircle } from "@styled-icons/fluentui-system-filled/AddCircle";
import { MinusCircle } from "@styled-icons/boxicons-regular/MinusCircle";

let indx: any = 5;
export default function ConstructTable(props: { rebateType: any }) {
  let columnsInitial: any = [
    {
      title: "Threshold",
      dataIndex: "threshold",
      key: "threshold",
      width: "5%",
      fixed: "right",
    },
    {
      title: "Month Input",
      children: [
        {
          title: "Lower Limit",
          dataIndex: "lowerlimit",
          key: "lowerlimit",
          width: "20%",
        },
        {
          title: "Upper Limit",
          dataIndex: "upperlimit",
          key: "upperlimit",
          width: "20%",
        },
      ],
    },
    {
      title: "Rebate %",
      dataIndex: "rebate",
      key: "rebate",
      width: "15%",
      fixed: "right",
    },
  ];
  const [columns, setColumns] = React.useState<any>(columnsInitial);

  let dummyData = [
    {
      key: 1,
      threshold: 1,
      lowerlimit: 0,
      upperlimit: <Input defaultValue={6} key={1} onChange={(e) => handleValues(e, 1)}></Input>,
      rebate: props.rebateType ? (
        <InputNumber defaultValue={5} addonAfter="%" />
      ) : (
        <Input defaultValue={5}></Input>
      ),
    },
    // {
    //   key: 2,
    //   threshold: 2,
    //   lowerlimit: "",

    //   upperlimit: <Input key={2} onChange={(e) => handleValues(e, 2)}></Input>,
    //   rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
    // },
    // {
    //   key: 3,
    //   threshold: 3,
    //   lowerlimit: "",
    //   upperlimit: <Input key={3} onChange={(e) => handleValues(e, 3)}></Input>,
    //   rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
    // },
    // {
    //   key: 4,
    //   threshold: 4,
    //   lowerlimit: "",
    //   upperlimit: <Input key={4} onChange={(e) => handleValues(e, 4)}></Input>,
    //   rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
    // },
    {
      key: 2,
      threshold: "Final Threshold",
      lowerlimit: "6",
      upperlimit: "Remaining Months",
      rebate: props.rebateType ? (
        <InputNumber defaultValue={10} addonAfter="%" />
      ) : (
        <Input defaultValue={10}></Input>
      ),
    },
  ];

  let tempVal: any = [0, 0, 0, 0, 0];
    const [dataSource, setDataSource] = React.useState<any>(dummyData);
  const [error, setError] = React.useState<boolean>(false);

  const handleValues = (val: any, RowNumber: any) => {
    tempVal[RowNumber] = val.target.value;
    console.log(indx, dataSource);
    let dummy: any;
    if (indx === 5) {
      dummy = [
        {
          key: 1,
          threshold: 1,
          lowerlimit: 0,
          upperlimit: <Input key={1} onChange={(e) => handleValues(e, 1)}></Input>,
          rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        },
        // {
        //   key: 2,
        //   threshold: 2,
        //   lowerlimit: tempVal[1],

        //   upperlimit: <Input key={2} onChange={(e) => handleValues(e, 2)}></Input>,
        //   rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        // },
        // {
        //   key: 3,
        //   threshold: 3,
        //   lowerlimit: tempVal[2],
        //   upperlimit: <Input key={3} onChange={(e) => handleValues(e, 3)}></Input>,
        //   rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        // },
        // {
        //   key: 4,
        //   threshold: 4,
        //   lowerlimit: tempVal[3],
        //   upperlimit: <Input key={4} onChange={(e) => handleValues(e, 4)}></Input>,
        //   rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        // },
        {
          key: 5,
          threshold: "Final Threshold",
          // lowerlimit: <Input disabled={true} ueArr[4]}></Input>,
          lowerlimit: tempVal[4],
          upperlimit: "Remaining Months",
          rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        },
      ];
    } else if (indx === 4) {
      dummy = [
        {
          key: 1,
          threshold: 1,
          lowerlimit: 0,
          upperlimit: <Input key={1} onChange={(e) => handleValues(e, 1)}></Input>,
          rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        },
        {
          key: 2,
          threshold: 2,
          lowerlimit: tempVal[1],

          upperlimit: <Input key={2} onChange={(e) => handleValues(e, 2)}></Input>,
          rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        },
        {
          key: 3,
          threshold: 3,
          lowerlimit: tempVal[2],
          upperlimit: <Input key={3} onChange={(e) => handleValues(e, 4)}></Input>,
          rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        },

        {
          key: 5,
          threshold: "Final Threshold",
          // lowerlimit: <Input disabled={true} ueArr[4]}></Input>,
          lowerlimit: tempVal[4],
          upperlimit: "Remaining Months",
          rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        },
      ];
    } else if (indx === 3) {
      dummy = [
        {
          key: 1,
          threshold: 1,
          lowerlimit: 0,
          upperlimit: <Input key={1} onChange={(e) => handleValues(e, 1)}></Input>,
          rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        },
        {
          key: 2,
          threshold: 2,
          lowerlimit: tempVal[1],

          upperlimit: <Input key={2} onChange={(e) => handleValues(e, 4)}></Input>,
          rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        },

        {
          key: 5,
          threshold: "Final Threshold",
          // lowerlimit: <Input disabled={true} ueArr[4]}></Input>,
          lowerlimit: tempVal[4],
          upperlimit: "Remaining Months",
          rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        },
      ];
    } else if (indx === 2) {
      dummy = [
        {
          key: 1,
          threshold: 1,
          lowerlimit: 0,
          upperlimit: <Input key={1} onChange={(e) => handleValues(e, 4)}></Input>,
          rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        },

        {
          key: 5,
          threshold: "Final Threshold",
          // lowerlimit: <Input disabled={true} ueArr[4]}></Input>,
          lowerlimit: tempVal[4],
          upperlimit: "Remaining Months",
          rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
        },
      ];
    }

    setDataSource([...dummy]);
  };

  const handleAdd = () => {
    // setIndx(indx + 1);
    let dataTemp: any = [...dataSource];
    // let temp = [...valueArr];
    // temp.splice(dataSource.length - 1, 0, 0);
    // setValueArr(temp);
    dataTemp.splice(dataSource.length - 1, 0, {
      key: dataSource.length,
      threshold: dataSource.length,
      lowerlimit: tempVal[dataSource.length],
      // lowerlimit: "",
      upperlimit: (
        <Input
          key={dataSource.length}
          id={dataSource.length}
          onChange={(e) => handleValues(e, dataSource.length + 1)}
        ></Input>
      ),
      rebate: props.rebateType ? <InputNumber addonAfter="%" /> : <Input></Input>,
    });
    console.log(dataSource);
    setDataSource(dataTemp);
  };

  const handleDelete = () => {
    if (dataSource.length > 1) {
      indx = indx - 1;
      let dataTemp: any = [...dataSource];
      dataTemp.splice(dataSource.length - 2, 1);
      setDataSource(dataTemp);
    } else {
      setError(true);
    }
  };

  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setError(false);
  };

  return (
    <>
      {error && (
        <Alert
          message="Error"
          description="Table must have atleast 2 thresholds"
          type="error"
          closable
          onClose={onClose}
        />
      )}
      <Row>
        <Col span={18}>
          {dataSource && (
            <div>
              <Table
                pagination={false}
                columns={columns}
                dataSource={[...dataSource]}
                bordered
                size="small"
              />
            </div>
          )}
        </Col>
        <Col span={4}>
          <div className="mt-8 ml-8 float-left">
            <Button onClick={handleAdd} className="">
              Add
            </Button>
            <Button onClick={handleDelete} className="mt-8" style={{ marginLeft: "1rem" }}>
              Remove
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}
