import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import { FaBox, FaFileContract, FaUsers, FaUserAlt, FaQuestion } from "react-icons/fa";
import { Receipt } from "@styled-icons/boxicons-solid/Receipt";
import "./body-layout/index.css";
import "react-pro-sidebar/dist/css/styles.css";
import background from "../globalTheme/sidenavbar.png";
import { Link } from "react-router-dom";
import { getAllProductDetailsData } from "../services/SimulatorConfiguration";
import AppContext from "../store";
import "./body-layout/index.css";
export default function Aside() {
  const context = React.useContext(AppContext);
  const [activePage, setActivePage] = React.useState(null);
  const [color, setColor] = React.useState(false);
  async function getListOfProducts() {
    const data2 = await getAllProductDetailsData();
    console.log(data2);
    context.setTableData(data2);
  }

  function handleActive(event: any) {
    setColor(true);
    console.log(event);
    if (!event.target.classList.value.includes("active")) {
      event.target.classList.toggle("active");
      if (activePage) {
        //@ts-ignore
        activePage.classList.remove("active");
      }
      setActivePage(event.target);
    }
    context.setCurrent(0);
  }

  return (
    <>
      {window.sessionStorage.getItem("sideHeader") === "true" ? (
        <div
          className="h-screen"
          style={{ position: "fixed", overflowY: "hidden", top: 0, bottom: 0 }}
        >
          <ProSidebar image={background} width={"15rem"}>
            <SidebarHeader
              style={{
                padding: "24px",
                textTransform: "uppercase",
                fontWeight: "bold",
                letterSpacing: "1px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <div>
                <img
                  style={{ marginBottom: "2rem", marginLeft: "1rem" }}
                  className="ml-28 h-11 items-baseline overflow-visible "
                  src="/logo/ZSLogoSmall.png"
                  alt="zslogo"
                />
                <div
                  className="flex rounded-md text-sm font-medium "
                  style={{
                    color: "white",
                    position: "absolute",
                    display: "inline",
                    marginTop: "-1rem",
                    width: "10rem ",
                    marginLeft: "-1rem",
                  }}
                >
                  Innovative Contracting
                </div>
              </div>
            </SidebarHeader>
            <SidebarContent>
              <Menu iconShape="circle">
                <MenuItem active={true} icon={<FaFileContract size={18} />}>
                  <Link
                    style={{
                      color: color ? "" : "#27a6a4",
                      fontWeight: color ? "" : "600",
                      fontSize: color ? "1.1rem" : "1.2rem",
                    }}
                    onClick={handleActive}
                    to="/contracts"
                  >
                    Contracts
                  </Link>
                </MenuItem>
                <MenuItem onClick={getListOfProducts} icon={<FaBox />}>
                  <Link
                    style={{ fontWeight: "400", fontSize: "1.1rem" }}
                    onClick={handleActive}
                    to="/products"
                  >
                    Products
                  </Link>
                </MenuItem>
                {/* <MenuItem
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "visible !important",
                    fontWeight: "400",
                    fontSize: "1.1rem",
                  }}
                  icon={<Receipt size={19} />}
                >
                  <Link
                    style={{ fontWeight: "400", fontSize: "1.1rem" }}
                    onClick={handleActive}
                    to="/construct-selection"
                  >
                    Constructs
                  </Link>
                </MenuItem> */}
                <MenuItem
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "visible !important",
                    fontWeight: "400",
                    fontSize: "1.1rem",
                  }}
                  icon={<FaUsers size={19} />}
                >
                  Users
                </MenuItem>
              </Menu>
            </SidebarContent>
            <SidebarFooter style={{ textAlign: "left" }}>
              <Menu iconShape="circle">
                <MenuItem icon={<FaUserAlt />}>
                  <Link
                    style={{ fontWeight: "400", fontSize: "1.1rem" }}
                    onClick={handleActive}
                    to="/Account"
                  >
                    Account
                  </Link>
                </MenuItem>
                <MenuItem icon={<FaQuestion />}>
                  <Link
                    style={{ fontWeight: "400", fontSize: "1.1rem" }}
                    onClick={handleActive}
                    to="/help"
                  >
                    Help
                  </Link>
                </MenuItem>
              </Menu>
            </SidebarFooter>
          </ProSidebar>
          <div className="main"></div>
        </div>
      ) : (
        <div
          className="bckImg"
          style={{
            backgroundImage: "url(/images/background.jpg)",
            backgroundRepeat: "round",
            height: "100vh%",
            margin: "0",
            minHeight: "47rem",
          }}
        ></div>
      )}
    </>
  );
}
