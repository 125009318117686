import { Button, Drawer, Table } from "antd";
import React, { useEffect, useRef } from "react";
import { Edit } from "@styled-icons/evaicons-solid/Edit";
import DosingSchedule from "./dosingSchedule";
import AppContext from "../../../store";
const columns = [
  {
    title: "Indication Name",
    dataIndex: "indication",
    width: 150,
  },
  {
    title: "1",
    dataIndex: "1",
  },
  {
    title: "2",
    dataIndex: "2",
  },
  {
    title: "3",
    dataIndex: "3",
  },
  {
    title: "4",
    dataIndex: "4",
  },
  {
    title: "5",
    dataIndex: "5",
  },
  {
    title: "6",
    dataIndex: "6",
  },
  {
    title: "7",
    dataIndex: "7",
  },
  {
    title: "8",
    dataIndex: "8",
  },
  {
    title: "9",
    dataIndex: "9",
  },
  {
    title: "10",
    dataIndex: "10",
  },
  {
    title: "11",
    dataIndex: "11",
  },
  {
    title: "12",
    dataIndex: "12",
  },
  {
    title: "Total Doses",
    dataIndex: "totalDoses",
  },
];
export default function ViewOnlyTable(props: {
  key?: string;
  id?: string;
  valueTable: string;
  fetchedData: any;
  SetFetchedData: any;
  counter?: number;
}) {
  const context = React.useContext(AppContext);

  const [data, setData] = React.useState<any>(props.fetchedData);

  useEffect(() => {
    setData(props.fetchedData);
  }, [props.fetchedData]);

  const [visible, setVisible] = React.useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const onSave = (x: any, id: any) => {
    let dummydata: {
      indication_ID: string;
      indication: string;
      1: string;
      2: string;
      3: string;
      4: string;
      5: string;
      6: string;
      7: string;
      8: string;
      9: string;
      10: string;
      11: string;
      12: string;
      totalDoses: string;
    }[] = [];
    let counter: any = 0;
    x.map((i: any) => {
      const temp = {
        indication: i["0"],
        indication_ID: props.fetchedData["sku1"][counter].indication_ID,
        1: i["1"],
        2: i["2"],
        3: i["3"],
        4: i["4"],
        5: i["5"],
        6: i["6"],
        7: i["7"],
        8: i["8"],
        9: i["9"],
        10: i["10"],
        11: i["11"],
        12: i["12"],
        totalDoses: "12",
      };
      dummydata.push(temp);
      //   let outputData = props.initialViewOnlyData;
      counter++;
    });
    // setData(dummydata);
    let obj = props.fetchedData;
    obj[id] = dummydata;
    props.SetFetchedData(obj);
    setVisible(false);
    //console.log("Inside fetchdata", props.fetchedData);
    // context.setTableData(dummydata);
  };

  return (
    <div>
      {props.fetchedData[props.valueTable] && (
        <div>
          <Button onClick={showDrawer} className="float-right mt-8" icon={<Edit size={22} />}>
            Edit table
          </Button>
          {/* {console.log(props.fetchedData[props.valueTable])} */}
          <Table
            pagination={false}
            columns={columns}
            key={Math.random() + ""}
            dataSource={props.fetchedData[props.valueTable]}
          />

          <Drawer
            title="Edit Dosing Schedule"
            placement="bottom"
            onClose={onClose}
            visible={visible}
          >
            <div>
              {visible && (
                <DosingSchedule
                  onSave={onSave}
                  key={props.key}
                  id={props.key}
                  viewOnlyTableData={props.fetchedData[props.valueTable]}
                  valueTable={props.valueTable}
                />
              )}
              {/* <DosingSchedule viewOnlyTableData={data} /> */}
            </div>
          </Drawer>
        </div>
      )}
    </div>
  );
}
