import { Edit } from "@styled-icons/evaicons-solid/Edit";
import React, { useRef, useEffect } from "react";
import jspreadsheet from "jspreadsheet-ce";
import "../../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
import "../../../../node_modules/jsuites/dist/jsuites.css";
import { Button, Input } from "antd";
import AppContext from "../../../store";

export default function EpidemiologyEditTables(props: {
  columns: any;
  indicationName?: any;
  dataSource: any;
  onSave: any;
  indicationId?: any;
  key: any;
  name: any;
  data?: any;
}) {
  React.useEffect(() => {}, [props.dataSource]);

  const context = React.useContext(AppContext);
  const jRef: any = useRef(null);
  console.log(props.dataSource);
  console.log(props.columns);

  let indicationVal: any = props.indicationName;
  console.log(indicationVal);
  // props.dataSource.forEach((object: any) => {
  //   delete object["indication"];
  // });

  let obj: any = [];
  // console.log(props.dataSource);
  props.dataSource.map((ob: any) => {
    let temp: any = {};
    Object.entries(ob).map(([key, value]) => {
      console.log(key, value);
      if (value !== indicationVal) {
        temp[key] = value;
      }
    });
    obj.push(temp);
  });

  console.log("OBJ HERE", obj);

  let dataSourceForEditTable: any = [];
  dataSourceForEditTable = [...props.dataSource];

  console.log("Datasource for edit table", props.name);
  // let startBoundYear = props.data[0].contractStartDate.substring(0,4);
  const options: any = {
    // data: dataSourceForEditTable,
    data: obj,
    allowCreateTabs: true,
    columns: props.columns,
    minDimensions: [0, 0],
    columnSorting: false,
    tableWidth: "102rem",
    hideIndex: false,
    tableHeight: "600px",
    updateTable: function (
      worksheet: any,
      cell: any,
      x: any,
      y: any,
      source: any,
      value: any,
      label: any
    ) {
      for (let val = 0; val <= props.dataSource.length; val++)
        if (x == 0 && y == val) {
          cell.classList.add("readonly");
        }

      if (props.name === "monthlyPatient") {
        if (x < props.data[0].contractStartDate.substring(5, 7) && y == 0) {
          cell.classList.add("readonly");
        }

        if (
          x > props.data[0].contractEndDate.substring(5, 7) &&
          y ==
            parseInt(props.data[0].contractEndDate.substring(0, 4)) -
              parseInt(props.data[0].contractStartDate.substring(0, 4))
        ) {
          cell.classList.add("readonly");
        }
      }
    },
  };

  function InsertData() {
    const x = jRef.current.jexcel.getJson();
    let finalArr: any = [];
    console.log(x);
    if (
      props.name === "overallPricing" ||
      props.name === "confidentialNetPricing" ||
      props.name === "confidentialNetPricing2"
    ) {
      props.onSave(x, props.name);
    } else {
      // Object.entries(obj).map(([key, value]) => {
      //   temp[key] = value;
      // });

      obj.map((ob: any) => {
        let temp: any = {};
        Object.entries(ob).map(([key, value]) => {
          console.log(key, value, ob);
          temp[key] = x.filter((sub: any) => sub.subpopulation === ob["subpopulation"])[0][key];
        });
        temp["indication"] = indicationVal;

        finalArr.push(temp);
      });

      // temp["indication"] = indicationVal;

      // x.map((val: any) => {
      //   val.indication = indicationVal;
      // });
      console.log(finalArr);

      if (props.name === "patientNo") {
        props.onSave(props.indicationId, finalArr, props.name);
      } else {
        props.onSave(props.indicationId, x, props.name);
      }
    }
  }

  useEffect(() => {
    if (!jRef.current.jspreadsheet) {
      jspreadsheet(jRef.current, options);
      if (document.getElementsByClassName("jexcel_selectall")[0]) {
        document.getElementsByClassName("jexcel_selectall")[0].innerHTML = "Year";
      }
    }
  }, [options]);
  return (
    <>
      {obj && (
        <div className="mt-8">
          <div>
            <div>
              <br />
              <div
                className="w-screen"
                // key={props.key}
                //  id={props.id}
                ref={jRef}
              />
              <Button
                onClick={() => {
                  InsertData();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
