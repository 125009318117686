import React, { useRef, useEffect } from "react";
import jspreadsheet from "jspreadsheet-ce";
import "../../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
// import "../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
import "../../../../node_modules/jsuites/dist/jsuites.css";
// import "../KmCurveData/index.css";
import { Button, Input } from "antd";
import AppContext from "../../../store";
export default function DosingSchedule(props: {
  viewOnlyTableData: any;
  valueTable: any;
  onSave: any;
  key: any;
  id: any;
}) {
  const context = React.useContext(AppContext);
  const jRef: any = useRef(null);
  let excelInitialData = props.viewOnlyTableData;
  let inputData: any = [];
  excelInitialData.map((i: any, index: any) => {
    inputData.push([
      i["indication"],

      i["1"],
      i["2"],
      i["3"],
      i["4"],
      i["5"],
      i["6"],
      i["7"],
      i["8"],
      i["9"],
      i["10"],
      i["11"],
      i["12"],
      "=SUM(B" + (index + 1) + ":M" + (index + 1) + ")",
    ]);
  });
  const options: any = {
    // sheetName: "SKU-1",
    data: inputData,
    allowCreateTabs: true,
    columns: [
      {
        title: "Indication",
        type: "numeric",
        width: "100px",
      },
      {
        title: "1",
        type: "numeric",
      },
      {
        title: "2",
        type: "numeric",
      },
      {
        title: "3",
        type: "numeric",
      },
      {
        title: "4",
        type: "numeric",
      },
      {
        title: "5",
        type: "numeric",
      },
      {
        title: "6",
        type: "numeric",
      },
      {
        title: "7",
        type: "numeric",
      },
      {
        title: "8",
        type: "numeric",
      },
      {
        title: "9",
        type: "numeric",
      },
      {
        title: "10",
        type: "numeric",
      },
      {
        title: "11",
        type: "numeric",
      },
      {
        title: "12",
        type: "numeric",
      },
      {
        title: "Total Doses",
        type: "numeric",
        width: "100px",
      },
    ],
    minDimensions: [0, 0],
    columnSorting: false,
    tableWidth: "600px",
    hideIndex: false,
    tableHeight: "600px",
  };

  useEffect(() => {
    if (!jRef.current.jspreadsheet) {
      jspreadsheet(jRef.current, options);
      if (document.getElementsByClassName("jexcel_selectall")[0]) {
        document.getElementsByClassName("jexcel_selectall")[0].innerHTML = "Year";
      }
    }
  }, [options]);

  function InsertData() {
    const x = jRef.current.jexcel.getJson();
    // console.log(x);
    x.map((arr: any, index: any) => {
      arr["13"] = jRef.current.jexcel.getValue("N" + (index + 1), true);
    });
    context.setExcelData(x);
    // timeout(1000);
    props.onSave(x, props.valueTable);
  }

  return (
    <div>
      <div>
        <p className="italic">
          {" "}
          Please enter the monthly dosing schedule, i.e., number of doses to be administered in each
          month
        </p>
        <br />
        <div key={props.key} id={props.id} ref={jRef} />
      </div>
      <Button
        onClick={() => {
          InsertData();
        }}
      >
        Save
      </Button>
    </div>
  );
}
