import {
  getDetailsForContractData,
  getDetailsForContractDataOnEdit,
  getNewContractDetailsData,
  saveContractDetailsData,
} from "../../../services/ContractInput";

export const getDetailsForContract: (productId: any) => Promise<unknown> = async (
  productId: any
) => {
  const data = await getDetailsForContractData(productId);

  if (data === null || data === undefined) return null;

  return data;
};

export const getDetailsForContractOnEdit: (contractId: any) => Promise<unknown> = async (
  contractId: any
) => {
  const data = await getDetailsForContractDataOnEdit(contractId);

  if (data === null || data === undefined) return null;

  return data;
};

export const getNewContractDetails: () => Promise<unknown> = async () => {
  const data = await getNewContractDetailsData();

  if (data === null || data === undefined) return null;

  return data;
};

export const saveContractDetails: (value: any) => Promise<unknown> = async (value: any) => {
  const data = await saveContractDetailsData(value);

  if (data === null || data === undefined) return null;

  return data;
};
