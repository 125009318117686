import React, { useState } from "react";
const AppContext2 = React.createContext({
  selectedIndication: [],
  brandId: null,
  // contractId: null,
  // setContractId: (contractId: any) => {},
  setSelectedIndication: (selectedIndication: any) => {},
  setBrandId: (brandId: any) => {},
});

export function ContextProvider(props: { children: React.ReactNode }) {
  const [selectedIndication, setIsSelectedIndication] = React.useState<any>();
  const [brandId, setIsBrandId] = React.useState<any>(null);
  // const [contractId, setIsContractId] = React.useState<any>(null);

  // function setContractId(contractId: any) {
  //   setIsContractId(contractId);
  // }

  function setSelectedIndication(dosingData: any) {
    setIsSelectedIndication(selectedIndication);
  }

  function setBrandId(brandId: any) {
    setIsBrandId(brandId);
  }

  const contexts2 = {
    selectedIndication: selectedIndication,
    setSelectedIndication: setSelectedIndication,
    // setContractId: setContractId,
    brandId: brandId,
    // contractId: contractId,
    setBrandId: setBrandId,
  };

  return <AppContext2.Provider value={contexts2}>{props.children}</AppContext2.Provider>;
}

export default AppContext2;
