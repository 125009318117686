import React, { useState } from "react";
const AppContext = React.createContext({
  userId: "",
  isAdmin: false,
  login: true,
  submit1: false,
  productJson: {},
  current: 0,
  page: 0,
  brandId: "",
  excelData: [],
  tableData: [],
  productDetails: {},
  singleProduct: {},
  dosingData: [],
  step3: true,
  setStep3: (step3: boolean) => {},
  setLogin: (login: any) => {},
  setDosingData: (dosingData: any) => {},
  setBrandId: (brandId: any) => {},
  setSingleProduct: (singleProduct: any) => {},
  setProductDetails: (productDetails: any) => {},
  setPage: (page: number) => {},
  setUser: (userId: string) => {},
  setAdmin: (isAdmin: boolean) => {},
  setProductJson: (productJson: any) => {},
  setCurrent: (current: number) => {},
  setSubmit1: (submit1: boolean) => {},
  setExcelData: (excelData: any) => {},
  setTableData: (tableData: any) => {},

  contractId: null,
  setContractId: (contractId: any) => {},
});

export function ContextProvider(props: { children: React.ReactNode }) {
  const [contractId, setIsContractId] = React.useState<any>(null);

  const [userId, setUserId] = React.useState("");
  const [login, setIsLogin] = React.useState(false);
  const [current, setIsCurrent] = React.useState(0);
  const [submit1, setIsSubmit1] = React.useState(false);
  const [page, setIsPage] = React.useState(0);
  const [dosingData, setIsDosingData] = React.useState<any>();
  const [productDetails, setIsProductDetails] = React.useState({});
  const [singleProduct, setIsSingleProduct] = React.useState({});
  const [isAdmin, setIsAdmin] = React.useState(
    localStorage.getItem("admin") === "true" ? true : false
  );
  const [step3, setIsStep3] = React.useState<boolean>(true);
  const [excelData, setIsExcelData] = React.useState([]);
  const [productJson, setIsProductJson] = React.useState([]);
  const [tableData, setIsTableData] = React.useState([]);
  const [brandId, setIsBrandId] = React.useState("");

  function setContractId(contractId: any) {
    setIsContractId(contractId);
  }

  function setStep3(step3: any) {
    setIsStep3(step3);
  }

  function setLogin(login: any) {
    setIsLogin(login);
  }

  function setDosingData(dosingData: any) {
    setIsDosingData(dosingData);
  }

  function setBrandId(brandId: any) {
    setIsBrandId(brandId);
  }

  function setTableData(tableData: any) {
    setIsTableData(tableData);
  }

  function setSingleProduct(singleProduct: any) {
    setIsSingleProduct(singleProduct);
  }

  function setProductDetails(productDetails: any) {
    setIsProductDetails(productDetails);
  }

  // let data: any = getProductDetails("");
  // setProductDetails(data);
  // console.log("prod", productDetails);

  function setExcelData(excelData: any) {
    setIsExcelData(excelData);
  }

  function setPage(page: number) {
    setIsPage(page);
  }

  function setCurrent(current: number) {
    setIsCurrent(current);
  }

  function setSubmit1(submit1: boolean) {
    setIsSubmit1(submit1);
  }

  function setUser(userId: string) {
    setUserId(userId);
    window.sessionStorage.setItem("userId", userId);
  }

  function setAdmin(isAdmin: boolean) {
    setIsAdmin(isAdmin);
    window.sessionStorage.setItem("isAdmin", isAdmin ? "true" : "false");
  }

  function setProductJson(productJson: any) {
    setIsProductJson(productJson);
    window.sessionStorage.setItem("productJson", productJson);
  }

  const contexts = {
    userId: userId,
    dosingData: dosingData,
    setDosingData: setDosingData,
    isAdmin: isAdmin,
    login: login,
    step3: step3,
    setStep3: setStep3,
    setLogin: setLogin,
    tableData: tableData,
    productDetails: productDetails,
    brandId: brandId,
    setBrandId: setBrandId,
    setProductDetails: setProductDetails,
    setUser: setUser,
    singleProduct: singleProduct,
    setSingleProduct: setSingleProduct,
    setAdmin: setAdmin,
    setProductJson: setProductJson,
    productJson: productJson,
    setCurrent: setCurrent,
    current: current,
    submit1: submit1,
    setSubmit1: setSubmit1,
    page: page,
    setPage: setPage,
    setTableData: setTableData,
    setExcelData: setExcelData,
    excelData: excelData,
    contractId: contractId,
    setContractId: setContractId,
  };

  return <AppContext.Provider value={contexts}>{props.children}</AppContext.Provider>;
}

export default AppContext;
