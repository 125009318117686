import { List, Avatar, Button, Form, Collapse, Tooltip } from "antd";
import { AddCircle } from "@styled-icons/fluentui-system-filled/AddCircle";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";
import { Delete } from "@styled-icons/fluentui-system-regular/Delete";
import { Archive } from "@styled-icons/boxicons-regular/Archive";
import React, { useEffect, useState } from "react";
import { Table, Radio, Divider } from "antd";
import { Link } from "react-router-dom";
import ConstructSetup from "./ConstructSetup";
import { v4 as uuidv4 } from "uuid";
import AppContext from "../../../store";
import background from "../../../globalTheme/background2.jpg";

import {
  constructDetailsBasedOnConstructId,
  getAllConstructs,
  newlyAddedConstruct,
} from "./service-helper";

const columns = [
  {
    title: "Construct Name",
    dataIndex: "constructName",
  },

  // {
  //   title: "Construct Type",
  //   dataIndex: "constructType",
  // },

  {
    title: "Action",
    dataIndex: "action",
  },
];
const data = [
  {
    key: "1",
    constructName: "Imfinzi",
    constructType: "Outcome Based",
    action: (
      <>
        <EditOutline className="ml-4" size={22} />
        <Delete className="ml-4" size={22} />
        <Archive className="ml-4" size={18} />
      </>
    ),
  },
  {
    key: "2",
    constructName: "Calquence",
    constructType: "Financial Based",
    action: (
      <>
        <EditOutline className="ml-4" size={22} />
        <Delete className="ml-4" size={22} />
        <Archive className="ml-4" size={18} />
      </>
    ),
  },
  {
    key: "3",
    constructName: "Imfinzi",
    constructType: "Outcome Based",
    action: (
      <>
        <EditOutline className="ml-4" size={22} />
        <Delete className="ml-4" size={22} />
        <Archive className="ml-4" size={18} />
      </>
    ),
  },
];

interface DataType {
  key: React.Key;
  constructName: string;
  constructType: string;
}

export default function ConstructList() {
  const context = React.useContext(AppContext);
  console.log(context.brandId);
  const [fetchedData, setFetchData] = React.useState<any>();
  const [dataForTable, setDataForTable] = React.useState<any>();
  const [constructDataForID, setConstructDataForID] = React.useState<any>();
  const [constructPage, setConstructPage] = useState<Number>(0);

  const handleEdit = async (constructId: any, constructData: any) => {
    // let data: any = await constructDetailsBasedOnConstructId(constructId);
    // console.log(constructId, constructData);
    setNewConstruct(false);
    let initialData: any = {};

    initialData.constructName = constructData.name;
    initialData.constructType = constructData.selectedConstructType_ID;
    initialData.rebateThresholdInputType = constructData.selectedRebateThresholdInputType_ID;
    initialData.rebateCondition = constructData.selectedRebateCondition_ID;
    initialData.ivsRebateType = constructData.selectedIVSRebateType_ID;
    initialData.rebateTimePeriod = constructData.selectedRebateTimePeriod_ID;
    initialData.constructId = constructId;

    let temp = [];

    if (initialData.constructName === "Tiered Pricing") {
      initialData.constructEvaluationParameters = ["Volume Sold"];
    } else if (initialData.constructName === "Value Warranty") {
      initialData.constructEvaluationParameters = ["Patients", "New Patients"];
    } else if (initialData.constructName === "Cost Capitition") {
      initialData.constructEvaluationParameters = ["Drug Price", "Overall Contract"];
    } else if (initialData.constructName === "Duration Based Pricing") {
      initialData.constructEvaluationParameters = ["Drug Price", "Per Patient"];
    } else {
      temp.push(constructData.selectedConstructEvaluationParameters_ID);
      if (constructData.selectedParameterType_ID) temp.push("Overall Contract");
      initialData.constructEvaluationParameters = temp;
    }

    setConstructDataForID(initialData);

    console.log(initialData, "initialData");
    setConstructPage(1);
  };

  useEffect(() => {}, [constructDataForID]);

  const FetchData = async () => {
    const constructsData = await getAllConstructs(context.brandId);
    setFetchData(constructsData);
  };

  useEffect(() => {
    FetchData();
  }, []);

  useEffect(() => {}, [context.brandId]);

  useEffect(() => {
    FetchData();
  }, [constructPage]);

  useEffect(() => {
    let tableDataFinal: any = [];
    if (fetchedData && fetchedData[0].construct_Id !== null) {
      // console.log("fetchData", fetchedData);

      fetchedData.map((i: any) => {
        let tableDataObject: any = [];
        tableDataObject.constructName = i.name;
        tableDataObject.key = i.construct_Id;
        tableDataObject.action = (
          <>
            <Tooltip placement="topLeft" title="Edit ">
              <EditOutline
                key={"edit_" + i.productId}
                onClick={() => handleEdit(i.construct_Id, i)}
                className="ml-4"
                size={22}
              />
            </Tooltip>
            <Tooltip placement="topLeft" title="Delete ">
              <Delete key={"delete_" + i.productId} className="ml-4" size={22} />
            </Tooltip>
            <Tooltip placement="topLeft" title="Archive ">
              <Archive key={"copy_" + i.productId} className="ml-4" size={18} />
            </Tooltip>
          </>
        );
        tableDataFinal.push(tableDataObject);
      });
    }
    setDataForTable([...tableDataFinal]);
  }, [fetchedData]);

  useEffect(() => {}, [dataForTable]);

  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">("radio");
  const [newConstruct, setNewConstruct] = React.useState<boolean>(false);
  const setValueOfConstructPage = () => {
    setConstructPage(0);
  };

  let dataForNew: any;
  const handleClick = async () => {
    setConstructPage(1);
    dataForNew = await newlyAddedConstruct();
    setNewConstruct(true);
  };

  let uuid = uuidv4();

  const handleNext = () => {
    context.setCurrent(context.current + 1);
    if (context.current === 2) {
      context.setSubmit1(true);
    }
  };

  const prev = () => {
    context.setCurrent(context.current - 1);
  };

  const handleReturn = () => {
    setConstructPage(0);
    setConstructDataForID([]);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({}),
  };

  return (
    <>
      {/* {console.log(constructPage)} */}
      {dataForTable && (
        <div className="h-auto">
          {/* <Collapse defaultActiveKey={["2"]}> */}
          <div>
            <div className="h-auto" style={{ minHeight: "34rem" }}>
              {constructPage !== 0 ? (
                <>
                  <Button
                    className="float-right mt-8 mr-4"
                    htmlType="button"
                    onClick={handleReturn}
                    type="primary"
                  >
                    Return to Constructs
                  </Button>
                </>
              ) : (
                <></>
              )}

              {constructPage === 0 ? (
                <div>
                  <div>
                    <div
                      style={{ marginLeft: "-28rem" }}
                      className="font-bold float-left text-2xl text-zsgray mt-2 w-full"
                    >
                      Constructs{" "}
                      {context.brandId === "7e1af608-8030-4601-8ef1-c5f5e48f1743" && (
                        <span>for Syrifolumab</span>
                      )}
                      {context.brandId === "1ff46031-8545-4753-882d-33409ca2e799" && (
                        <span>for Ciplolcysteine</span>
                      )}
                      {context.brandId === "a5756bfa-b21b-49f7-95fb-5cdf2481f83c" && (
                        <span>for Azpelumab </span>
                      )}
                    </div>

                    <div className="mt-8">
                      {" "}
                      {/* <Link to="/app-management">
                        <AddCircle
                          className="float-right mr-16"
                          size={30}
                          color={"#27a6a4"}
                          onClick={handleClick}
                        />
                        Add Construct
                      </Link> */}
                    </div>

                    <Table
                      rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                      }}
                      size="small"
                      style={{ padding: "2rem", minHeight: "28rem" }}
                      columns={columns}
                      dataSource={dataForTable}
                      pagination={false}
                    />
                    <Button
                      className="float-left ml-8 -mt-36"
                      type="primary"
                      onClick={handleClick}
                      style={{ paddingBottom: "2.3rem" }}
                      icon={
                        <AddCircle
                          className="mr-4 mb-1"
                          size={30}
                          color={"white"}
                          onClick={handleClick}
                        />
                      }
                      size="large"
                    >
                      Add New Construct
                    </Button>
                  </div>
                  <div className="mt-12">
                    <Form.Item>
                      <Button onClick={() => prev()}>Previous</Button>
                      <Button
                        className="ml-4"
                        type="primary"
                        htmlType="submit"
                        onClick={handleNext}
                        style={{ width: "5rem" }}
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              ) : (
                <>
                  <ConstructSetup
                    constructDataForID={constructDataForID}
                    setValueOfConstructPage={setValueOfConstructPage}
                    data={data}
                    id={uuid}
                    constructPage={constructPage}
                    setConstructPage={() => setConstructPage}
                    dataForNew={dataForNew}
                    newConstruct={newConstruct}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
