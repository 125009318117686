import React from "react";
import StepsLayout from "../../layout/body-layout/steps";
import AppContext from "../../store";
import Construct from "../ConstructSelection/ConstructPage.tsx/Construct";
import ConstructPage from "../ConstructSelection/ConstructPage.tsx/ConstructPage";
import ContractSetupPage from "./ContractSetup";
import Epidemiology from "./Epidemiology";
import KmSelection from "./KmSelection";
import Pricing from "./Pricing";

export default function ContractInput() {
  const context = React.useContext(AppContext);
  const subTabs =
    context.step3 === true
      ? [
          {
            // id: 1,
            title: "Contract Setup",
            content: <ContractSetupPage />,
          },
          {
            // id: 2,
            title: "Epidemiology",
            content: <Epidemiology />,
          },
          {
            // id: 3,
            title: "KM Curve Selection",
            content: <KmSelection />,
          },
          {
            // id: 4,
            title: "Pricing",
            content: <Pricing />,
          },
          {
            // id: 4,
            title: "Constructs",
            content: <ConstructPage />,
          },
        ]
      : [
          {
            // id: 1,
            title: "Contract Setup",
            content: <ContractSetupPage />,
          },
          {
            // id: 2,
            title: "Epidemiology",
            content: <Epidemiology />,
          },
          {
            // id: 4,
            title: "Pricing",
            content: <Pricing />,
          },
          {
            // id: 4,
            title: "Constructs",
            content: <ConstructPage />,
          },
        ];

  // const subTabs2 = [
  //   {
  //     // id: 1,
  //     title: "Contract Setup",
  //     content: <ContractSetupPage />,
  //   },
  //   {
  //     // id: 2,
  //     title: "Epidemiology",
  //     content: <Epidemiology />,
  //   },
  //   {
  //     // id: 4,
  //     title: "Pricing",
  //     content: <Pricing />,
  //   },
  // ];

  const [page3, setPage3] = React.useState(false);
  return (
    <>
      <>
        <StepsLayout subTabs={subTabs} />
      </>
    </>
  );
}
