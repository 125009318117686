import {
  getEpidemiologyDetailsData,
  saveEpidemiologyDetailsData,
} from "../../../services/ContractInput";

export const getEpidemiologyDetails: (contractId: any) => Promise<unknown> = async (
  contractId: any
) => {
  const data = await getEpidemiologyDetailsData(contractId);

  if (data === null || data === undefined) return null;

  return data;
};

export const saveEpidemiologyDetails: (value: any) => Promise<unknown> = async (value: any) => {
  const data = await saveEpidemiologyDetailsData(value);

  if (data === null || data === undefined) return null;

  return data;
};
