import { Button, Drawer, Table } from "antd";
import React, { useEffect, useRef } from "react";
import { Edit } from "@styled-icons/evaicons-solid/Edit";
import DosingSchedule from "../../SimulatorConfiguration/BrandProductSetup/dosingSchedule";
import EpidemiologyEditTables from "./epidemiologyEditTables";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";

export default function TablesForEpidemiology(props: {
  tableData: any;
  indicationName?: any;
  tableColumns: any;
  indicationId: any;
  tableColumnsEdit?: any;
  key: any;
  name: any;
  data?: any;
  onSave: any;
  uniformDist?: any;
}) {
  const [visible, setVisible] = React.useState(false);
  const showDrawer = () => {
    setVisible(true);
    console.log("Overall Data Here", props.tableData);
  };
  const onClose = () => {
    setVisible(false);
  };

  if (props.uniformDist) {
    console.log(props.tableData, props.tableColumns);
    props.tableData.map((d: any) => {
      d.jan = "8.3%";
      d.feb = "8.3%";
      d.mar = "8.3%";
      d.apr = "8.3%";
      d.may = "8.3%";
      d.jun = "8.3%";
      d.jul = "8.3%";
      d.aug = "8.3%";
      d.sep = "8.3%";
      d.oct = "8.3%";
      d.nov = "8.3%";
      d.dec = "8.3%";
      d.sum = "100%";
    });
  }

  if (props.name === "avgDist") {
    if (props.tableData.length !== 3) {
      let totalRow: any = {
        2022: "100%",
        2023: "100%",
        2024: "100%",
        2025: "100%",
        2026: "100%",
        2027: "100%",
        2028: "100%",
        2029: "100%",
        dose: "Total",
      };
      props.tableData.push(totalRow);
    }
  }
  console.log("Console table coloumn", props.name, props.tableColumns, props.tableData);

  return (
    <div className="mt-8">
      {props.tableData && (
        <>
          {/* <Button onClick={showDrawer} className="float-right mr-8 mt-8" icon={<Edit size={22} />}>
            Edit table
          </Button> */}
          <p className="float-right mr-8 mt-8">
            <EditOutline size={22} onClick={showDrawer} />
          </p>

          <Table
            bordered
            size="small"
            className="mr-8"
            // scroll={{ x: 1300 }}
            columns={props.tableColumns}
            key={Math.random() + ""}
            pagination={false}
            dataSource={props.tableData}
          />

          <Drawer title="Edit Table Data" placement="bottom" onClose={onClose} visible={visible}>
            <div>
              {visible && <></>}
              <EpidemiologyEditTables
                indicationName={props.indicationName}
                columns={
                  props.tableColumnsEdit !== undefined ? props.tableColumnsEdit : props.tableColumns
                }
                dataSource={props.tableData}
                onSave={props.onSave}
                indicationId={props.indicationId}
                key={props.key}
                name={props.name}
                data={props.data}
              />
            </div>
          </Drawer>
        </>
      )}
    </div>
  );
}
