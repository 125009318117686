import { Button, Form, Switch } from "antd";
import React from "react";
import AppContext from "../../../store";
import PricingTables from "./pricingTables";
import { getPricingDetails } from "./service-helper";
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import { savePricingDetails } from "../KmSelection/service-helper";
import { Link, useNavigate } from "react-router-dom";
import background from "../../../globalTheme/background2.png";

export default function Pricing() {
  const context = React.useContext(AppContext);
  let navigate = useNavigate();

  const [dummyData, setDummyData] = React.useState<any>(null);
  const [startYear, setStartYear] = React.useState<any>();
  const [endYear, setEndYear] = React.useState<any>();
  const [followUp, setFollowUp] = React.useState<any>();
  const [benchMarkPrice1, setBenchMarkPrice1] = React.useState<any>();
  const [benchMarkPrice2, setBenchMarkPrice2] = React.useState<any>();
  const [indicationSwitch, setIndicationSwitch] = React.useState(false);
  const [columnsForConfidentialPricing, setColumnsFOrConfidentialPricing] = React.useState<any>();
  const [columnsForConfidentialPricing2, setColumnsFOrConfidentialPricing2] = React.useState<any>();
  const [columnsForConfidentialPricing3, setColumnsFOrConfidentialPricing3] = React.useState<any>();

  const [columnsForConfidentialPricingEdit, setColumnsFOrConfidentialPricingEdit] =
    React.useState<any>();

  const [columnsForOverallPricing, setColumnsForOverallPricing] = React.useState<any>();
  const [columnsForOverallPricingEdit, setColumnsForOVerallPricingEdit] = React.useState<any>();
  const [dataForOverallPricing, setDataForOverallPricing] = React.useState<any>();
  const [dataForConfidentialNetPricingData, setDataForConfidentialNetPricingData] =
    React.useState<any>();
  const [dataForConfidentialNetPricingData2, setDataForConfidentialNetPricingData2] =
    React.useState<any>();

  const [dataForConfidentialNetPricingData3, setDataForConfidentialNetPricingData3] =
    React.useState<any>();
  const FetchData = async () => {
    // call api for getting values here
    getPricingDetails(context.contractId).then((val) => {
      setDummyData(val);
      console.log(val);
    });
  };

  React.useEffect(() => {
    FetchData();
  }, []);

  React.useEffect(() => {
    if (dummyData) {
      console.log("DummyData", dummyData);
      // sets columns for patient numbers according to the contract duration
      let sy = parseInt(dummyData[0].startDate.substring(0, 4));
      let ey = parseInt(dummyData[0].endDate.substring(0, 4));
      let fu = Math.ceil(dummyData[0].followUpMonths / 12);

      setStartYear(sy);
      setEndYear(ey);
      setFollowUp(fu);

      let arrForOverallPricingCol = [
        {
          title: " Gross ex manufacturing price for ",
          dataIndex: "dosage",
          name: "dosage",
          width: "25rem",
        },
      ];
      let arrForOverallPricingEditCol = [{ title: "dosage", name: "dosage" }];

      let arrForConfidentialNetPricingCol = [
        {
          title: "Indication Based Pricing for",
          dataIndex: "indications",
          name: "indications",
          width: "15rem",
        },
      ];

      let arrForConfidentialNetPricingCol2: any;
      if (dummyData.length > 1) {
        arrForConfidentialNetPricingCol2 = [
          {
            title: "Indication Based Pricing for",
            dataIndex: "indications",
            name: "indications",
            width: "15rem",
          },
        ];
      }

      let arrForConfidentialNetPricingCol3: any;
      if (dummyData.length > 1) {
        arrForConfidentialNetPricingCol3 = [
          {
            title: "Mandatory Rebate % for",
            dataIndex: "indications",
            name: "indications",
            width: "15rem",
          },
        ];
      }

      let arrForConfidentialNetPricingEditCol = [
        {
          dataIndex: "indications",
          name: "indications",
        },
      ];

      for (let year = sy; year <= ey + fu; year++) {
        let tempObjForCol: any = {};
        let objForEditingTableCol: any = {};
        if (year > endYear) {
          tempObjForCol.title = (
            <p className="font-bold mt-2" style={{ color: "red" }}>
              {year + ""}
            </p>
          );
          tempObjForCol.dataIndex = year + "";
          tempObjForCol.name = year + "";
          tempObjForCol.width = "8rem";
          objForEditingTableCol.title = year + "";
          objForEditingTableCol.name = year + "";
        } else {
          tempObjForCol.title = year + "";
          tempObjForCol.dataIndex = year + "";
          tempObjForCol.width = "8rem";
          tempObjForCol.name = year + "";

          objForEditingTableCol.title = year + "";
          objForEditingTableCol.name = year + "";
        }
        arrForConfidentialNetPricingCol.push(tempObjForCol);
        if (dummyData.length > 1) {
          arrForConfidentialNetPricingCol2.push(tempObjForCol);
          arrForConfidentialNetPricingCol3.push(tempObjForCol);
        }
        arrForConfidentialNetPricingEditCol.push(objForEditingTableCol);
        arrForOverallPricingCol.push(tempObjForCol);
        arrForOverallPricingEditCol.push(objForEditingTableCol);
      }

      let dfop: any = [];

      // when 1 dose ( i.e. dummyData.length === 1)
      if (dummyData[0].overallPricingModel.length > 0) {
        let objForOverallPricing: any = {};
        objForOverallPricing.dosage = dummyData[0].sku_Mg + " mg tablet";
        dummyData[0].overallPricingModel.map((price: any) => {
          objForOverallPricing[price.year + ""] = "$" + price.grossExManufacturingPrice;
        });

        dfop.push(objForOverallPricing);
      } else {
        let objForOverallPricing: any = {};
        objForOverallPricing.dosage = dummyData[0].sku_Mg + " mg tablet";
        for (let year = sy; year <= ey + fu; year++) {
          objForOverallPricing[year + ""] = "$" + 0;
        }
        dfop.push(objForOverallPricing);
      }

      if (dummyData.length === 2) {
        if (dummyData[1].overallPricingModel.length > 0) {
          let objForOverallPricing: any = {};
          objForOverallPricing.dosage = dummyData[1].sku_Mg + " mg tablet";
          dummyData[1].overallPricingModel.map((price: any) => {
            objForOverallPricing[price.year + ""] = "$" + price.grossExManufacturingPrice;
          });

          dfop.push(objForOverallPricing);
        } else {
          let objForOverallPricing: any = {};
          objForOverallPricing.dosage = dummyData[1].sku_Mg + " mg tablet";
          for (let year = sy; year <= ey + fu; year++) {
            objForOverallPricing[year + ""] = "$" + 0;
          }
          dfop.push(objForOverallPricing);
        }
      }

      // for data of confidential net pricing
      let dfcnp: any = [];
      let dfcnp2: any = [];
      let dfcnp3: any = [];

      // when confidential net starting price arr is []
      dummyData[0].contractPricingIndicationModel.map((ind: any) => {
        let objForDataOfConfidentialPricing: any = {};

        objForDataOfConfidentialPricing.indications = ind.indicationName;
        if (ind.confidentialNetStartingPriceModel.length > 0) {
          ind.confidentialNetStartingPriceModel.map((pricing: any) => {
            if (pricing.netPrice) {
              objForDataOfConfidentialPricing[pricing.year] = "$" + pricing.netPrice;
            }
          });
        } else {
          for (let count = sy; count <= ey + fu; count++) {
            // objForDataOfConfidentialPricing[count + ""] = pricing.netPrice;
            objForDataOfConfidentialPricing[count + ""] = "$" + 0;
          }
        }
        ind.confidentialNetStartingPriceModel.map((pricing: any) => {
          // pricing.year =
          // if (pricing.netPrice) {
          objForDataOfConfidentialPricing[pricing.year] = "$" + pricing.netPrice;

          // }
        });
        console.log("pushed here", objForDataOfConfidentialPricing);
        dfcnp.push(objForDataOfConfidentialPricing);
      });

      if (dummyData.length > 1) {
        // dummyData.map((d: any) => {
        dummyData[1].contractPricingIndicationModel.map((ind: any) => {
          let objForDataOfConfidentialPricing: any = {};
          let objForDataOfConfidentialPricing3: any = {};

          objForDataOfConfidentialPricing.indications = ind.indicationName;
          objForDataOfConfidentialPricing3.indications = ind.indicationName;

          if (ind.confidentialNetStartingPriceModel.length > 0) {
            ind.confidentialNetStartingPriceModel.map((pricing: any) => {
              // if (pricing.netPrice !) {
              objForDataOfConfidentialPricing[pricing.year] = "$" + pricing.netPrice;
              objForDataOfConfidentialPricing3[pricing.year] = pricing.netPrice + "%";

              // }
            });
          } else {
            for (let count = sy; count <= ey + fu; count++) {
              // objForDataOfConfidentialPricing[count + ""] = pricing.netPrice;
              objForDataOfConfidentialPricing[count + ""] = "$" + 0;
              objForDataOfConfidentialPricing3[count + ""] = 0 + "%";
            }
          }

          console.log("for 2nd dosage", objForDataOfConfidentialPricing);
          dfcnp2.push(objForDataOfConfidentialPricing);
          dfcnp3.push(objForDataOfConfidentialPricing3);
        });
        // });
      }

      console.log("DFNCP", dfcnp);
      setColumnsFOrConfidentialPricing(arrForConfidentialNetPricingCol);
      setColumnsFOrConfidentialPricing2(arrForConfidentialNetPricingCol2);
      setColumnsFOrConfidentialPricing3(arrForConfidentialNetPricingCol3);
      setColumnsFOrConfidentialPricingEdit(arrForConfidentialNetPricingEditCol);
      setColumnsForOverallPricing(arrForOverallPricingCol);
      setColumnsForOVerallPricingEdit(arrForOverallPricingEditCol);
      setDataForOverallPricing(dfop);
      setDataForConfidentialNetPricingData(dfcnp);
      setDataForConfidentialNetPricingData2(dfcnp2);
      setDataForConfidentialNetPricingData3(dfcnp3);
    }
  }, [dummyData]);

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    setIndicationSwitch(!indicationSwitch);
  };

  const onSave = (x: any, tableName: string) => {
    console.log(x);
    let dataAfterSave: any = [...dummyData];
    console.log(x, dataAfterSave, tableName);
    console.log("TableName", tableName);
    if (tableName === "overallPricing") {
      console.log(dataForOverallPricing);
      if (dataForOverallPricing[0].dosage === x[0].dosage) {
        for (let year = startYear; year <= endYear + followUp; year++) {
          dataForOverallPricing[0][year] = "$" + parseInt(x[0][year]);
        }
      }

      if (dataForOverallPricing[1].dosage === x[1].dosage) {
        for (let year = startYear; year <= endYear + followUp; year++) {
          dataForOverallPricing[1][year] = "$" + parseInt(x[1][year]);
        }
      }
    } else if (tableName === "confidentialNetPricing") {
      console.log(dataForConfidentialNetPricingData);
      dataForConfidentialNetPricingData.map((d: any) => {
        for (let year = startYear; year <= endYear + followUp; year++) {
          x.map((editedVal: any) => {
            if (d.indications === editedVal.indications) {
              d[year + ""] = "$" + editedVal[year + ""];
              console.log("values rewritten here...");
            }
          });
        }
      });
    } else if (tableName === "confidentialNetPricing2") {
      console.log(dataForConfidentialNetPricingData2);
      dataForConfidentialNetPricingData2.map((d: any) => {
        for (let year = startYear; year <= endYear + followUp; year++) {
          x.map((editedVal: any) => {
            if (d.indications === editedVal.indications) {
              d[year + ""] = "$" + editedVal[year + ""];
              console.log("values rewritten here...");
            }
          });
        }
      });

      dataForConfidentialNetPricingData3.map((d: any) => {
        for (let year = startYear; year <= endYear + followUp; year++) {
          x.map((editedVal: any) => {
            if (d.indications === editedVal.indications) {
              d[year + ""] = "$" + editedVal[year + ""];
              console.log("values rewritten here...");
            }
          });
        }
      });
    }
  };

  const [form] = Form.useForm();

  const goPrevious = () => {
    context.setCurrent(context.current - 1);
  };

  const onFinish = () => {
    console.log("FINISH!!!! PRESSED");
    let arrForOverallPricing: any = [];
    let arrForOverallPricing2: any = [];

    let arrForConfidentialPricing: any = [];

    // console.log(dummyData);
    let dataForSaving: any = [...dummyData];

    dataForSaving[0].benchmarkPriceModel.benchMarkPrice = benchMarkPrice1;
    dataForSaving[0].benchmarkPriceModel.id = dataForSaving[0].benchmarkPriceModel.id
      ? dataForSaving[0].benchmarkPriceModel.id
      : null;
    dataForSaving[0].benchmarkPriceModel.contract_ID = context.contractId;
    dataForSaving[0].benchmarkPriceModel.dosage_SKUID = dataForSaving[0].dosage_SKUID;

    console.log(dataForOverallPricing, dataForSaving);
    //for overall pricing dosage - 1
    if (dataForSaving[0].overallPricingModel.length > 0) {
      dataForSaving[0].overallPricingModel.map((op: any) => {
        let tempPrice: any = dataForOverallPricing[0][op.year];
        op.grossExManufacturingPrice = 10;
      });
    } else {
      // creating for the first time
      for (let year = startYear; year <= endYear + followUp; year++) {
        let objForPricing1: any = {};
        objForPricing1.id = null;
        objForPricing1.year = year;
        objForPricing1.contract_ID = context.contractId;
        objForPricing1.dosage_SKUID = dataForSaving[0].dosage_SKUID;
        let tempPrice = dataForOverallPricing[0][year];
        objForPricing1.grossExManufacturingPrice = 10;
        arrForOverallPricing.push(objForPricing1);
      }
    }
    dataForSaving[0].overallPricingModel = arrForOverallPricing;

    dataForSaving[0].contractPricingIndicationModel.map((cpi: any, index: any) => {
      arrForConfidentialPricing = [];
      if (cpi.confidentialNetStartingPriceModel.length > 0) {
        cpi.confidentialNetStartingPriceModel.map((cnp: any) => {
          console.log(dataForConfidentialNetPricingData, cnp);

          cnp.netPrice = parseInt(
            dataForConfidentialNetPricingData
              .filter((x: any) => x.indications === cpi.indicationName)[0]
              [cnp.year].replace("$", "")
          );
        });
      } else {
        for (let year = startYear; year <= endYear + followUp; year++) {
          let objForConfidential: any = {};
          objForConfidential.id = null;
          objForConfidential.year = year;
          objForConfidential.contractIndication_ID = cpi.contractIndication_ID;
          objForConfidential.dosage_SKUID = dataForSaving[0].dosage_SKUID;
          objForConfidential.netPrice = parseInt(
            dataForConfidentialNetPricingData
              .filter((x: any) => x.indications === cpi.indicationName)[0]
              [year].replace("$", "")
          );
          arrForConfidentialPricing.push(objForConfidential);
        }
        cpi.confidentialNetStartingPriceModel = [...arrForConfidentialPricing];
      }
    });
    console.log(dataForSaving[0].contractPricingIndicationModel);

    // for Confidential Net Pricing

    // for 2 dosage types :
    if (dataForSaving.length > 1) {
      dataForSaving[1].benchmarkPriceModel.benchMarkPrice = benchMarkPrice2;
      dataForSaving[1].benchmarkPriceModel.id = dataForSaving[1].benchmarkPriceModel.id
        ? dataForSaving[1].benchmarkPriceModel.id
        : null;
      dataForSaving[1].benchmarkPriceModel.contract_ID = context.contractId;
      dataForSaving[1].benchmarkPriceModel.dosage_SKUID = dataForSaving[1].dosage_SKUID;

      //save overall Pricing Model detail
      if (dataForSaving[1].overallPricingModel.length > 0) {
        dataForSaving[1].overallPricingModel.map((op: any) => {
          op.grossExManufacturingPrice = 10;
        });
      } else {
        // creating for the first time
        for (let year = startYear; year <= endYear + followUp; year++) {
          let objForPricing1: any = {};
          objForPricing1.id = null;
          objForPricing1.year = year;
          objForPricing1.contract_ID = context.contractId;
          objForPricing1.dosage_SKUID = dataForSaving[1].dosage_SKUID;
          console.log(dataForOverallPricing[1][year], year);
          objForPricing1.grossExManufacturingPrice = 10;
          arrForOverallPricing2.push(objForPricing1);
        }
      }
      dataForSaving[1].overallPricingModel = arrForOverallPricing2;
    }

    dataForSaving[1].contractPricingIndicationModel.map((cpi: any, index: any) => {
      arrForConfidentialPricing = [];
      if (cpi.confidentialNetStartingPriceModel.length > 0) {
        cpi.confidentialNetStartingPriceModel.map((cnp: any) => {
          // cnp.netPrice = parseInt(dataForConfidentialNetPricingData[index][cnp.year]);

          cnp.netPrice = parseInt(
            dataForConfidentialNetPricingData2
              .filter((x: any) => x.indications === cpi.indicationName)[0]
              [cnp.year].replace("$", "")
          );

          cnp.netPrice = parseInt(
            dataForConfidentialNetPricingData3
              .filter((x: any) => x.indications === cpi.indicationName)[0]
              [cnp.year].replace("$", "")
          );
        });
      } else {
        for (let year = startYear; year <= endYear + followUp; year++) {
          let objForConfidential: any = {};
          objForConfidential.id = null;
          objForConfidential.year = year;
          objForConfidential.contractIndication_ID = cpi.contractIndication_ID;
          objForConfidential.dosage_SKUID = dataForSaving[1].dosage_SKUID;
          objForConfidential.netPrice = parseInt(
            dataForConfidentialNetPricingData2
              .filter((x: any) => x.indications === cpi.indicationName)[0]
              [year].replace("$", "")
          );
          arrForConfidentialPricing.push(objForConfidential);
        }
        cpi.confidentialNetStartingPriceModel = [...arrForConfidentialPricing];
      }
    });

    console.log(dataForSaving);

    // api here
    savePricingDetails(dataForSaving);
    context.setCurrent(context.current + 1);
    // navigate("/contracts");
  };

  const changeInputValue = (e: any, benchmarkPriceFor: any) => {
    // benchmarkPriceModel
    if (benchmarkPriceFor === 1) {
      setBenchMarkPrice1(e.target.value);
    } else {
      setBenchMarkPrice2(e.target.value);
    }
  };

  return (
    <div>
      {dummyData && dataForOverallPricing && (
        <div style={{ backgroundImage: `url(${background})` }}>
          <div className="ml-8 mt-4 mr-8" style={{ paddingBottom: " 1rem" }}>
            <div className=" text-xl font-bold float-left"> Pricing </div>
            <br></br>
            <br></br>

            <div style={{ marginTop: "0rem" }}>
              <div className="text-l float-left mb-8">
                Pricing Information - Specify price related inputs for the contract
              </div>
              <br></br>
              <div className="text-l font-bold float-left mt-6" style={{ marginLeft: "-28.5rem" }}>
                Overall Pricing
              </div>

              <PricingTables
                tableColumns={columnsForOverallPricing}
                tableData={dataForOverallPricing}
                EditTableColumns={columnsForOverallPricingEdit}
                name="overallPricing"
                onSave={onSave}
              />
            </div>

            <br></br>
            <br></br>
            <div className="text-sm font-semibold float-left text-[#75737D]">
              Specify benchmark-price related inputs for the contract
            </div>
            <br></br>
            <br></br>
            <div style={{ float: "left" }}>
              <div>
                <span className="mr-4 text-sm font-normal  text-[#75737D]">
                  {"Benchmark Price per " + dummyData[0].sku_Mg + " " + dummyData[0].productType}
                </span>
                <Input
                  placeholder={
                    "Benchmark Price per " + dummyData[0].sku_Mg + " " + dummyData[0].productType
                  }
                  // prefix={<UserOutlined className="site-form-item-icon" />}
                  defaultValue={dummyData[0].benchmarkPriceModel.benchMarkPrice}
                  onChange={(e: any) => changeInputValue(e, 1)}
                  style={{ width: "10rem" }}
                  prefix={"$"}
                  suffix={
                    <Tooltip title="Extra information">
                      <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                    </Tooltip>
                  }
                />
              </div>
              {dummyData.length > 1 && (
                <div>
                  <span className="mr-4  text-sm font-normal  text-[#75737D]">
                    {"Benchmark Price per " + dummyData[1].sku_Mg + " " + dummyData[0].productType}
                  </span>
                  <Input
                    placeholder={
                      "Benchmark Price per " + dummyData[1].sku_Mg + " " + dummyData[0].productType
                    }
                    // prefix={<UserOutlined className="site-form-item-icon" />}
                    defaultValue={dummyData[1].benchmarkPriceModel.benchMarkPrice}
                    onChange={(e: any) => changeInputValue(e, 2)}
                    style={{ width: "10rem", marginTop: "2rem" }}
                    prefix={"$"}
                    suffix={
                      <Tooltip title="Extra information">
                        <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                      </Tooltip>
                    }
                  />
                </div>
              )}
            </div>
            <br></br>
            <br></br>
            <br></br>

            <div>
              <br></br>
              <br></br>
              <div className="text-l font-bold float-left mt-6">
                Indication Based Pricing{" "}
                <Switch
                  style={{ marginLeft: "1rem" }}
                  defaultChecked={indicationSwitch}
                  onChange={onChange}
                />
              </div>
              <br></br>
              <br></br>
            </div>
            <br></br>
            {indicationSwitch ? (
              <>
                {" "}
                <div className=" text-l font-bold float-left mt-6">
                  Indication Based Pricing ({dummyData[0].sku_Mg})
                </div>
                <PricingTables
                  tableColumns={columnsForConfidentialPricing}
                  EditTableColumns={columnsForConfidentialPricingEdit}
                  tableData={dataForConfidentialNetPricingData}
                  name="confidentialNetPricing"
                  onSave={onSave}
                />
                {dummyData.length > 1 ? (
                  <div>
                    <br></br>
                    <div className=" text-l font-bold float-left mt-6">
                      Indication Based Pricing ({dummyData[1].sku_Mg})
                    </div>
                    <PricingTables
                      tableColumns={columnsForConfidentialPricing2}
                      EditTableColumns={columnsForConfidentialPricingEdit}
                      tableData={dataForConfidentialNetPricingData2}
                      name="confidentialNetPricing2"
                      onSave={onSave}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            <div>
              <br></br>
              <div className=" text-l font-bold float-left mt-6">Mandatory Rebate</div>
              {console.log(dataForConfidentialNetPricingData3)}
              <PricingTables
                tableColumns={columnsForConfidentialPricing3}
                EditTableColumns={columnsForConfidentialPricingEdit}
                tableData={dataForConfidentialNetPricingData3}
                name="confidentialNetPricing2"
                onSave={onSave}
              />
            </div>

            <div className="mt-16 mb-8">
              <Button className=" float-left" onClick={goPrevious}>
                Previous
              </Button>
              <Form form={form} name="pricingTab" onFinish={onFinish}>
                <Form.Item>
                  <Button
                    type="primary"
                    style={{ width: "5rem" }}
                    htmlType="submit"
                    className="-ml-28"
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
