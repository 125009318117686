import React from "react";
import { Button, Checkbox, Col, Collapse, Divider, Form, Row, Tooltip, TreeSelect } from "antd";
import ContractSetup from "./ContractSetup";
import IndicationSelection from "./IndicationSelection";
import { InfoCircle } from "@styled-icons/bootstrap/InfoCircle";

import AppContext from "../../../store";
import {
  getDetailsForContract,
  getDetailsForContractOnEdit,
  getNewContractDetails,
  saveContractDetails,
} from "./service-helper";
import AppContext2 from "../../../store/index2";
import { useLocation } from "react-router";
import FormItem from "antd/lib/form/FormItem";
import background from "../../../globalTheme/background2.png";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const CheckboxGroup = Checkbox.Group;

export default function ContractSetupPage() {
  const context = React.useContext(AppContext);
  const context2 = React.useContext(AppContext2);
  let loc: any = useLocation();

  const [contractData, setContractData] = React.useState<any>();
  const [contractId, setContractId] = React.useState<any>();

  const [temp, setTemp] = React.useState<any>();
  const [start, setStart] = React.useState<any>();
  const [end, setEnd] = React.useState<any>();
  const [newContract, setNewContract] = React.useState<any>();
  const [isVialShared, setIsVialShared] = React.useState<boolean>();

  const FetchData = async () => {
    let productId = loc.state.productId;

    if (loc.state.contractId) {
      // if edit then contractId is present already
      const dataOfContract: any = await getDetailsForContractOnEdit(loc.state.contractId);
      if (dataOfContract.isKMcurve === false) {
        context.setStep3(false);
      } else {
        context.setStep3(true);
      }
      context.setContractId(loc.state.contractId);
      console.log(dataOfContract);
      setTemp({ ...dataOfContract });
      setContractData(dataOfContract);
      setNewContract(false);
      let sd = dataOfContract.startDate + "";
      setStart(sd.substring(0, 10));
      sd = dataOfContract.endDate + "";
      setEnd(sd.substring(0, 10));
    } else {
      const dataOfContract: any = await getDetailsForContract(productId);
      if (dataOfContract.isKMcurve === false) {
        context.setStep3(false);
      } else {
        context.setStep3(true);
      }
      console.log(dataOfContract);
      setTemp({ ...dataOfContract });
      setNewContract(true);
      setContractData(dataOfContract);
      context.setContractId(null);
    }
  };

  React.useEffect(() => {
    console.log("Contract Setup Page API Called!");
    FetchData();
  }, []);

  React.useEffect(() => {}, [context2.selectedIndication]);

  const onFinish = (values: any) => {
    let finalValues: any = { ...temp };
    finalValues.country = loc.state.country;
    finalValues.country_ID = loc.state.country_ID;
    finalValues.name = values.name;
    finalValues.inputCurrency = values.inputCurrency;
    finalValues.followUpMonths = values.followUpMonths;
    finalValues.isVialSharingConsidered = values.isVialSharingConsidered;
    finalValues.wastageInLastVial = values.wastageInLastVial;

    let indIdArray: any = [];

    console.log(selectedInd);
    selectedInd.map((element: any) => {
      let contractIndicationId: any;

      console.log(element, temp.selectedIndication);
      if (temp.selectedIndication) {
        if (
          temp.selectedIndication.filter(
            (selectedInd: any) => selectedInd.indication_ID === element
          ).length > 0
        ) {
          contractIndicationId = temp.selectedIndication.filter(
            (selectedInd: any) => selectedInd.indication_ID === element
          )[0].contractIndicationID;
        }
      }

      temp.indication
        .filter((ind: any) => ind.indication_ID === element)
        .map((i: any) => {
          console.log(i);
          let tempObj: any = {};
          tempObj.contractIndicationID = contractIndicationId ? contractIndicationId : null;
          tempObj.indication_Name = indiNames.filter(
            (name: any) => name.value === element
          )[0].label;
          tempObj.indication_ID = i.indication_ID;
          let arrForSelectedDosage: any = [];
          i.dose.map((dose: any) => {
            let dosageObjForTempObj: any = {};
            dosageObjForTempObj.id = null;
            // add calculated value here
            dosageObjForTempObj.noOfUnitsPerDose_Calculated = 3;
            dosageObjForTempObj.contractIndicationID = i.contractIndicationID
              ? i.contractIndicationID
              : null;
            dosageObjForTempObj.dosageID = dose.id;
            arrForSelectedDosage.push(dosageObjForTempObj);
          });
          tempObj.selectedDosageModel = [...arrForSelectedDosage];
          console.log(tempObj);
          indIdArray.push(tempObj);
        });
    });

    finalValues.selectedIndication = [...indIdArray];
    finalValues.startDate =
      values.validity[0].year() +
      "-" +
      ("0" + values.validity[0].month()).slice(-2) +
      "-" +
      "01" +
      "T12:02:16.427Z";

    finalValues.endDate =
      values.validity[1].year() +
      "-" +
      ("0" + values.validity[1].month()).slice(-2) +
      "-" +
      "01" +
      "T12:02:16.427Z";

    console.log(finalValues);
    // creating new contract
    saveContractDetails(finalValues).then((val: any) => {
      console.log("CONTRACT ID", val);
      setContractId(val);
    });
  };

  React.useEffect(() => {
    if (contractId) {
      console.log(contractId);
      context.setContractId(contractId);
      context.setCurrent(context.current + 1);

      if (context.current === 1) {
        context.setSubmit1(true);
      }
    }
  }, [contractId]);

  const [form] = Form.useForm();

  let arrForSelectedInd: any = [];

  // console.log(temp);

  const [checkedList, setCheckedList] = React.useState<any>();
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);

  // let indiNames: any = [];

  const [indiNames, setIndiNames] = React.useState<any>();
  const [selectedInd, setSelectedInd] = React.useState<any>();

  const onChangeVial = (value: any) => {
    console.log("Value of vial :", value);
  };

  React.useEffect(() => {
    // console.log(temp);
    if (temp !== undefined) {
      let arrForSelectedIndicationNames: any = [];
      let arrForIndiNames: any = [];
      temp.indication.map((ind: any) => {
        let objForOptionsOfInd: any = {};
        objForOptionsOfInd.label = ind.indication_Name;
        objForOptionsOfInd.value = ind.indication_ID;

        arrForSelectedInd.push(ind.indication_ID);

        arrForIndiNames.push(objForOptionsOfInd);
      });

      setIndiNames(arrForIndiNames);

      if (temp !== undefined && temp.selectedIndication !== null) {
        temp.selectedIndication.map((ind: any) => {
          arrForSelectedIndicationNames.push(ind.indication_ID);
        });
      }

      setSelectedInd(arrForSelectedIndicationNames);
      setCheckedList(arrForSelectedIndicationNames);
    }
  }, [temp]);

  React.useEffect(() => {}, [checkedList]);

  // const onChange = (list: any) => {
  //   console.log(list);
  //   setSelectedInd(list);

  //   setCheckedList([...list]);
  // };

  const { SHOW_PARENT } = TreeSelect;

  // const treeData =
  let treeData: any = [];
  if (indiNames) {
    indiNames.map((i: any) => {
      let objTemp: any = {};
      objTemp.title = i.label;
      objTemp.value = i.value;
      objTemp.key = i.value;
      treeData.push(objTemp);
    });
  }

  // const treeData = [
  //   {
  //     title: "ind1",
  //     value: "ind1",
  //     key: "0-0",
  //   },
  //   {
  //     title: "ind2",
  //     value: "ind2",
  //     key: "0-1",
  //   },
  // ];

  const [value, setValue] = React.useState<any>([]);
  const onChange = (newValue: any) => {
    console.log("onChange ", newValue);
    setValue(newValue);
    setSelectedInd(newValue);

    setCheckedList([...newValue]);
  };

  return (
    <div
      style={{
        height: isVialShared ? "47rem" : "42rem",
        minHeight: "42rem",
        backgroundImage: `url(${background})`,
      }}
    >
      {temp && (
        <Form
          layout="vertical"
          {...layout}
          form={form}
          name="contractSetup"
          onFinish={onFinish}
          initialValues={temp}
        >
          <div>
            {
              <ContractSetup
                start={start}
                end={end}
                contractData={temp}
                isNewContract={newContract}
                isVialShared={isVialShared}
                setIsVialShared={setIsVialShared}
              />
            }
          </div>
          <Divider />

          {console.log("ContractId used", temp)}
          <div>
            <div className="ml-8 mt-12 mr-8 h-auto">
              <div className=" text-xl font-bold float-left">
                Indication Selection
                <Tooltip title="Lorem Ipsum text">
                  <InfoCircle
                    className="mr-4"
                    style={{ marginLeft: "0.5rem", marginTop: "-1em" }}
                    size={14}
                  />
                </Tooltip>
              </div>
              <br></br>
              <br></br>
              <div className="text-sm font-normal float-left">
                <Row>
                  <Col className="-ml-8" span={16}>
                    Select Indications for the Contract :
                  </Col>
                  <Col span={8}>
                    <FormItem name="checkboxes">
                      {console.log(indiNames)}
                      <TreeSelect
                        showCheckedStrategy="SHOW_PARENT"
                        placeholder="Please select"
                        style={{
                          width: "100%",
                          marginTop: "-1rem",
                        }}
                        treeCheckable={true}
                        onChange={onChange}
                        // value={value}
                        treeData={treeData}
                        value={loc.state.contractId && treeData}
                      />
                    </FormItem>
                  </Col>
                  {/* <Col span={12}></Col> */}
                </Row>
              </div>
              <br></br>
              <br></br>
              <br></br>
              {/* <Divider /> */}
              {/* 
              <FormItem name="checkboxes">
                {console.log(indiNames)}
                <CheckboxGroup
                  style={{ width: "17rem", marginLeft: "-2rem" }}
                  options={indiNames}
                  value={checkedList}
                  // defaultValue={["ind1"]}
                  onChange={onChange}
                />
              </FormItem>
              <br></br> */}
              <br></br>
            </div>
          </div>

          <Form.Item style={{ marginLeft: "-3.5rem", marginTop: "0rem" }}>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
