import React from "react";
import { ResponsiveLine } from "@nivo/line";
const data = [
  {
    id: "KM2 - GROSS REVENUE",
    color: "hsl(63, 70%, 50%)",
    data: [
      {
        x: "2022",
        y: 226,
      },
      {
        x: "2023",
        y: 118,
      },
      {
        x: "2024",
        y: 146,
      },
      {
        x: "2025",
        y: 137,
      },
      {
        x: "2026",
        y: 225,
      },
      {
        x: "2027",
        y: 70,
      },
      {
        x: "2028",
        y: 108,
      },
      {
        x: "2029",
        y: 84,
      },
      {
        x: "2030",
        y: 195,
      },
      {
        x: "2031",
        y: 192,
      },
      {
        x: "2032",
        y: 211,
      },
      {
        x: "2033",
        y: 123,
      },
    ],
  },
  {
    id: "KM1- GROSS REVENUE",
    color: "hsl(127, 70%, 50%)",
    data: [
      {
        x: "2022",
        y: 183,
      },
      {
        x: "2023",
        y: 296,
      },
      {
        x: "2024",
        y: 186,
      },
      {
        x: "2025",
        y: 262,
      },
      {
        x: "2026",
        y: 179,
      },
      {
        x: "2027",
        y: 2,
      },
      {
        x: "2028",
        y: 38,
      },
      {
        x: "2029",
        y: 133,
      },
      {
        x: "2030",
        y: 177,
      },
      {
        x: "2031",
        y: 284,
      },
      {
        x: "2032",
        y: 256,
      },
      {
        x: "2033",
        y: 290,
      },
    ],
  },
  {
    id: "KM2 - NET REVENUE",
    color: "hsl(195, 70%, 50%)",
    data: [
      {
        x: "2022",
        y: 9,
      },
      {
        x: "2023",
        y: 281,
      },
      {
        x: "2024",
        y: 47,
      },
      {
        x: "2025",
        y: 235,
      },
      {
        x: "2026",
        y: 106,
      },
      {
        x: "2027",
        y: 156,
      },
      {
        x: "2028",
        y: 273,
      },
      {
        x: "2029",
        y: 72,
      },
      {
        x: "2030",
        y: 11,
      },
      {
        x: "2031",
        y: 52,
      },
      {
        x: "2032",
        y: 133,
      },
      {
        x: "2033",
        y: 184,
      },
    ],
  },
  {
    id: "KM1 - NET REVENUE",
    color: "hsl(189, 70%, 50%)",
    data: [
      {
        x: "2022",
        y: 283,
      },
      {
        x: "2023",
        y: 39,
      },
      {
        x: "2024",
        y: 117,
      },
      {
        x: "2025",
        y: 53,
      },
      {
        x: "2026",
        y: 53,
      },
      {
        x: "2027",
        y: 212,
      },
      {
        x: "2028",
        y: 234,
      },
      {
        x: "2029",
        y: 209,
      },
      {
        x: "2030",
        y: 55,
      },
      {
        x: "2031",
        y: 194,
      },
      {
        x: "2032",
        y: 29,
      },
      {
        x: "2033",
        y: 230,
      },
    ],
  },
];

export default function ContractRevenueChart(props: { data?: any; legend?: any; anch?: any }) {
  return (
    <div style={{ height: 420, maxWidth: "98%" }}>
      {console.log(props.data)}

      <ResponsiveLine
        data={props.data}
        // data={data}
        margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        curve="cardinal"
        axisBottom={{
          // orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Year",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          // orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.legend,
          legendOffset: -40,
          legendPosition: "middle",
        }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            itemHeight: 20,
            itemWidth: 110,
            toggleSerie: true,
            translateX: 120,
            translateY: 6,
          },
        ]}
        // legends={[
        //   {
        //     anchor: "bottom",
        //     direction: "column",
        //     justify: false,
        //     translateX: props.anch ? 550 : 265,
        //     translateY: 20,
        //     itemsSpacing: 0,
        //     itemDirection: "left-to-right",
        //     itemWidth: 80,
        //     itemHeight: 20,
        //     itemOpacity: 0.75,
        //     symbolSize: 12,
        //     symbolShape: "circle",
        //     symbolBorderColor: "rgba(0, 0, 0, .5)",
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemBackground: "rgba(0, 0, 0, .03)",
        //           itemOpacity: 1,
        //         },
        //       },
        //     ],
        //   },
        // ]}
      />
    </div>
  );
}
