import React from "react";
import StepsLayout from "../../layout/body-layout/steps";
import AppContext from "../../store";
import BrandProductSetup from "./BrandProductSetup/index";
import ConstructList from "./Constructs/ConstructList";
import KMCurveData from "./KMCurveData";

export default function SimulatorConfiguration() {
  const subTabs = [
    {
      title: "Products",
      content: <BrandProductSetup />,
    },
    {
      title: "Constructs",
      content: <ConstructList />,
    },
    {
      title: "Subpopulations",
      content: <KMCurveData />,
    },
  ];

  return (
    <div>
      <StepsLayout subTabs={subTabs} />
    </div>
  );
}
