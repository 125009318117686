import { CopyContractDetailsData, deleteContractDetailsData, getAllContractsListData } from "../../services/ContractInput";
import { checkLoginData } from "../../services/LoginAuth";
import {
  CopyBrandDetailsData,
  deleteBrandDetailsData,
} from "../../services/SimulatorConfiguration";

export const getAllContractsList: () => Promise<unknown> = async () => {
  const data = await getAllContractsListData();

  if (data === null || data === undefined) return null;

  return data;
};

export const deleteBrandDetails: (productId: any) => Promise<unknown> = async (productId: any) => {
  const data = await deleteBrandDetailsData(productId);

  if (data === null || data === undefined) return null;

  return data;
};

export const CopyBrandDetails: (productId: any, productName: any) => Promise<unknown> = async (
  productId: any,
  productName: any
) => {
  const data = await CopyBrandDetailsData(productId, productName);

  if (data === null || data === undefined) return null;

  return data;
};

export const CopyContractDetails: (contractId: any, contractName: any) => Promise<unknown> = async (
  contractId: any,
  contractName: any
) => {
  const data = await CopyContractDetailsData(contractId, contractName);

  if (data === null || data === undefined) return null;

  return data;
};

export const deleteContractDetails: (contractId: any) => Promise<unknown> = async (
  contractId: any
) => {
  const data = await deleteContractDetailsData(contractId);

  if (data === null || data === undefined) return null;

  return data;
};

export const checkLogin: (credentials: any) => Promise<unknown> = async (credentials: any) => {
  const data = await checkLoginData(credentials);

  if (data === null || data === undefined) return null;

  return data;
};
