// import { Col, Row, Card, Statistic } from "antd";
import React from "react";
import { DollarCircle } from "@styled-icons/boxicons-regular/DollarCircle";
import { ArrowDown } from "@styled-icons/bootstrap/ArrowDown";
import { ChartBar } from "@styled-icons/fa-regular/ChartBar";
import { FactCheck } from "@styled-icons/material/FactCheck";
import background from "../../globalTheme/background2.png";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Row,
  Select,
  Statistic,
  Tag,
  Tree,
} from "antd";
import ContractRevenueChart from "../ContractPerformance/contractRevenueChart";

const { Option } = Select;
const { Panel } = Collapse;

export default function ContractComparison() {
  let startYear: number = 2022;
  let endYear: number = 2027;
  let arrForYears: any = [];
  for (let year = startYear; year <= endYear; year++) {
    arrForYears.push(year);
  }
  return (
    <div className="pl-4 pr-12 pt-3">
      <div
        style={{
          overflowY: "auto",
          height: "auto",
          width: "104%",
          backgroundImage: `url(${background})`,
          minHeight: "44rem",
        }}
      >
        <div
          style={{
            paddingRight: "1rem",
            marginTop: "-1rem",
            // minHeight: "60rem",
          }}
        >
          <div className="text-xl font-bold float-left mt-8 ml-8">
            Contract Comparisons
            <div className="text-sm font-normal text-[#75737D] mt-2">
              {/* Lorem ipsum dolor sit ametLorem ipsum dolor sit amet */}
            </div>
          </div>
          <br></br>
          <div className="mt-24 ml-4">
            <div className="site-card-wrapper">
              <Row gutter={16}>
                <Col span={12}>
                  <Card style={{ height: "12rem" }} bordered={false}>
                    <div
                      className="border-2 border-sky-500 h-10 bg-slate-300  ml-8 mr-8"
                      style={{ background: "#F4F3F3", borderColor: "#F4F3F3" }}
                    >
                      <div className="mt-2 font-bold mt-12 mb-4 flex justify-left text-sm pl-4">
                        Contract 1
                      </div>
                      <Row>
                        <Col span={24}>
                          <span style={{ color: "#8C8C8C", paddingLeft: "1rem" }}>
                            Contract Name :
                          </span>{" "}
                          <span className="font-semibold">Syrildiaphene H2 2022 Italy </span>
                        </Col>
                        <Col span={24}>
                          <span style={{ color: "#8C8C8C", paddingLeft: "1rem" }}>
                            Contract Period :{" "}
                          </span>{" "}
                          <span className="font-semibold">Dec 2024-2027</span>
                        </Col>
                        <Col span={24}>
                          <span style={{ color: "#8C8C8C", paddingLeft: "1rem" }}>
                            Contract Outcome Monitoring Period :
                          </span>{" "}
                          <span className="font-semibold"> 36 Months</span>
                        </Col>
                        <Col span={24}>
                          <span style={{ color: "#8C8C8C", paddingLeft: "1rem" }}>
                            Contstructs Used :
                          </span>
                          <span className="font-semibold">
                            {" "}
                            Free Initiation, Duration Based Pricing
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{ height: "12rem" }} bordered={false}>
                    <div
                      className="border-2 border-sky-500 h-10 bg-slate-300 ml-8 mr-8"
                      style={{ background: "#F4F3F3", borderColor: "#F4F3F3" }}
                    >
                      <div className="mt-2 font-bold mt-12 mb-4 flex justify-left text-sm pl-4">
                        Contract 2
                      </div>
                      <Row>
                        <Col span={24}>
                          <span style={{ color: "#8C8C8C", paddingLeft: "1rem" }}>
                            Contract Name :
                          </span>{" "}
                          <span className="font-semibold">Azpelumab H1 2022 Italy</span>
                        </Col>
                        <Col span={24}>
                          <span style={{ color: "#8C8C8C", paddingLeft: "1rem" }}>
                            Contract Period :{" "}
                          </span>{" "}
                          <span className="font-semibold">Dec 2024-2027</span>
                        </Col>
                        <Col span={24}>
                          <span style={{ color: "#8C8C8C", paddingLeft: "1rem" }}>
                            Contract Outcome Monitoring Period :
                          </span>{" "}
                          <span className="font-semibold"> 27 Months</span>
                        </Col>
                        <Col span={24}>
                          <span style={{ color: "#8C8C8C", paddingLeft: "1rem" }}>
                            Contstructs Used :
                          </span>
                          <span className="font-semibold"> Value Warranty, Cost Capitation</span>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
                {/* <Col span={8}>
                  <Card bordered={false}>Card content</Card>
                </Col> */}
              </Row>
            </div>
          </div>

          <div className="site-card-wrapper mt-16 ml-8 mr-8 mt-8">
            <Row gutter={16}>
              <Col span={6}>
                <Card bordered={true}>
                  <p
                    style={{ background: "#27a6a4", color: "rgb(248 250 252)" }}
                    className="flex justify-center"
                  >
                    Total Gross Revenue
                  </p>
                  <Row>
                    <Col span={12}>
                      <Statistic
                        title={<p className="flex justify-center">Contract 1</p>}
                        value={27.75 + "M"}
                        precision={2}
                        valueStyle={{ color: "gray", display: "flex", justifyContent: "center" }}
                        prefix={<p>$</p>}
                        suffix=""
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title={<p className="flex justify-center">Contract 2</p>}
                        value={18.35 + "M"}
                        precision={2}
                        valueStyle={{ color: "gray", display: "flex", justifyContent: "center" }}
                        prefix={<p>$</p>}
                        suffix=""
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={6}>
                <Card bordered={true}>
                  <p
                    style={{ background: "#27a6a4", color: "rgb(248 250 252)" }}
                    className="flex justify-center"
                  >
                    Total Deductions
                  </p>
                  <Row>
                    <Col span={12}>
                      <Statistic
                        title={<p className="flex justify-center">Contract 1</p>}
                        value={135 + "K"}
                        precision={2}
                        valueStyle={{ color: "gray", display: "flex", justifyContent: "center" }}
                        prefix={<p>$</p>}
                        suffix=""
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title={<p className="flex justify-center">Contract 2</p>}
                        value={145 + "K"}
                        precision={2}
                        valueStyle={{ color: "gray", display: "flex", justifyContent: "center" }}
                        prefix={<p>$</p>}
                        suffix=""
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={6}>
                <Card bordered={true}>
                  <p
                    style={{ background: "#27a6a4", color: "rgb(248 250 252)" }}
                    className="flex justify-center"
                  >
                    Total Net Revenue
                  </p>
                  <Row>
                    <Col span={12}>
                      <Statistic
                        title={<p className="flex justify-center">Contract 1</p>}
                        value={22.25 + "M"}
                        precision={2}
                        valueStyle={{ color: "gray", display: "flex", justifyContent: "center" }}
                        prefix={<p>$</p>}
                        suffix=""
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title={<p className="flex justify-center">Contract 2</p>}
                        value={12.95 + "M"}
                        precision={2}
                        valueStyle={{ color: "gray", display: "flex", justifyContent: "center" }}
                        prefix={<p>$</p>}
                        suffix=""
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={6}>
                <Card bordered={true}>
                  <p
                    style={{ background: "#27a6a4", color: "rgb(248 250 252)" }}
                    className="flex justify-center"
                  >
                    Average Net Price for Contract
                  </p>
                  <Row>
                    <Col span={12}>
                      <Statistic
                        title={<p className="flex justify-center">Contract 1</p>}
                        value={1300}
                        precision={0}
                        valueStyle={{ color: "gray", display: "flex", justifyContent: "center" }}
                        prefix={<p>$</p>}
                        suffix=""
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title={<p className="flex justify-center">Contract 2</p>}
                        value={2700}
                        precision={0}
                        valueStyle={{ color: "gray", display: "flex", justifyContent: "center" }}
                        prefix={<p>$</p>}
                        suffix=""
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="text-xl font-bold float-left mt-8 ml-8">
            Detailed Contract Performance Outputs
          </div>

          <div
            className="border-2 border-sky-500 h-10 bg-slate-300 mt-24 ml-8 mr-8"
            style={{ background: "#F4F3F3", borderColor: "#F4F3F3" }}
          >
            <div className="mt-2 mb-4 font-bold mt-24 flex justify-center text-sm	">
              Contract Level Outputs
            </div>
            <Row>
              <Col span={24}>
                <div style={{ height: 500, background: "white" }}>
                  <p className="-mb-4 pt-12 font-bold" style={{ marginLeft: "35rem" }}>
                    Revenue
                  </p>
                  <ContractRevenueChart
                    anch={true}
                    legend="Revenue [in thousand]"
                    data={[
                      {
                        id: "Gross Revenue 2",
                        color: "hsl(120, 70%, 50%)",
                        data: [
                          {
                            x: "2022",
                            y: 1500,
                          },
                          {
                            x: "2023",
                            y: 1900,
                          },
                          {
                            x: "2024",
                            y: 2550,
                          },
                          {
                            x: "2025",
                            y: 3000,
                          },
                          {
                            x: "2026",
                            y: 4250,
                          },
                          {
                            x: "2027",
                            y: 5500,
                          },
                        ],
                      },
                      {
                        id: "Gross Revenue 1",
                        color: "hsl(120, 70%, 50%)",
                        data: [
                          {
                            x: "2022",
                            y: 1000,
                          },
                          {
                            x: "2023",
                            y: 1240,
                          },
                          {
                            x: "2024",
                            y: 2250,
                          },
                          {
                            x: "2025",
                            y: 3200,
                          },
                          {
                            x: "2026",
                            y: 3750,
                          },
                          {
                            x: "2027",
                            y: 4500,
                          },
                        ],
                      },
                      {
                        id: "Net Revenue 2",
                        color: "hsl(189, 70%, 50%)",
                        data: [
                          {
                            x: "2022",
                            y: 670,
                          },
                          {
                            x: "2023",
                            y: 990,
                          },
                          {
                            x: "2024",
                            y: 2000,
                          },
                          {
                            x: "2025",
                            y: 2450,
                          },
                          {
                            x: "2026",
                            y: 4500,
                          },
                          {
                            x: "2027",
                            y: 4520,
                          },
                        ],
                      },
                      {
                        id: "Net Revenue 1",
                        color: "hsl(189, 70%, 50%)",
                        data: [
                          {
                            x: "2022",
                            y: 970,
                          },
                          {
                            x: "2023",
                            y: 850,
                          },
                          {
                            x: "2024",
                            y: 1970,
                          },
                          {
                            x: "2025",
                            y: 2850,
                          },
                          {
                            x: "2026",
                            y: 3500,
                          },
                          {
                            x: "2027",
                            y: 4320,
                          },
                        ],
                      },
                    ]}
                  />
                </div>
              </Col>
            </Row>
            <div style={{ height: 500, background: "white" }}>
              <Row>
                <Col span={12}>
                  <p className="mt-8 ml-56 font-bold">Pricing</p>

                  <div style={{ height: 500 }}>
                    <ContractRevenueChart
                      anch={false}
                      legend="Price"
                      data={[
                        {
                          id: "Net Price",
                          color: "hsl(63, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 800,
                            },
                            {
                              x: "2023",
                              y: 1200,
                            },
                            {
                              x: "2024",
                              y: 1800,
                            },
                            {
                              x: "2025",
                              y: 2200,
                            },
                            {
                              x: "2026",
                              y: 2900,
                            },
                            {
                              x: "2027",
                              y: 3100,
                            },
                          ],
                        },
                        {
                          id: "Ind. Price",
                          color: "hsl(189, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 800,
                            },
                            {
                              x: "2023",
                              y: 860,
                            },
                            {
                              x: "2024",
                              y: 1200,
                            },
                            {
                              x: "2025",
                              y: 1300,
                            },
                            {
                              x: "2026",
                              y: 2000,
                            },
                            {
                              x: "2027",
                              y: 2500,
                            },
                          ],
                        },
                        {
                          id: "Benchmark Price",
                          color: "hsl(195, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 600,
                            },
                            {
                              x: "2023",
                              y: 800,
                            },
                            {
                              x: "2024",
                              y: 1200,
                            },
                            {
                              x: "2025",
                              y: 1400,
                            },
                            {
                              x: "2026",
                              y: 1500,
                            },
                            {
                              x: "2027",
                              y: 2000,
                            },
                          ],
                        },
                        {
                          id: "Gross Price",
                          color: "hsl(127, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 1000,
                            },
                            {
                              x: "2023",
                              y: 1500,
                            },
                            {
                              x: "2024",
                              y: 2000,
                            },
                            {
                              x: "2025",
                              y: 2500,
                            },
                            {
                              x: "2026",
                              y: 3000,
                            },
                            {
                              x: "2027",
                              y: 3500,
                            },
                          ],
                        },
                      ]}
                    />
                  </div>
                </Col>
                {/* <Col span={2}></Col> */}

                <Col span={12}>
                  <p className=" mt-8 ml-56 font-bold">Patient Numbers</p>
                  <div style={{ height: 500 }}>
                    <ContractRevenueChart
                      anch={false}
                      legend="Patient Numbers"
                      data={[
                        {
                          id: "mNSCLC ",
                          color: "hsl(195, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 100,
                            },
                            {
                              x: "2023",
                              y: 120,
                            },
                            {
                              x: "2024",
                              y: 140,
                            },
                            {
                              x: "2025",
                              y: 150,
                            },
                            {
                              x: "2026",
                              y: 170,
                            },
                            {
                              x: "2027",
                              y: 250,
                            },
                          ],
                        },
                        {
                          id: "mBC ",
                          color: "hsl(63, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 100,
                            },
                            {
                              x: "2023",
                              y: 120,
                            },
                            {
                              x: "2024",
                              y: 146,
                            },
                            {
                              x: "2025",
                              y: 150,
                            },
                            {
                              x: "2026",
                              y: 225,
                            },
                            {
                              x: "2027",
                              y: 420,
                            },
                          ],
                        },
                        {
                          id: "mGC",
                          color: "hsl(127, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 120,
                            },
                            {
                              x: "2023",
                              y: 140,
                            },
                            {
                              x: "2024",
                              y: 200,
                            },
                            {
                              x: "2025",
                              y: 230,
                            },
                            {
                              x: "2026",
                              y: 240,
                            },
                            {
                              x: "2027",
                              y: 280,
                            },
                          ],
                        },
                        {
                          id: "Bronchial Asthma ",
                          color: "hsl(63, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 184,
                            },
                            {
                              x: "2023",
                              y: 192,
                            },
                            {
                              x: "2024",
                              y: 222,
                            },
                            {
                              x: "2025",
                              y: 250,
                            },
                            {
                              x: "2026",
                              y: 254,
                            },
                            {
                              x: "2027",
                              y: 300,
                            },
                          ],
                        },
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div style={{ height: "52rem" }}>
              <div className="mb-2 mt-4">Time Period</div>
              <Select
                style={{ width: "12rem" }}
                showSearch
                placeholder="Select Time Period"
                optionFilterProp="children"
              >
                <Option value="yearly"> Yearly</Option>
                <Option value="monthly"> Monthly</Option>
              </Select>

              <Row className="mt-8">
                <Col span={3}>
                  <p className="float-left font-semibold text-xs"> Metric </p>
                </Col>
                {/* <Col span={3}></Col> */}
                {/* <Col span={4}>
                    <p className="float-left font-semibold text-xs ">KM Curve </p>
                  </Col> */}
                {/* <Col className=" flex justify-items-stretch" span={18 / arrForYears.length}> */}
                {arrForYears.map((el: any) => (
                  <span className="text-xs font-semibold" style={{ marginLeft: "9rem" }}>
                    {el}{" "}
                  </span>
                ))}
                {/* </Col> */}
              </Row>

              <Divider style={{ backgroundColor: "#9C9AA1" }} className="mt-0" />
              <Row className="mb-4">
                <Col className="font-bold" span={3}>
                  Gross Price{" "}
                </Col>
                <Col span={21}></Col>

                <Col span={3}>
                  <Tag color="#27a6a4">Syrildiaphene H2 2022 Italy</Tag>{" "}
                </Col>

                <Col span={21}>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "8.5rem", marginRight: "0rem" }}
                  >
                    {"$1,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.8rem" }}
                  >
                    {"$1,500"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.5rem" }}
                  >
                    {"$2,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.5rem" }}
                  >
                    {"$2,500"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.2rem", marginRight: "0.5rem" }}
                  >
                    {"$3,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.6rem", marginRight: "0.5rem" }}
                  >
                    {"$3,500"}
                  </span>
                </Col>

                <Col span={3}>
                  <Tag color="#27a6a4">Azpelumab H1 2022 Italy</Tag>
                </Col>
                <Col span={21}>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "8.5rem", marginRight: "0rem" }}
                  >
                    {"$1,300"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.8rem" }}
                  >
                    {"$1,700"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.5rem" }}
                  >
                    {"$2,300"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.5rem" }}
                  >
                    {"$2,900"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.2rem", marginRight: "0.5rem" }}
                  >
                    {"$3,250"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.6rem", marginRight: "0.5rem" }}
                  >
                    {"$3,900"}
                  </span>
                </Col>
              </Row>
              <Divider className="mt-0" />

              <Row className="mb-4">
                <Col className="font-bold" span={3}>
                  {" "}
                  Gross Revenue
                </Col>
                <Col span={21}></Col>
                <Col span={3}>
                  {" "}
                  <Tag color="#27a6a4">Syrildiaphene H2 2022 Italy</Tag>{" "}
                </Col>
                <Col span={21}>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "6.5rem", marginRight: "0rem" }}
                  >
                    {"$1,000,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "6.5rem", marginRight: "0.8rem" }}
                  >
                    {"$1,240,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.5rem", marginRight: "0.5rem" }}
                  >
                    {"$2,250,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.5rem", marginRight: "0.5rem" }}
                  >
                    {"$3,200,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.8rem", marginRight: "0.5rem" }}
                  >
                    {"$3,750,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.9rem", marginRight: "0.5rem" }}
                  >
                    {"$4,500,000"}
                  </span>
                </Col>
                <Col span={3}>
                  {" "}
                  <Tag color="#27a6a4">Azpelumab H1 2022 Italy</Tag>{" "}
                </Col>
                <Col span={21}>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "6.5rem", marginRight: "0rem" }}
                  >
                    {"$1,500,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "6.5rem", marginRight: "0.8rem" }}
                  >
                    {"$1,900,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.5rem", marginRight: "0.5rem" }}
                  >
                    {"$2,550,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.5rem", marginRight: "0.5rem" }}
                  >
                    {"$3,000,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.8rem", marginRight: "0.5rem" }}
                  >
                    {"$4,250,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.9rem", marginRight: "0.5rem" }}
                  >
                    {"$4,950,000"}
                  </span>
                </Col>
              </Row>

              <Divider className="mt-0" />
              <Row className="mb-4">
                <Col className={"font-bold"} span={3}>
                  Mandatory Rebate
                </Col>
                <Col span={21}></Col>
                <Col span={3}>
                  {" "}
                  <Tag color="#27a6a4">Syrildiaphene H2 2022 Italy</Tag>{" "}
                </Col>
                <Col span={21}>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0rem" }}
                  >
                    {"$10,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.6rem", marginRight: "0.8rem" }}
                  >
                    {"$15,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "6.7rem", marginRight: "0.5rem" }}
                  >
                    {"$20,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                  >
                    {"$25,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                  >
                    {"$30,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                  >
                    {"$35,000"}
                  </span>
                </Col>

                <Col span={3}>
                  {" "}
                  <Tag color="#27a6a4">Azpelumab H1 2022 Italy</Tag>{" "}
                </Col>
                <Col span={21}>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0rem" }}
                  >
                    {"$15,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.6rem", marginRight: "0.8rem" }}
                  >
                    {"$19,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "6.7rem", marginRight: "0.5rem" }}
                  >
                    {"$25,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                  >
                    {"$29,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                  >
                    {"$45,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                  >
                    {"$55,000"}
                  </span>
                </Col>
              </Row>
              <Divider className="mt-0" />
              <Row className="mb-4">
                <Col className="font-bold" span={4}>
                  Construct Based Rebate
                </Col>
                <Col span={20}></Col>
                <Col span={4}>
                  {" "}
                  <Tag color="#27a6a4">Syrildiaphene H2 2022 Italy</Tag>{" "}
                </Col>
                <Col span={20}>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "4.3rem", marginRight: "0rem" }}
                  >
                    {"$20,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.6rem", marginRight: "0.8rem" }}
                  >
                    {"$35,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "6.7rem", marginRight: "0.5rem" }}
                  >
                    {"$10,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                  >
                    {"$15,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                  >
                    {"$10,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                  >
                    {"$15,000"}
                  </span>
                </Col>
                <Col span={4}>
                  {" "}
                  <Tag color="#27a6a4">Azpelumab H1 2022 Italy</Tag>{" "}
                </Col>
                <Col span={20}>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "4.3rem", marginRight: "0rem" }}
                  >
                    {"$10,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.6rem", marginRight: "0.8rem" }}
                  >
                    {"$15,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "6.7rem", marginRight: "0.5rem" }}
                  >
                    {"$20,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                  >
                    {"$25,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                  >
                    {"$30,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                  >
                    {"$25,000"}
                  </span>
                </Col>
              </Row>
              <Divider className="mt-0" />

              <Row className="mb-4">
                <Col className="font-bold" span={3}>
                  Net Revenue
                </Col>
                <Col span={21}></Col>

                <Col span={3}>
                  {" "}
                  <Tag color="#27a6a4">Syrildiaphene H2 2022 Italy</Tag>{" "}
                </Col>

                <Col span={21}>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "6.7rem", marginRight: "0rem" }}
                  >
                    {"$970,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.8rem" }}
                  >
                    {"$850,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.5rem", marginRight: "0.5rem" }}
                  >
                    {"$1,970,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.9rem", marginRight: "0.5rem" }}
                  >
                    {"$2,850,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.8rem", marginRight: "0.5rem" }}
                  >
                    {"$3,500,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.9rem", marginRight: "0.5rem" }}
                  >
                    {"$4,320,000"}
                  </span>
                </Col>
                <Col span={3}>
                  {" "}
                  <Col span={3}>
                    {" "}
                    <Tag color="#27a6a4">Azpelumab H1 2022 Italy</Tag>{" "}
                  </Col>
                </Col>
                <Col span={21}>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "6.7rem", marginRight: "0rem" }}
                  >
                    {"$670,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.8rem" }}
                  >
                    {"$990,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.5rem", marginRight: "0.5rem" }}
                  >
                    {"$2,000,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.9rem", marginRight: "0.5rem" }}
                  >
                    {"$2,450,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.8rem", marginRight: "0.5rem" }}
                  >
                    {"$4,500,000"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "5.9rem", marginRight: "0.5rem" }}
                  >
                    {"$4,500,000"}
                  </span>
                </Col>
              </Row>
              <Divider className="mt-0" />

              <Row className="mb-4">
                <Col className="font-bold" span={3}>
                  Net Price
                </Col>
                <Col span={21}></Col>

                <Col span={3}>
                  {" "}
                  <Tag color="#27a6a4">Syrildiaphene H2 2022 Italy</Tag>{" "}
                </Col>
                <Col span={21}>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "8.2rem", marginRight: "0rem" }}
                  >
                    {"$800"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.8rem" }}
                  >
                    {"$1,200"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.5rem" }}
                  >
                    {"$1,800"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.5rem" }}
                  >
                    {"$2,200"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.2rem", marginRight: "0.5rem" }}
                  >
                    {"$2,900"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.6rem", marginRight: "0.5rem" }}
                  >
                    {"$3,100"}
                  </span>
                </Col>

                <Col span={3}>
                  {" "}
                  <Tag color="#27a6a4">Azpelumab H1 2022 Italy</Tag>{" "}
                </Col>
                <Col span={21}>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "8.2rem", marginRight: "0rem" }}
                  >
                    {"$950"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.8rem" }}
                  >
                    {"$1,900"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.5rem" }}
                  >
                    {"$2,800"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.5rem", marginRight: "0.5rem" }}
                  >
                    {"$3,200"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.2rem", marginRight: "0.5rem" }}
                  >
                    {"$3,900"}
                  </span>
                  <span
                    className="text-sm font-semibold "
                    style={{ marginLeft: "7.6rem", marginRight: "0.5rem" }}
                  >
                    {"$4,700"}
                  </span>
                </Col>
              </Row>
            </div>

            <div style={{ height: "12rem" }}></div>

            {/* <div className="mt-20">sdalasml</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
