import {
  constructDetailsBasedOnConstructIdData,
  getAllConstructsData,
  newlyAddedConstructData,
  saveConstructData,
} from "../../../services/SimulatorConfiguration";

export const getAllConstructs: (productId: string) => Promise<unknown> = async (productId) => {
  const data = await getAllConstructsData(productId);

  if (data === null || data === undefined) return null;

  return data;
};

export const newlyAddedConstruct: () => Promise<unknown> = async () => {
  const data = await newlyAddedConstructData();

  if (data === null || data === undefined) return null;

  return data;
};

export const saveConstruct: (value: any) => Promise<unknown> = async (value) => {
  const data = await saveConstructData(value);

  if (data === null || data === undefined) return null;

  return data;
};

export const constructDetailsBasedOnConstructId: (constructId: any) => Promise<unknown> = async (
  constructId
) => {
  const data = await constructDetailsBasedOnConstructIdData(constructId);

  if (data === null || data === undefined) return null;

  return data;
};
