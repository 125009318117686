import { request } from "../layout";

export const getAllProductDetailsData = async () => {
  const fetchUrl = "https://api.icp.dev.zsservices.com/AppManagement/AllBrandsList";

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  console.log("LOGIN DETAILS: ", loginDetails);

  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const getProductDetailsData = async (productId: string) => {
  const fetchUrl = "https://api.icp.dev.zsservices.com/AppManagement/BrandDetails?id=" + productId;
  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const getEmptyProductData = async () => {
  const fetchUrl = "https://api.icp.dev.zsservices.com/AppManagement/NewlyAddedBrandDetails";
  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};

export const saveProductDetailsData = async (isUpdate: boolean, value: any) => {
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/appmanagement/SaveBrandDetails?isUpdate=" + isUpdate;
  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;

  const data = await request
    .post(fetchUrl, value, {
      headers: {
        "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
        "Content-Type": "application/json",
        "user-details": loginDetails,
        // "user-details": context.userInfo,
      },
    })
    .then((response) => {
      console.log("Response...", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};

export const getAllConstructsData = async (productId: string) => {
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/AppManagement/ConstructDetails?productId=" + productId;
  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const newlyAddedConstructData = async () => {
  const fetchUrl = "https://api.icp.dev.zsservices.com/AppManagement/NewlyAddedConstructDetails";
  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const saveConstructData = async (value: any) => {
  const fetchUrl = "https://api.icp.dev.zsservices.com/AppManagement/SaveConstructDetails";
  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;

  const data = await request
    .post(fetchUrl, value, {
      headers: {
        "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
        "Content-Type": "application/json",
        "user-details": loginDetails,
        // "user-details": context.userInfo,
      },
    })
    .then((response) => {
      console.log("Response...", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};

export const constructDetailsBasedOnConstructIdData = async (constructId: any) => {
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/AppManagement/ConstructDetailsBasedOnConstructId?constructId=" +
    constructId;

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const getIndicationsListForKMData = async (productId: any) => {
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/AppManagement/IndicationList?productId=" + productId;
  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const getSubpopulationsKMData = async (indicationId: any) => {
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/AppManagement/SubpopulationList?id=" + indicationId;
  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const getKMCurveData = async (productId: any) => {
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/AppManagement/IndicationListforKMcurveData?productId=" +
    productId;
  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const deleteBrandDetailsData = async (productId: any) => {
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/AppManagement/DeleteBrandDetails?id=" + productId;
  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;

  const data = await request
    .post(fetchUrl, "", {
      headers: {
        "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
        "Content-Type": "application/json",
        "user-details": loginDetails,
        // "user-details": context.userInfo,
      },
    })
    .then((response) => {
      console.log("Response...", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};

export const CopyBrandDetailsData = async (productId: any, productName: any) => {
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/AppManagement/CopyBrandDetails?productId=" +
    productId +
    "&productName=" +
    productName;

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const data = await request
    .post(fetchUrl, "", {
      headers: {
        "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
        "Content-Type": "application/json",
        "user-details": loginDetails,
        // "user-details": context.userInfo,
      },
    })
    .then((response) => {
      console.log("Response...", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};
