import React from "react";
import { Alert, Button, Col, Input, InputNumber, Row, Table } from "antd";

export default function CostCapitationTable(props: { level?: any }) {
  let columnsInitial: any = [
    {
      title: "Cost Capitation",
      dataIndex: "costCapitation",
      key: "costCapitation",
      width: "5%",
      fixed: "right",
    },
    {
      title: "2022",
      dataIndex: "2022",
      key: "2022",
      width: "5%",
      fixed: "right",
    },
    {
      title: "2023",
      dataIndex: "2023",
      key: "2023",
      width: "5%",
      fixed: "right",
    },
    {
      title: "2024",
      dataIndex: "2024",
      key: "2024",
      width: "5%",
      fixed: "right",
    },
    {
      title: "2025",
      dataIndex: "2025",
      key: "2025",
      width: "5%",
      fixed: "right",
    },
    {
      title: "2026",
      dataIndex: "2026",
      key: "2026",
      width: "5%",
      fixed: "right",
    },
    {
      title: "2027",
      dataIndex: "2027",
      key: "2027",
      width: "5%",
      fixed: "right",
    },
  ];
  const [columns, setColumns] = React.useState<any>(columnsInitial);

  const handleValues = (val: any, RowNumber: any) => {};

  let dummyData = [
    {
      key: 1,
      costCapitation: "Entire Contract",
      2022: <Input defaultValue={"$750,0000"} key={1} onChange={(e) => handleValues(e, 1)}></Input>,
      2023: (
        <Input defaultValue={"$1,10,0000"} key={1} onChange={(e) => handleValues(e, 1)}></Input>
      ),
      2024: (
        <Input defaultValue={"$1,80,0000"} key={1} onChange={(e) => handleValues(e, 1)}></Input>
      ),
      2025: (
        <Input defaultValue={"$2,40,0000"} key={1} onChange={(e) => handleValues(e, 1)}></Input>
      ),
      2026: (
        <Input defaultValue={"$2,80,0000"} key={1} onChange={(e) => handleValues(e, 1)}></Input>
      ),
      2027: (
        <Input defaultValue={"$3,20,0000"} key={1} onChange={(e) => handleValues(e, 1)}></Input>
      ),
    },
  ];

  const [dataSource, setDataSource] = React.useState<any>(dummyData);

  React.useEffect(() => {}, [props.level]);

  return (
    <>
      <Row>
        <Col span={18}>
          <div>
            <Table
              pagination={false}
              columns={columns}
              dataSource={[...dataSource]}
              bordered
              size="small"
            />
          </div>
        </Col>
        <Col span={4}></Col>
      </Row>
    </>
  );
}
