import { Button, Drawer, Table } from "antd";
import React, { useEffect, useRef } from "react";
import { Edit } from "@styled-icons/evaicons-solid/Edit";
import DosingSchedule from "../../SimulatorConfiguration/BrandProductSetup/dosingSchedule";
import EpidemiologyEditTables from "../Epidemiology/epidemiologyEditTables";
import "../Pricing/index.css";
import { EditOutline } from "styled-icons/evaicons-outline";
export default function PricingTables(props: {
  tableData: any;
  tableColumns: any;
  EditTableColumns: any;
  name: any;
  onSave: any;
}) {
  const [visible, setVisible] = React.useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <>
        {console.log(props.tableColumns)}
        <p className="float-right mr-8 mt-8">
          <EditOutline size={22} onClick={showDrawer} />
        </p>

        <Table
          bordered
          className="mr-8"
          // scroll={{ x: 1300 }}
          columns={props.tableColumns}
          key={Math.random() + ""}
          size={"small"}
          // dataSource={props.data}
          pagination={false}
          dataSource={props.tableData}
        />

        <Drawer title="Edit Table Data" placement="bottom" onClose={onClose} visible={visible}>
          <div>
            {visible && (
              <>
                <EpidemiologyEditTables
                  columns={props.EditTableColumns}
                  dataSource={props.tableData}
                  onSave={props.onSave}
                  indicationId={1}
                  key={2}
                  name={props.name}
                />
              </>
            )}
          </div>
        </Drawer>
      </>
    </div>
  );
}
