import { Button, Drawer, Table } from "antd";
import React from "react";
import KmCurveData from "../../../libraries/KmCurveData";
import { Edit } from "@styled-icons/evaicons-solid/Edit";

let monthlyCol: any = [];

monthlyCol.push({ title: "Month", dataIndex: "0", fixed: "left", width: 100 });
for (let i = 1; i <= 40; i++) {
  monthlyCol.push({
    title: i + "",
    dataIndex: i + "",
    width: 70,
  });
}
const columns = monthlyCol;
export default function KmCurveTables(props: {
  handleInputChange: any;
  kmData: any;
  indication: any;
  subpop: any;
  KMCurve1: any;
  KMCurve2: any;
  KMCurve3: any;
  indx: any;
  data: any;
}) {
  const [visible, setVisible] = React.useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="mt-8">
      <Button onClick={showDrawer} className="float-right mt-4 mb-4" icon={<Edit size={22} />}>
        Edit table
      </Button>

      <Table
        bordered
        className=""
        scroll={{ x: 1300 }}
        columns={columns}
        key={Math.random() + ""}
        pagination={false}
        dataSource={props.data}
      />

      <Drawer title="Edit KM Curve Data" placement="bottom" onClose={onClose} visible={visible}>
        <div>
          {visible && (
            <KmCurveData
              handleInputChange={props.handleInputChange}
              data={props.data}
              id={props.indx}
              kmData={props.kmData}
              indication={props.indication}
              subpop={props.subpop}
              KMCurve1={props.KMCurve1}
              KMCurve2={props.KMCurve2}
              KMCurve3={props.KMCurve3}
              onClose={onClose}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
}
