import {  saveSimulatorConfigData } from "../../../services/ContractInput";
import {
  getIndicationsListForKMData,
  getKMCurveData,
  getSubpopulationsKMData,
} from "../../../services/SimulatorConfiguration";

export const getIndicationsListForKM: (productId: any) => Promise<unknown> = async (productId) => {
  const data = await getIndicationsListForKMData(productId);

  if (data === null || data === undefined) return null;

  return data;
};

export const getSubpopulationsKM: (productId: any) => Promise<unknown> = async (productId) => {
  const data = await getSubpopulationsKMData(productId);

  if (data === null || data === undefined) return null;

  return data;
};

export const getKMCurve: (productId: any) => Promise<unknown> = async (productId) => {
  const data = await getKMCurveData(productId);

  if (data === null || data === undefined) return null;

  return data;
};

export const saveSimulatorConfig: (value: any) => Promise<unknown> = async (value) => {
  const data = await saveSimulatorConfigData(value);

  if (data === null || data === undefined) return null;

  return data;
};
