import "../Landing/index.css";
import React from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { useEffect, useState } from "react";
import AppContext from "../../store";
import { checkLogin } from "./service-helper";
import { useNavigate } from "react-router";

type RequiredMark = boolean | "optional";

const LoginPage = (props: { isIn: any; setIsIn: any }) => {
  React.useEffect(() => {
    window.sessionStorage.setItem("sideHeader", "false");
    // console.log("A", props.isIn);
    props.setIsIn(true);
    // console.log("AA", props.isIn);
  }, []);

  let navigate = useNavigate();

  const context = React.useContext(AppContext);

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    // context.setLogin(true);
    //api call here
    checkLogin(values).then((val: any) => {
      window.sessionStorage.clear();

      if (val) {
        console.log(val);
        // context.setLogin(true);
        window.sessionStorage.setItem("login", JSON.stringify(val));
        window.sessionStorage.setItem("sideHeader", "true");
        context.setLogin(true);
        navigate("/contracts");
      } else {
        console.log("error!");
        sessionStorage.setItem("login", "abcd");
        window.sessionStorage.setItem("sideHeader", "false");
        context.setLogin(false);
      }
    });
  };

  useEffect(() => {}, []);

  const [requiredMark, setRequiredMarkType] = useState<RequiredMark>("optional");
  const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  return (
    <div>
      <div className="bck">
        <img
          className="ml-2 mt-4 mb-16 h-14 items-baseline overflow-visible"
          src="/logo/ZSLogoFull.png"
          alt="zslogo"
          style={{ zoom: "140%" }}
        />
        <h2 className="text-5xl mb-4 w-max text-white-200" style={{ color: "white" }}>
          Innovative Contracting
        </h2>
        <Form
          name="normal_login"
          className="login-form"
          // initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={requiredMark}
        >
          <Form.Item
            label={<label style={{ color: "white" }}>Username</label>}
            required
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input placeholder="Enter username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
            label={<label style={{ color: "white" }}>Password</label>}
          >
            <Input type="password" placeholder="Enter password" />
          </Form.Item>
          <Form.Item>
            <Form.Item
              // name="remember"
              valuePropName="checked"
              noStyle
            >
              <Checkbox style={{ color: "white" }}>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
