import React from "react";
import { Button, Collapse } from "antd";
import Construct from "./Construct";
import { useNavigate } from "react-router";
import AppContext from "../../../store";

const { Panel } = Collapse;
export default function ConstructPage() {
  let navigate = useNavigate();
  const context = React.useContext(AppContext);

  const handleClick = () => {
    navigate("/contract-performance", {
      state: { name: "Ciplolcysteine H12022 Italy" },
    });
    context.current = 0;
  };

  const goBackToContracts = () => {
    navigate("/contracts", {});
    context.current = 0;
  };

  return (
    <div>
      <Collapse defaultActiveKey={["1"]} accordion>
        <Panel header={<p className="font-bold">Construct 1</p>} key="1">
          <Construct key="1" type="1" />
        </Panel>
        <Panel
          header={
            <>
              <p className="font-bold">Construct 2 </p> <i className="ml-2"> (Optional)</i>
            </>
          }
          key="2"
        >
          <Construct key="2" type="2" />
        </Panel>
      </Collapse>

      <div className="mt-24 ml-8 pb-16">
        <Button className="float-left" onClick={goBackToContracts}>
          Go Back to List of Contracts
        </Button>
        <Button type="primary" onClick={handleClick} className="ml-8  float-left">
          Check Contract Performance
        </Button>
      </div>
    </div>
  );
}
