import React from "react";
import { Button, Collapse } from "antd";
import "./index.css";
import { createContext } from "react";
import background from "../../globalTheme/background2.png";

const { Panel } = Collapse;
const text = `
  Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
    dignissim elementum. Mollis tincidunt mattis hendrerit dolor eros enim, nisi ligula ornare.
    Hendrerit parturient habitant pharetra rutrum gravida porttitor eros feugiat. Mollis elit
    sodales taciti duis praesent id. Consequat urna vitae morbi nunc congue.,
`;


export default function UserManagement() {
  return (
    <div className="h-5/6 mt-40 ml-40" style={{ backgroundImage: `url(${background})` }}>
      User Management Page Here!
    </div>
  );
}
