import { List, Avatar, Button, Popconfirm, Form, Input, Tooltip } from "antd";
import { AddCircle } from "@styled-icons/fluentui-system-filled/AddCircle";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";
import { Delete } from "@styled-icons/fluentui-system-regular/Delete";
import { Copy } from "@styled-icons/fa-regular/Copy";
import React, { useEffect, useState } from "react";
import { Table, Radio, Divider, message, Modal } from "antd";
import { Link } from "react-router-dom";
import AppContext from "../../store";
import { getProductDetails } from "../SimulatorConfiguration/BrandProductSetup/service-helper";
import { CopyBrandDetails, deleteBrandDetails } from "./service-helper";
import { getAllProductDetailsData } from "../../services/SimulatorConfiguration";
import "../Landing/index.css";

// import { getAllProductDetailsData } from "../../services/SimulatorConfiguration";
const columns = [
  {
    title: <span className="text-base">Product</span>,
    dataIndex: "product",
    width: 200,
  },
  {
    title: <span className="text-base">Indications</span>,
    dataIndex: "indications",
    width: 300,
  },
  {
    title: <span className="text-base">Action</span>,
    dataIndex: "editButton",
    width: 100,
  },
];

const text = "Are you sure to delete this product?";

export default function LandingPage() {
  const context = React.useContext(AppContext);
  const [dataForTable, setDataForTable] = React.useState([]);
  const [productIdForCopy, setProductIdForCopy] = React.useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const confirm = async (productId: any) => {
    let data: any = await deleteBrandDetails(productId);

    if (data) {
      const data2 = await getAllProductDetailsData();
      context.setTableData(data2);
      message.info("Successfully deleted");
    } else {
      message.info("Deletion Failed!");
    }
  };

  const copyProduct = (productId: any) => {
    showModal();
    setProductIdForCopy(productId);
  };

  const handleFinish = async (values: any) => {
    // console.log(values);
    let data: any = await CopyBrandDetails(productIdForCopy, values.productName);
    if (data) {
      const data2 = await getAllProductDetailsData();
      context.setTableData(data2);
      message.info("Successfully Copied Product!");
    } else {
      message.info("Copy Failed!");
    }
    setIsModalVisible(false);
  };

  const handleClick = async (productId: any) => {
    let data: any = await getProductDetails(productId);
    context.setBrandId(productId);
    // console.log(data);
    context.setProductDetails(data);
  };

  useEffect(() => {
    context.setBrandId(null);
    let tableDataFinal: any = [];
    console.log("context", context.tableData);
    if (context.tableData) {
      context.tableData.map((i: any) => {
        let tableDataObject: any = {};
        tableDataObject.key = i.productId;
        tableDataObject.product = i.productName;
        console.log("BEFORE ERROR", i.productType);
        tableDataObject.productType = i.productType.filter(
          (x: any) => x.id === i.selectedProductType
        )[0].name;

        let indicationList: any = "";
        let count: any = i.indications.length;
        console.log("count", count);
        i.indications.map((j: any, indx: any) => {
          if (indx === count - 1) {
            indicationList += j.indication_Name;
          } else {
            indicationList += j.indication_Name + ", ";
          }
        });
        tableDataObject.indications = indicationList;
        tableDataObject.editButton = (
          <>
            <Link to={"/app-management"} state={i.productId}>
              <Tooltip placement="topLeft" title="Edit ">
                <Button
                  type="link"
                  className="ml-8 mr-6"
                  shape="circle"
                  onClick={() => {
                    handleClick(i.productId);
                  }}
                  icon={<EditOutline style={{ color: "black" }} className="mb-1" size={22} />}
                />
              </Tooltip>
            </Link>
            {/* <Link to={"/app-management"} state={i.productId}>
              <EditOutline
                onClick={() => {
                  handleClick(i.productId);
                }}
                className="ml-4"
                size={22}
              />
            </Link> */}
            <Popconfirm
              placement="top"
              title={text}
              onConfirm={() => {
                confirm(i.productId);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip placement="topLeft" title="Delete ">
                <Button
                  danger
                  type="link"
                  className="ml-8 mr-6"
                  key={"delete_" + i.productId}
                  shape="circle"
                  icon={<Delete className="mb-1" size={22} />}
                />
              </Tooltip>
            </Popconfirm>

            <Tooltip placement="topLeft" title="Copy ">
              <Button
                type="link"
                className="ml-8 mr-6"
                shape="circle"
                onClick={() => copyProduct(i.productId)}
                icon={<Copy style={{ color: "black" }} className="mb-1" size={18} />}
              />
            </Tooltip>
          </>
        );
        tableDataFinal.push(tableDataObject);
      });
      setDataForTable(tableDataFinal);
    }
  }, [context.tableData]);

  const [form] = Form.useForm();

  return (
    <div
      style={{
        height: "95vh",
        // background: "white",
        marginLeft: "1rem",
        marginRight: "1rem",
        marginTop: "1rem",
      }}
      className="back"
    >
      <div>
        <Modal
          title="Copy Product"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button form="copyForm" className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button type="primary" form="copyForm" key="submit" htmlType="submit">
              Submit
            </Button>,
          ]}
        >
          <Form layout="vertical" form={form} name="copyForm" onFinish={handleFinish}>
            <Form.Item name="productName" label="Product Name">
              <Input placeholder="" />
            </Form.Item>
          </Form>
        </Modal>
        <h4 className="ml-12 pt-12 text-2xl mb-8" style={{ fontWeight: "700" }}>
          Product Configuration - All Products
        </h4>

        {dataForTable.map((i: any) => (
          <div
            className={`font-lato border-l-8  rounded-md border-dashboard-blue} w-full my-2`}
            style={{
              background: "#D7EFFF",
              borderColor: "#27a6a4",
              marginLeft: "1rem",
              marginRight: "1rem",
              height: "4rem",
              width: "97%",
            }}
          >
            <div style={{ padding: "0.5rem" }} className="w-full grid grid-cols-12 items-center">
              <div className="col-span-3">
                <div className="w-full flex items-center justify-left gap-2">
                  <div className="w-1/10 text-zsYellow button-favourite"></div>
                  <div className="w-9/10">
                    <div
                      className="text-xl font-bold text-zsBlack80"
                      style={{ color: "rgb(71, 69, 84)" }}
                    >
                      <span>{i.product}</span>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-6">
                <div className="grid grid-cols-3 px-2">
                  <div className="text-center">
                    <div className="text-zsBlack50 text-xs" style={{ color: "#888888" }}>
                      Indications
                    </div>
                    <div
                      className="text-zsBlack60 text-base font-semibold"
                      style={{ color: "#75737d" }}
                    >
                      {i.indications}
                    </div>
                  </div>
                  <div className="text-center"></div>
                  <div className="text-center">
                    <div
                      className="text-zsBlack50 text-xs"
                      style={{ color: "#888888", marginLeft: "-10rem" }}
                    >
                      Product Type
                    </div>
                    <div
                      className="text-zsBlack60 text-base font-semibold"
                      style={{ color: "#75737d", marginLeft: "-10rem" }}
                    >
                      {i.productType}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 text-right" style={{ width: "17rem" }}>
                {i.editButton}
              </div>
            </div>
          </div>
        ))}

        <Link to="/app-management">
          <Button
            className="float-left ml-8 mt-8"
            type="primary"
            onClick={() => context.setCurrent(0)}
            icon={
              <AddCircle
                className="float-left mr-4"
                size={30}
                style={{ marginTop: "-2px" }}
                onClick={() => context.setCurrent(0)}
                // color={"#27a6a4"}
              />
            }
            size="large"
          >
            Add New Product
          </Button>
        </Link>
      </div>
    </div>
  );
}
