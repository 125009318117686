import { Form, Input, Row, Col, Divider, InputNumber, Tooltip, Table, Card } from "antd";
import { DatePicker, Space } from "antd";
import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { InfoCircle } from "@styled-icons/bootstrap/InfoCircle";
import moment from "moment";
import "../ContractSetup/index.css";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function ContractSetup(props: {
  contractData: any;
  start: any;
  end: any;
  isNewContract: any;
  isVialShared: any;
  setIsVialShared: any;
}) {
  const columns = [
    {
      title: "Indications",
      dataIndex: "indications",
      key: "indications",
    },
    {
      title: "Avg. Wt. of patient",
      dataIndex: "avgWt",
      key: "avgWt",
    },
    {
      title: "Prescribed Dose",
      dataIndex: "prescribedDose",
      key: "prescribedDose",
    },
    {
      title: "Volume of product per unit",
      dataIndex: "volume",
      key: "volume",
    },
    {
      title: "No. of units per dose",
      dataIndex: "unitsPerDose",
      key: "unitsPerDose",
    },
  ];

  const dataSource = [
    {
      key: "1",
      indications: "ind1",
      avgWt: 80,
      prescribedDose: "3",
      volume: "2",
      unitsPerDose: 2,
    },
    {
      key: "2",
      indications: "ind2",
      avgWt: 75,
      prescribedDose: "2",
      volume: "2",
      unitsPerDose: 2,
    },
  ];

  // const [isVialShared, setIsVialShared] = React.useState<boolean>();

  React.useEffect(() => {
    if (props.contractData) {
      props.setIsVialShared(props.contractData.isVialSharingConsidered);
    }
  }, []);

  const handleChange = (value: string) => {
    console.log(value);
    if (value) props.setIsVialShared(true);
    else props.setIsVialShared(false);
  };

  React.useEffect(() => {}, [props.start, props.end]);

  return (
    <>
      {props.isNewContract ? (
        <>
          <div className="ml-8 mt-6 mr-8">
            <br></br>
            <div className="text-xl font-bold float-left">
              <div className="text-xl font-bold -ml-20 mb-6">Contract Setup</div>
              <div className="text-sm font-normal text-[#75737D]">
                Input basic details for the contract
              </div>
            </div>

            <div></div>

            <div className="float-right">
              <Card
                size="small"
                title={
                  <>
                    <span style={{ fontWeight: "400", color: "#888888" }}>Product Name: </span>
                    <span style={{ color: "rgb(71 69 84", fontWeight: "600" }}>
                      {props.contractData.product_Name}{" "}
                    </span>
                  </>
                }
                bordered={true}
                style={{
                  width: 300,
                  borderColor: "#27a6a4",
                  marginTop: "-12px",
                }}
              >
                <div>
                  <span style={{ fontWeight: "400", color: "#888888" }}>Product Type:</span>
                  <span style={{ color: "rgb(71 69 84", fontWeight: "600" }}>
                    {" "}
                    {props.contractData.product_Type}{" "}
                  </span>
                </div>
              </Card>
            </div>

            <div
              className="border-2 border-sky-500 h-10 bg-slate-300 mt-8"
              style={{
                background: "#F4F3F3",
                borderColor: "#F4F3F3",
                marginTop: "6rem",
              }}
            >
              <div className="mt-2 font-bold mt-12">
                Contract Information
                <Tooltip title="Lorem Ipsum text">
                  <InfoCircle
                    className="mr-4"
                    style={{ marginLeft: "0.5rem", marginTop: "-1em" }}
                    size={14}
                  />
                </Tooltip>
              </div>
            </div>

            <br></br>
            <br></br>

            <Row>
              <Col span={12}>
                <Form.Item name="name" label="Contract Name" rules={[{ required: true }]}>
                  <Input style={{ minWidth: "15.1rem", float: "left" }} placeholder="" />
                </Form.Item>
              </Col>
              {console.log(props.start, props.end)}
              <Col span={12}>
                <Form.Item
                  name="inputCurrency"
                  label="Currency Used for Pricing Inputs"
                  rules={[{ required: true }]}
                  tooltip={{ title: "lorem ipsum text", icon: <InfoCircleOutlined /> }}
                >
                  <Select className="float-left" defaultValue="" style={{ width: 210 }}>
                    <Option value="USD">USD</Option>
                    <Option value="INR">INR</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item
                  initialValue={[moment(props.start), moment(props.end)]}
                  name="validity"
                  label="Contract Period"
                  rules={[{ required: true }]}
                >
                  <RangePicker picker="month" />
                </Form.Item>
              </Col>{" "}
              <Form.Item
                name="followUpMonths"
                label="Follow Up Period (in Months)"
                rules={[{ required: true }]}
              >
                {/* <Input style={{ maxWidth: "10rem", float: "left" }} placeholder="" /> */}
                <InputNumber
                  size="middle"
                  style={{ marginTop: "6px", paddingRight: "26px" }}
                  addonAfter="Months"
                />
              </Form.Item>
              <Col span={12}></Col>
            </Row>

            {props.contractData.product_Type === "Vial" && (
              <Row>
                <>
                  <Col style={{ marginRight: "-5.5em" }} span={8}>
                    <Form.Item
                      name="isVialSharingConsidered"
                      label="Vial Sharing to be Considered?"
                      rules={[{ required: true }]}
                    >
                      <Select
                        className="float-left"
                        defaultValue={props.contractData.isVialSharingConsidered}
                        style={{ width: 242 }}
                        onChange={handleChange}
                      >
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {props.isVialShared ? (
                    <Row>
                      <Col span={8}>
                        <Form.Item
                          style={{ marginTop: "-5.5rem" }}
                          name="wastageInLastVial"
                          label="Percentage of wastage in the last (residual) vial"
                          rules={[{ required: true }]}
                        >
                          <InputNumber addonAfter="%" />
                        </Form.Item>
                      </Col>
                      {/* <Table
                        style={{ marginLeft: "-21.5rem" }}
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                      /> */}
                    </Row>
                  ) : (
                    <></>
                  )}
                </>
              </Row>
            )}
            {/* </Form> */}
          </div>
        </>
      ) : (
        <>
          {props.start && props.end && (
            <>
              <div className="ml-8 mt-6 mr-8">
                <br></br>
                <div className="text-xl font-bold float-left">
                  <div className="text-xl font-bold -ml-20 mb-3">Contract Setup</div>
                  <div className="text-sm font-normal text-[#75737D]">
                    Input basic details for the contract
                  </div>
                </div>

                <div className="float-right">
                  <Card
                    size="small"
                    bordered={true}
                    style={{
                      width: 300,
                      // borderRadius: "18px",
                      borderColor: "#27a6a4",
                      marginTop: "-12px",
                    }}
                  >
                    {/* <span style={{ fontWeight: "400", color: "#888888" }}>Product Type:</span> */}
                    <span style={{ color: "rgb(71 69 84", fontWeight: "600" }}>
                      {" "}
                      {props.contractData.product_Type}{" "}
                    </span>
                  </Card>

                  <Card
                    size="small"
                    bordered={true}
                    style={{
                      width: 300,
                      borderColor: "#27a6a4",
                      marginLeft: "-20rem",
                      marginTop: "-45px",
                    }}
                  >
                    {/* <span style={{ fontWeight: "400", color: "#888888" }}>Product Name: </span> */}
                    <span style={{ color: "rgb(71 69 84", fontWeight: "600" }}>
                      {props.contractData.product_Name}
                    </span>
                  </Card>
                </div>

                <div
                  className="border-2 border-sky-500 h-10 bg-slate-300 mt-8"
                  style={{ background: "#F4F3F3", borderColor: "#F4F3F3", marginTop: "6rem" }}
                >
                  <div className="mt-2 font-bold mt-12">
                    Contract Information
                    <Tooltip title="Lorem Ipsum text">
                      <InfoCircle
                        className="mr-4"
                        style={{ marginLeft: "0.5rem", marginTop: "-1em" }}
                        size={14}
                      />
                    </Tooltip>
                  </div>
                </div>

                <br></br>
                <br></br>
                <Row>
                  <Col span={12}>
                    <Form.Item name="name" label="Contract Name" rules={[{ required: true }]}>
                      <Input style={{ minWidth: "15.1rem", float: "left" }} placeholder="" />
                    </Form.Item>
                  </Col>
                  {console.log(props.start, props.end)}
                  <Col span={12}>
                    <Form.Item
                      name="inputCurrency"
                      label="Currency Used for Pricing Inputs"
                      rules={[{ required: true }]}
                      tooltip={{ title: "lorem ipsum text", icon: <InfoCircleOutlined /> }}
                    >
                      <Select className="float-left" defaultValue="" style={{ width: 210 }}>
                        <Option value="USD">USD</Option>
                        <Option value="INR">INR</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      initialValue={[moment(props.start), moment(props.end)]}
                      name="validity"
                      label="Contract Period"
                      rules={[{ required: true }]}
                    >
                      <RangePicker picker="month" format={"YYYY-MMM"} />
                    </Form.Item>
                  </Col>{" "}
                  <Form.Item
                    name="followUpMonths"
                    label="Follow Up Period (in Months)"
                    rules={[{ required: true }]}
                  >
                    {/* <Input style={{ maxWidth: "10rem", float: "left" }} placeholder="" /> */}
                    <InputNumber
                      size="middle"
                      style={{ marginTop: "6px", paddingRight: "26px" }}
                      addonAfter="Months"
                    />
                  </Form.Item>
                  <Col span={12}></Col>
                </Row>

                {props.contractData.product_Type === "Vial" && (
                  <Row>
                    <>
                      <Col span={12}>
                        <Form.Item
                          name="isVialSharingConsidered"
                          label="Vial Sharing to be Considered?"
                          rules={[{ required: true }]}
                        >
                          <Select
                            className="float-left"
                            defaultValue={props.contractData.isVialSharingConsidered}
                            style={{ width: 242 }}
                            onChange={handleChange}
                          >
                            <Option value={true}>Yes</Option>
                            <Option value={false}>No</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      {props.isVialShared ? (
                        <>
                          <Row>
                            <Col className="-ml-3" span={12}>
                              <Form.Item
                                // style={{ marginTop: "-5.5rem" }}
                                name="wastageInLastVial"
                                label="Percentage of Wastage in the Last Vial"
                                rules={[{ required: true }]}
                              >
                                <InputNumber addonAfter="%" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            {/* <Table style={{width: "53%"}} pagination={false} dataSource={dataSource} columns={columns} /> */}
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  </Row>
                )}
                {/* </Form> */}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
