import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import ConstructSelection from "../pages/ConstructSelection";
import ContractComparison from "../pages/ContractComparison";
import ContractInput from "../pages/ContractInput";
import ContractPerformance from "../pages/ContractPerformance";
import LoginPage from "../pages/Landing";
import ContractsList from "../pages/Landing/ContractsList";
import LandingPage from "../pages/Landing/LandingPage";
import SimulatorConfiguration from "../pages/SimulatorConfiguration";
import UserManagement from "../pages/UserManagement";
import AppContext from "../store";

export function Routing(props: { isIn: any; setIsIn: any }) {
  const context = React.useContext(AppContext);
  React.useEffect(() => {}, [context.login]);
  return (
    <>
      {context.login ? (
        <>
          <div
            className="ml-60 h-screen"
            style={{ overflowY: "hidden", overflowX: "hidden", background: "#E5E5E5" }}
          >
            <Routes>
              {console.log(window.sessionStorage.getItem("sideHeader"))}
              {/* <Route path="/login" element={<LoginPage />} /> */}
              <Route path="/app-management/*" element={<SimulatorConfiguration />} />
              <Route path="/user-management/*" element={<UserManagement />} />
              <Route path="/" element={<ContractsList />} />
              <Route path="/products/*" element={<LandingPage />} />
              <Route path="/contracts/*" element={<ContractsList />} />
              <Route path="/contract-input/*" element={<ContractInput />} />
              <Route path="/contract-performance/*" element={<ContractPerformance />} />
              <Route path="/contract-comparison/*" element={<ContractComparison />} />
              {/* <Route path="/construct-selection/*" element={<ConstructSelection />} /> */}
            </Routes>
          </div>
        </>
      ) : (
        <>
          <Routes>
            <Route
              path="/login"
              element={<LoginPage isIn={props.isIn} setIsIn={props.setIsIn} />}
            />
            <Route path="/" element={<LoginPage isIn={props.isIn} setIsIn={props.setIsIn} />} />
          </Routes>
        </>
      )}
    </>
  );
}
