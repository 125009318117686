import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import "./index.css";
import AppContext from "../../../store";
import { Tabs } from "antd";
import { Bin2 } from "styled-icons/icomoon";
import { v4 as uuidv4 } from "uuid";
import { PlusOutlined } from "@ant-design/icons";
import KmCurveData from "../../../libraries/KmCurveData";
import { getIndicationsListForKM, getKMCurve, saveSimulatorConfig } from "./service-helper";
import KmCurveTables from "./kmCurveTables";
import { AddCircle } from "@styled-icons/fluentui-system-filled/AddCircle";
import { useNavigate } from "react-router";
import background from "../../../globalTheme/background2.png";

const { TabPane } = Tabs;
const { Panel } = Collapse;
let uuidArr: any = [];
export default function KMCurveData() {
  let navigate = useNavigate();
  const context = React.useContext(AppContext);

  const [kmData, setKMData] = React.useState<any>();
  const [outputData, setOutputData] = React.useState<any>();
  const FetchData = async () => {
    const kmDataaa: any = await getKMCurve(context.brandId);
    console.log(kmDataaa);
    if (kmDataaa[0].isKMcurve) {
      console.log("Value here");

      outputDataValueSet(kmDataaa);

      kmDataaa.map((i: any) => {
        i.subpopulationModel.map((s: any) => {
          let kmdsc: any = [];
          let kmValues: any = [];

          for (let count = 0; count <= 2; count++) {
            let kmdscObj: any = {};
            let dt = s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date;
            // console.log(new Date(s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date));
            let dateTemp =
              new Date(s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date).getFullYear() +
              "-" +
              (
                "0" +
                (new Date(s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date).getMonth() +
                  1)
              ).slice(-2) +
              "-" +
              (
                "0" +
                new Date(s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date).getDate()
              ).slice(-2);
            // console.log(dateTemp);

            kmdscObj["name"] = i.kMcurveTypeModel[count].curveType;
            kmdscObj.date =
              s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date !== null ? dateTemp : "";
            kmdscObj.source =
              s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].source !== null
                ? s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].source
                : "";
            kmdscObj.comments =
              s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].comments !== null
                ? s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].comments
                : "";
            kmdscObj.uuid = s.subpopulation_ID;
            kmdsc.push(kmdscObj);
            let kmValuesObj: any = {};
            kmValuesObj[0] = i.kMcurveTypeModel[count].curveType;
            for (let indx = 0; indx < 40; indx++) {
              kmValuesObj[indx + 1] = s.kmCurveTypeModel[count].kmCurveValuesModel.filter(
                (mon: any) => mon.monthIndex === indx + 1
              )[0].value;
            }
            kmValues.push(kmValuesObj);
          }

          //loop for kmcurvetype
          s["KMdsc"] = kmdsc;
          s["KMmonth"] = kmValues;
        });
      });
    }
    // // setKMData(kmDataaa);

    // console.log(kmDataaa);
    setKMData(kmDataaa);
  };

  // for KM Curve Types
  const handleInputChange = (indication: any, event: any) => {
    const values: any = [...outputData];

    if (
      event.target.name === indication.indication_ID + "_type1" ||
      event.target.name === indication.indication_ID + "_type2" ||
      event.target.name === indication.indication_ID + "_type3"
    ) {
      values
        .filter((ind: any) => ind.indication_ID === indication.indication_ID)
        .map((s: any) => {
          s.subpopulationModel.map((sub: any) => {
            for (let index = 1; index <= 3; index++) {
              if (event.target.name === indication.indication_ID + "_type" + index) {
                sub.kmCurveTypeModel[index - 1].curveType = event.target.value;

                sub.KMdsc[index - 1].name = event.target.value;
                sub.KMmonth[index - 1][0] = event.target.value;
              }
            }
          });

          if (event.target.name === indication.indication_ID + "_type1")
            s.kMcurveTypeModel[0].curveType = event.target.value;
          else if (event.target.name === indication.indication_ID + "_type2")
            s.kMcurveTypeModel[1].curveType = event.target.value;
          else if (event.target.name === indication.indication_ID + "_type3")
            s.kMcurveTypeModel[2].curveType = event.target.value;
        });
    }

    // console.log(values);

    // setKMData(values);
    setOutputData([...values]);
  };

  const handleExcelChange = (indication: any, subpopulation: any, values: any) => {
    // console.log(indication, subpopulation, values);

    let valuesTemp = [...outputData];

    valuesTemp
      .filter((ind: any) => ind.indication_ID === indication.indication_ID)[0]
      .subpopulationModel.filter(
        (s: any) => s.subpopulation_ID === subpopulation.subpopulation_ID
      )[0].KMmonth[0] = values[0];

    valuesTemp
      .filter((ind: any) => ind.indication_ID === indication.indication_ID)[0]
      .subpopulationModel.filter(
        (s: any) => s.subpopulation_ID === subpopulation.subpopulation_ID
      )[0].KMmonth[1] = values[1];

    valuesTemp
      .filter((ind: any) => ind.indication_ID === indication.indication_ID)[0]
      .subpopulationModel.filter(
        (s: any) => s.subpopulation_ID === subpopulation.subpopulation_ID
      )[0].KMmonth[2] = values[2];

    for (let count = 0; count <= 2; count++) {
      let kmValues: any = [...values];

      let kmMonthValuesObj: any = kmValues.filter(
        (curve: any) =>
          curve["0"] ===
          valuesTemp
            .filter((ind: any) => ind.indication_ID === indication.indication_ID)[0]
            .subpopulationModel.filter(
              (s: any) => s.subpopulation_ID === subpopulation.subpopulation_ID
            )[0].KMdsc[count].name
      )[0];

      // console.log(kmMonthValuesObj);

      for (let i = 1; i <= 40; i++) {
        valuesTemp
          .filter((ind: any) => ind.indication_ID === indication.indication_ID)[0]
          .subpopulationModel.filter(
            (s: any) => s.subpopulation_ID === subpopulation.subpopulation_ID
          )[0]
          .kmCurveTypeModel[count].kmCurveValuesModel.filter(
            (x: any) => x.monthIndex === i
          )[0].value = parseFloat(kmMonthValuesObj[i]);
      }

      // console.log(valuesTemp);
    }

    // setKMData(valuesTemp);
    setOutputData([...valuesTemp]);
  };

  // for subpopulation Name
  const handleInputChange2 = (indication: any, subpop: any, event: any) => {
    // console.log(indication, subpop, event);
    const values: any = [...outputData];

    if (event.target.name === "subpopulationName_" + subpop.subpopulation_ID) {
      values
        .filter((ind: any) => ind.indication_ID === indication.indication_ID)[0]
        .subpopulationModel.filter(
          (s: any) => s.subpopulation_ID === subpop.subpopulation_ID
        )[0].subpopulation_Name = event.target.value;
    }

    setOutputData(values);

    // console.log(kmData);
  };

  useEffect(() => {
    FetchData();
  }, []);

  useEffect(() => {
    if (kmData) {
      let outputDataTemp: any = [...kmData];
      // console.log(outputDataTemp);
      setOutputData([...outputDataTemp]);
    }
  }, [kmData]);

  useEffect(() => {}, [outputData]);

  function outputDataValueSet(outputDataTemp: any) {
    outputDataTemp.map((i: any) => {
      i.subpopulationModel.map((s: any) => {
        let kmdsc: any = [];
        let kmValues: any = [];

        for (let count = 0; count <= 2; count++) {
          let kmdscObj: any = {};
          // let dt = s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date;
          // console.log(dt);
          // let dateTemp =
          //   new Date(s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date).getFullYear() +
          //   "-" +
          //   (
          //     "0" + new Date(s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date).getMonth()
          //   ).slice(-2) +
          //   "-" +
          //   (
          //     "0" + new Date(s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date).getDate()
          //   ).slice(-2);
          // console.log(dateTemp);

          kmdscObj["name"] = i.kMcurveTypeModel[count].curveType;
          // kmdscObj.date =
          //   s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date !== null ? dateTemp : "";
          kmdscObj.source =
            s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].source !== null
              ? s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].source
              : "";
          kmdscObj.comments =
            s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].comments !== null
              ? s.kmCurveTypeModel[count].kMtype_SubpopulationModel[0].comments
              : "";
          kmdscObj.uuid = s.subpopulation_ID;

          kmdsc.push(kmdscObj);
          let kmValuesObj: any = {};
          kmValuesObj[0] = i.kMcurveTypeModel[count].curveType;
          for (let indx = 0; indx < 40; indx++) {
            kmValuesObj[indx + 1] = s.kmCurveTypeModel[count].kmCurveValuesModel.filter(
              (mon: any) => mon.monthIndex === indx + 1
            )[0].value;
          }
          kmValues.push(kmValuesObj);
        }

        s["KMdsc"] = kmdsc;
        s["KMmonth"] = kmValues;
      });
    });

    setKMData(kmData);
    // setOutputData(outputDataTemp);
  }

  const [tableData, setTableData] = useState<any>([]);

  const onFinish = (values: any) => {
    let finalValue = [...outputData];

    finalValue.map((i: any) => {
      console.log(i);
      i.isProductSetup = true;
      console.log(i);

      i.subpopulationModel.map((s: any) => {
        for (let count = 1; count <= 3; count++) {
          // console.log(s.kmCurveTypeModel.filter((km: any) => km.curve === count + "")[0]);
          // console.log(i.kmCurveTypeModel[count - 1].curveType);

          if (s.kmCurveTypeModel.filter((km: any) => km.curve === count + "")[0] !== undefined) {
            s.kmCurveTypeModel.filter((km: any) => km.curve === count + "")[0].curveType =
              i.kMcurveTypeModel[count - 1].curveType;
          }
        }
      });
    });

    // console.log(uuidArr);
    uuidArr.forEach((idToBeDeleted: any) => {
      finalValue.map((i: any) => {
        i.subpopulationModel
          .filter((x: any) => x.subpopulation_ID === idToBeDeleted)
          .map((s: any) => {
            s.subpopulation_ID = null;
            s.kmCurveTypeModel.map((km: any) => {
              km.kMtype_SubpopulationModel[0].subpopulation_ID = null;
            });
          });
      });
    });

    let arrFinal: any = [];

    finalValue.map((i: any) => {
      arrFinal.push(...i.subpopulationModel);
    });
    console.log(arrFinal);
    arrFinal.map((i: any) => {
      i.isProductSetup = true;
    });
    console.log(arrFinal);

    saveSimulatorConfig(arrFinal);

    navigate("/products");
    context.setCurrent(0);
  };

  const onInputChange = (key: any, index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newData = tableData;
    newData[index][key] = e.target.value;
    setTableData(newData);
  };

  const [value2, setValue2] = React.useState<any>();
  function changeKMCurveName(index: any, changedValue: any) {
    let newDataSource = [...tableData];
    //console.log("NEW DATA SOURCE", newDataSource);
    newDataSource[index] = { ...newDataSource[index], name: changedValue, id: index };

    if (changedValue === "") {
      if (index === 0) {
        newDataSource = [];
        setValue2("");
      }

      newDataSource = newDataSource.splice(index - 1, 1);
    }

    setTableData(newDataSource);
  }

  let formatForNew: any;

  const addNewSubpop = (indication_ID: any) => {
    // console.log(outputData);

    let uuid: any = uuidv4();
    uuidArr.push(uuid);

    let kmData_temp = [...outputData];
    let kmCurveMonthlyData1 = [];
    let kmCurveMonthlyData2 = [];
    let kmCurveMonthlyData3 = [];
    for (let i = 1; i <= 40; i++) {
      kmCurveMonthlyData1.push({
        id: null,
        monthIndex: i,
        value: 0,
        kMtype_Subpopulation_ID: null,
      });
      kmCurveMonthlyData2.push({
        id: null,
        monthIndex: i,
        value: 0,
        kMtype_Subpopulation_ID: null,
      });
      kmCurveMonthlyData3.push({
        id: null,
        monthIndex: i,
        value: 0,
        kMtype_Subpopulation_ID: null,
      });
    }
    let kmCurveTypeModel: any = [
      {
        id: kmData_temp.filter((ind: any) => ind.indication_ID === indication_ID)[0]
          .kMcurveTypeModel[0].id
          ? kmData_temp.filter((ind: any) => ind.indication_ID === indication_ID)[0]
              .kMcurveTypeModel[0].id
          : null,
        curve: "1",
        curveType: "",

        kmCurveValuesModel: [...kmCurveMonthlyData1],
        kMtype_SubpopulationModel: [
          {
            id: null,
            uuid: uuid,
            subpopulation_ID: uuid,
            date: "",
            source: "",
            comments: "",
            kmType_ID: kmData_temp.filter((ind: any) => ind.indication_ID === indication_ID)[0]
              .kMcurveTypeModel[0].id
              ? kmData_temp.filter((ind: any) => ind.indication_ID === indication_ID)[0]
                  .kMcurveTypeModel[0].id
              : null,
          },
        ],
      },
      {
        id: kmData_temp.filter((ind: any) => ind.indication_ID === indication_ID)[0]
          .kMcurveTypeModel[1].id
          ? kmData_temp.filter((ind: any) => ind.indication_ID === indication_ID)[0]
              .kMcurveTypeModel[1].id
          : null,
        curve: "2",
        curveType: "",

        kmCurveValuesModel: [...kmCurveMonthlyData2],
        kMtype_SubpopulationModel: [
          {
            id: null,
            uuid: uuid,
            subpopulation_ID: uuid,
            date: null,
            source: "",
            comments: "",
            kmType_ID: kmData_temp.filter((ind: any) => ind.indication_ID === indication_ID)[0]
              .kMcurveTypeModel[1].id
              ? kmData_temp.filter((ind: any) => ind.indication_ID === indication_ID)[0]
                  .kMcurveTypeModel[1].id
              : null,
          },
        ],
      },
      {
        id: kmData_temp.filter((ind: any) => ind.indication_ID === indication_ID)[0]
          .kMcurveTypeModel[2].id
          ? kmData_temp.filter((ind: any) => ind.indication_ID === indication_ID)[0]
              .kMcurveTypeModel[2].id
          : null,
        curve: "3",
        curveType: "",
        kmCurveValuesModel: [...kmCurveMonthlyData3],
        kMtype_SubpopulationModel: [
          {
            id: null,
            uuid: uuid,
            subpopulation_ID: uuid,
            date: null,
            source: "",
            comments: "",
            kmType_ID: kmData_temp.filter((ind: any) => ind.indication_ID === indication_ID)[0]
              .kMcurveTypeModel[2].id
              ? kmData_temp.filter((ind: any) => ind.indication_ID === indication_ID)[0]
                  .kMcurveTypeModel[2].id
              : null,
          },
        ],
      },
    ];
    let kmdsc: any = [];
    let kmValues: any = [];

    for (let count = 0; count <= 2; count++) {
      let kmdscObj: any = {};
      kmdscObj["name"] = kmData_temp.filter(
        (ind: any) => ind.indication_ID === indication_ID
      )[0].kMcurveTypeModel[count].curveType;
      kmdscObj.date =
        kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date !== null
          ? kmCurveTypeModel[count].kMtype_SubpopulationModel[0].date
          : "";
      kmdscObj.source =
        kmCurveTypeModel[count].kMtype_SubpopulationModel[0].source !== null
          ? kmCurveTypeModel[count].kMtype_SubpopulationModel[0].source
          : "";
      kmdscObj.comments =
        kmCurveTypeModel[count].kMtype_SubpopulationModel[0].comments !== null
          ? kmCurveTypeModel[count].kMtype_SubpopulationModel[0].comments
          : "";
      kmdscObj.uuid = kmCurveTypeModel[count].kMtype_SubpopulationModel[0].uuid;

      kmdsc.push(kmdscObj);

      let kmValuesObj: any = {};
      kmValuesObj[0] = kmData_temp.filter(
        (ind: any) => ind.indication_ID === indication_ID
      )[0].kMcurveTypeModel[count].curveType;
      for (let indx = 0; indx < 40; indx++) {
        kmValuesObj[kmCurveTypeModel[0].kmCurveValuesModel[indx].monthIndex] =
          kmCurveTypeModel[count].kmCurveValuesModel[indx].value;
      }

      kmValues.push(kmValuesObj);
    }

    //loop for kmcurvetype

    formatForNew = {
      subpopulation_ID: uuid,
      indication_ID: indication_ID,
      subpopulation_Name: null,
      kmCurveTypeModel: [...kmCurveTypeModel],
      KMdsc: kmdsc,
      KMmonth: kmValues,
    };

    // console.log(
    //   kmData_temp.filter((x: any) => x.indication_ID === indication_ID)[0].subpopulationModel
    // );
    if (
      kmData_temp.filter((x: any) => x.indication_ID === indication_ID)[0].subpopulationModel
        .length > 0
    ) {
      kmData_temp
        .filter((x: any) => x.indication_ID === indication_ID)[0]
        .subpopulationModel.push(formatForNew);
    } else {
      kmData_temp.filter((x: any) => x.indication_ID === indication_ID)[0].subpopulationModel = [
        { ...formatForNew },
      ];
    }

    // setKMData([...kmData_temp]);
    setOutputData([...kmData_temp]);
  };

  const handleNext = () => {
    context.setCurrent(context.current + 1);
    if (context.current === 2) {
      context.setSubmit1(true);
    }
  };

  const prev = () => {
    context.setCurrent(context.current - 1);
  };

  const saveButton = (
    <Button className="tabs-extra-demo-button" type="primary" htmlType="submit">
      Submit
    </Button>
    // <>
    //   <Button onClick={() => prev()}>Previous</Button>
    //   <Button className="ml-4" type="primary" htmlType="submit" onClick={handleNext}>
    //     Next
    //   </Button>
    // </>
  );

  const [KMCurve1, setKMCurve1] = useState("");
  const [KMCurve2, setKMCurve2] = useState("");
  const [KMCurve3, setKMCurve3] = useState("");

  const handleInputChange3 = (indication_ID: any, uuid: any, event: any) => {
    // console.log(kmData);
    let values: any = [];
    if (outputData !== undefined) {
      values = [...outputData];
    }

    // console.log(indication_ID, uuid, event, values);
    for (let counter = 1; counter <= 3; counter++) {
      if (event.target.name === uuid + "_date_" + (counter - 1)) {
        values
          .filter((ind: any) => ind.indication_ID === indication_ID)[0]
          .subpopulationModel.filter((s: any) => s.subpopulation_ID === uuid)[0].kmCurveTypeModel[
          counter - 1
        ].kMtype_SubpopulationModel[0].date = event.target.value;

        values
          .filter((ind: any) => ind.indication_ID === indication_ID)[0]
          .subpopulationModel.filter((s: any) => s.subpopulation_ID === uuid)[0].KMdsc[
          counter - 1
        ].date = event.target.value;
      }

      if (event.target.name === uuid + "_source_" + (counter - 1)) {
        values
          .filter((ind: any) => ind.indication_ID === indication_ID)[0]
          .subpopulationModel.filter((s: any) => s.subpopulation_ID === uuid)[0].kmCurveTypeModel[
          counter - 1
        ].kMtype_SubpopulationModel[0].source = event.target.value;

        values
          .filter((ind: any) => ind.indication_ID === indication_ID)[0]
          .subpopulationModel.filter((s: any) => s.subpopulation_ID === uuid)[0].KMdsc[
          counter - 1
        ].source = event.target.value;
      }

      if (event.target.name === uuid + "_comments_" + (counter - 1)) {
        values
          .filter((ind: any) => ind.indication_ID === indication_ID)[0]
          .subpopulationModel.filter((s: any) => s.subpopulation_ID === uuid)[0].kmCurveTypeModel[
          counter - 1
        ].kMtype_SubpopulationModel[0].comments = event.target.value;

        values
          .filter((ind: any) => ind.indication_ID === indication_ID)[0]
          .subpopulationModel.filter((s: any) => s.subpopulation_ID === uuid)[0].KMdsc[
          counter - 1
        ].comments = event.target.value;
      }
    }
    // console.log(values);

    setOutputData(values);

    // outputDataValueSet(values);
  };

  const deleteRow = (indicationID: any, subpopulationID: any) => {
    let index_ind: number = kmData.findIndex((ind: any) => ind.indication_ID === indicationID);
    let index_sub: number = kmData[index_ind].subpopulationModel.findIndex(
      (sub: any) => sub.subpopulation_ID === subpopulationID
    );

    kmData[index_ind].subpopulationModel.splice(index_sub, 1);
    setOutputData(kmData);

    console.log(indicationID, subpopulationID, kmData, index_sub, outputData);
  };

  return (
    <div className="mt-8 ml-4 mr-4">
      {outputData && (
        <Form name="item_form" onFinish={onFinish} layout={"vertical"}>
          {console.log(outputData[0].isKMcurve)}
          {outputData[0].isKMcurve ? (
            <>
              <Tabs type="card" className="card-tabs" tabBarExtraContent={saveButton}>
                {outputData.map((x: any, indx: any) => {
                  return (
                    <TabPane
                      forceRender={true}
                      tab={<p className="uppercase font-semibold">{x.indication_Name}</p>}
                      key={x.indication_ID}
                    >
                      {/* {console.log(x)} */}
                      <Row>
                        <Col span={6} style={{ marginTop: "2rem" }}>
                          <Form.Item
                            name={x.indication_ID + "_type1"}
                            label="KM Curve Type 1"
                            // rules={[{ required: true }]}
                            key={x.indication_ID + "_type1"}
                          >
                            <Input
                              name={x.indication_ID + "_type1"}
                              defaultValue={x.kMcurveTypeModel[0].curveType}
                              onChange={(e) => {
                                handleInputChange(x, e);
                                // changeKMCurveName(0, e.target.value);
                              }}
                              autoComplete="none"
                            ></Input>
                          </Form.Item>
                        </Col>
                        <Col span={6} style={{ marginTop: "2rem" }}>
                          <Form.Item
                            key={x.indication_ID + "_type2"}
                            name={x.indication_ID + "_type2"}
                            label="KM Curve Type 2"
                          >
                            {/* {console.log(x.kMcurveTypeModel[2].curveType)} */}

                            <Input
                              name={x.indication_ID + "_type2"}
                              defaultValue={x.kMcurveTypeModel[1].curveType}
                              onChange={(e) => {
                                handleInputChange(x, e);
                                // changeKMCurveName(1, e.target.value);
                              }}
                            ></Input>
                          </Form.Item>
                        </Col>
                        <Col span={6} style={{ marginTop: "2rem" }}>
                          <Form.Item
                            key={x.indication_ID + "_type3"}
                            name={x.indication_ID + "_type3"}
                            label="KM Curve Type 3"
                          >
                            {/* {console.log(x.kMcurveTypeModel[2].curveType)} */}

                            <Input
                              name={x.indication_ID + "_type3"}
                              defaultValue={x.kMcurveTypeModel[2].curveType}
                              onChange={(e) => {
                                handleInputChange(x, e);
                                // changeKMCurveName(2, e.target.value);
                              }}
                            ></Input>
                          </Form.Item>
                        </Col>
                      </Row>
                      <div>
                        {x.subpopulationModel.map((sub: any) => (
                          <div>
                            <Collapse
                              collapsible="header"
                              style={{ width: "74.5rem" }}
                              key={sub.subpopulation_ID}
                            >
                              <Panel
                                style={{ width: "74rem", border: "10px black" }}
                                header={
                                  <p style={{ minWidth: "11rem" }} className="font-bold ">
                                    {"Patient subpopulation"}
                                  </p>
                                }
                                key={sub.subpopulation_ID}
                                // extra={genExtra()}
                                extra={
                                  <div style={{ width: "60rem" }}>
                                    <Form.Item
                                      style={{ float: "left" }}
                                      name={"subpopulationName_" + sub.subpopulation_ID}
                                      initialValue={sub.subpopulation_Name}
                                    >
                                      {/* {console.log(sub)} */}
                                      <Input
                                        name={"subpopulationName_" + sub.subpopulation_ID}
                                        onChange={(e) => {
                                          handleInputChange2(x, sub, e);
                                        }}
                                        defaultValue={sub.subpopulation_Name}
                                        style={{
                                          width: "15rem",
                                          marginTop: "-3px",
                                          marginLeft: "-1rem",
                                        }}
                                        placeholder="Subpopulation Name"
                                      />
                                    </Form.Item>
                                    {console.log(sub)}
                                    <Popconfirm
                                      placement="topLeft"
                                      title={"Confirm Delete?"}
                                      onConfirm={() => {
                                        // confirm(inputField.indication_ID);
                                        deleteRow(sub.indication_ID, sub.subpopulation_ID);
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Bin2
                                        color="black"
                                        size={15}
                                        style={{
                                          marginTop: "8px",
                                          marginLeft: "2px",
                                          marginRight: "0.5rem",
                                          float: "right",
                                        }}
                                        onClick={function (event) {
                                          // remove(name);
                                        }}
                                      />
                                    </Popconfirm>
                                  </div>
                                }
                              >
                                {
                                  <div style={{ padding: "2rem" }}>
                                    <p className="float-left font-semibold text-sm  mr-8">
                                      Enter source for the KM curves:
                                    </p>
                                    {/* {console.log(sub.KMdsc)} */}
                                    <Table
                                      key={Math.random()}
                                      className=""
                                      rowClassName={(record, index) =>
                                        index % 2 === 0 ? "table-row-dark" : "table-row-light"
                                      }
                                      size="small"
                                      rowKey="id"
                                      columns={[
                                        {
                                          dataIndex: "name",
                                          title: "KM Curve Type",
                                        },
                                        // {
                                        //   dataIndex: "date",
                                        //   title: "Date",
                                        // },
                                        {
                                          dataIndex: "source",
                                          title: "Source",
                                        },
                                        // {
                                        //   dataIndex: "comments",
                                        //   title: "Comments",
                                        // },
                                      ]}
                                      dataSource={[
                                        {
                                          name: sub.KMdsc[0].name,
                                          date: (
                                            <input
                                              type="date"
                                              name={sub.KMdsc[0].uuid + "_date_" + 0}
                                              onBlur={(event) =>
                                                handleInputChange3(
                                                  x.indication_ID,
                                                  sub.KMdsc[0].uuid,
                                                  event
                                                )
                                              }
                                              defaultValue={sub.KMdsc[0].date}
                                            />
                                          ),

                                          source: (
                                            <input
                                              name={sub.KMdsc[0].uuid + "_source_" + 0}
                                              style={{ width: "18rem", borderRadius: " 2px" }}
                                              onBlur={(event) =>
                                                handleInputChange3(
                                                  x.indication_ID,
                                                  sub.KMdsc[0].uuid,
                                                  event
                                                )
                                              }
                                              defaultValue={sub.KMdsc[0].source}
                                            ></input>
                                          ),

                                          comments: (
                                            <input
                                              name={sub.KMdsc[0].uuid + "_comments_" + 0}
                                              onBlur={(event) =>
                                                handleInputChange3(
                                                  x.indication_ID,
                                                  sub.KMdsc[0].uuid,
                                                  event
                                                )
                                              }
                                              defaultValue={sub.KMdsc[0].comments}
                                            ></input>
                                          ),
                                        },
                                        {
                                          name: sub.KMdsc[1].name,

                                          date: (
                                            <input
                                              type="date"
                                              name={sub.KMdsc[1].uuid + "_date_" + 1}
                                              onBlur={(event) =>
                                                handleInputChange3(
                                                  x.indication_ID,
                                                  sub.KMdsc[1].uuid,
                                                  event
                                                )
                                              }
                                              defaultValue={sub.KMdsc[1].date}
                                            />
                                          ),

                                          source: (
                                            <input
                                              name={sub.KMdsc[1].uuid + "_source_" + 1}
                                              style={{ width: "18rem", borderRadius: " 2px" }}
                                              onBlur={(event) =>
                                                handleInputChange3(
                                                  x.indication_ID,
                                                  sub.KMdsc[1].uuid,
                                                  event
                                                )
                                              }
                                              defaultValue={sub.KMdsc[1].source}
                                            ></input>
                                          ),

                                          comments: (
                                            <input
                                              name={sub.KMdsc[1].uuid + "_comments_" + 1}
                                              onBlur={(event) =>
                                                handleInputChange3(
                                                  x.indication_ID,
                                                  sub.KMdsc[1].uuid,
                                                  event
                                                )
                                              }
                                              defaultValue={sub.KMdsc[1].comments}
                                            ></input>
                                          ),
                                        },

                                        {
                                          name: sub.KMdsc[2].name,

                                          date: (
                                            <input
                                              type="date"
                                              name={sub.KMdsc[2].uuid + "_date_" + 2}
                                              onBlur={(event) =>
                                                handleInputChange3(
                                                  x.indication_ID,
                                                  sub.KMdsc[2].uuid,
                                                  event
                                                )
                                              }
                                              defaultValue={sub.KMdsc[2].date}
                                            />
                                          ),

                                          source: (
                                            <input
                                              name={sub.KMdsc[2].uuid + "_source_" + 2}
                                              style={{ width: "18rem", borderRadius: " 2px" }}
                                              onBlur={(event) =>
                                                handleInputChange3(
                                                  x.indication_ID,
                                                  sub.KMdsc[2].uuid,
                                                  event
                                                )
                                              }
                                              defaultValue={sub.KMdsc[2].source}
                                            ></input>
                                          ),

                                          comments: (
                                            <input
                                              name={sub.KMdsc[2].uuid + "_comments_" + 2}
                                              onBlur={(event) =>
                                                handleInputChange3(
                                                  x.indication_ID,
                                                  sub.KMdsc[2].uuid,
                                                  event
                                                )
                                              }
                                              defaultValue={sub.KMdsc[2].comments}
                                            ></input>
                                          ),
                                        },
                                      ]}
                                      pagination={false}
                                    />

                                    <p className="float-left font-semibold text-sm mr-8 mt-16">
                                      Enter values for KM Curve corresponding to each month index:
                                    </p>

                                    <KmCurveTables
                                      handleInputChange={handleExcelChange}
                                      data={sub.KMmonth}
                                      kmData={outputData}
                                      indx={indx}
                                      indication={x}
                                      subpop={sub}
                                      KMCurve1={KMCurve1}
                                      KMCurve2={KMCurve2}
                                      KMCurve3={KMCurve3}
                                    />
                                  </div>
                                }
                              </Panel>
                            </Collapse>
                          </div>
                        ))}
                      </div>

                      <Form.Item>
                        <Button
                          type="text"
                          className="mt-8"
                          onClick={function () {
                            addNewSubpop(x.indication_ID);
                          }}
                          block
                          icon={<AddCircle className="mr-1" size={30} color={"#27a6a4"} />}
                        >
                          <span className="font-semibold">Add New Subpopulation</span>
                        </Button>
                      </Form.Item>
                    </TabPane>
                  );
                })}
              </Tabs>
            </>
          ) : (
            <>
              <Tabs type="card" className="card-tabs" tabBarExtraContent={saveButton}>
                {outputData.map((x: any, indx: any) => {
                  return (
                    <TabPane
                      forceRender={true}
                      tab={<p className="uppercase font-semibold">{x.indication_Name}</p>}
                      key={x.indication_ID}
                    >
                      <div>
                        {x.subpopulationModel.map((sub: any, index: any) => (
                          <div>
                            <Collapse
                              collapsible="header"
                              style={{ width: "74.5rem" }}
                              key={sub.subpopulation_ID}
                            >
                              <Panel
                                showArrow={false}
                                style={{ width: "74rem", border: "10px black" }}
                                header={
                                  <p style={{ minWidth: "11rem" }} className="font-bold ">
                                    {"Patient subpopulation #" + ++index}
                                  </p>
                                }
                                key={sub.subpopulation_ID}
                                // extra={genExtra()}
                                extra={
                                  <div style={{ width: "60rem" }}>
                                    <Form.Item
                                      style={{ float: "left" }}
                                      name={"subpopulationName_" + sub.subpopulation_ID}
                                      initialValue={sub.subpopulation_Name}
                                    >
                                      {/* {console.log(sub)} */}
                                      <Input
                                        name={"subpopulationName_" + sub.subpopulation_ID}
                                        onChange={(e) => {
                                          handleInputChange2(x, sub, e);
                                        }}
                                        defaultValue={sub.subpopulation_Name}
                                        style={{
                                          width: "15rem",
                                          marginTop: "-3px",
                                          marginLeft: "-1rem",
                                        }}
                                        placeholder="Subpopulation Name"
                                      />
                                    </Form.Item>
                                    <Popconfirm
                                      placement="topLeft"
                                      title={"Confirm Delete?"}
                                      onConfirm={() => {
                                        // confirm(inputField.indication_ID);
                                        deleteRow(sub.indication_ID, sub.subpopulation_ID);
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Bin2
                                        color="black"
                                        size={15}
                                        style={{
                                          marginTop: "8px",
                                          marginLeft: "2px",
                                          marginRight: "0.5rem",
                                          float: "right",
                                        }}
                                        onClick={function (event) {
                                          // remove(name);
                                        }}
                                      />
                                    </Popconfirm>
                                  </div>
                                }
                              ></Panel>
                            </Collapse>
                          </div>
                        ))}
                      </div>

                      <Form.Item>
                        <Button
                          type="text"
                          className="mt-8"
                          onClick={function () {
                            addNewSubpop(x.indication_ID);
                          }}
                          block
                          icon={<AddCircle className="mr-1" size={30} color={"#27a6a4"} />}
                        >
                          <span className="font-semibold">Add New Subpopulation</span>
                        </Button>
                      </Form.Item>
                    </TabPane>
                  );
                })}
              </Tabs>
            </>
          )}
        </Form>
      )}
    </div>
  );
}
