import "./App.css";
// import AppContext, { ContextProvider } from "./store/ThemeContext";
import { BrowserRouter } from "react-router-dom";
import PageLayout from "./layout";
import { ContextProvider } from "./store";
function App() {
  return (
    <>
      <ContextProvider>
        <BrowserRouter>
          <PageLayout />
        </BrowserRouter>
      </ContextProvider>
    </>
  );
}

export default App;
