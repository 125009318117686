import { Col, Divider, Row, List, Typography, Form, Button, Modal } from "antd";
import React from "react";
import { Select } from "antd";
import KmCurveViz from "./kmCurveViz";
import AppContext from "../../../store";
import AppContext2 from "../../../store/index2";
import { getKmCurveDetails, saveKmCurveDetails } from "./service-helper";
import background from "../../../globalTheme/background2.jpg";
import "../KmSelection/index.css";
const data = [
  {
    title: "Title 1",
    kmCurves: ["km1", "km2", "km3"],
    date: "21/5/22",
    source: "source",
    comments: "comments",
  },
  {
    title: "Title 2",
    kmCurves: ["km1", "km2", "km3"],
    date: "21/5/22",
    source: "source",
    comments: "comments",
  },
  {
    title: "Title 3",
    kmCurves: ["km1", "km2", "km3"],
    date: "21/5/22",
    source: "source",
    comments: "comments",
  },
  {
    title: "Title 4",
    kmCurves: ["km1", "km2", "km3"],
    date: "21/5/22",
    source: "source",
    comments: "comments",
  },
];

let dataBody: any = [
  {
    indicationName: "ind1",
    subpopulation: [
      {
        subpopulationName: "sub1",
        kmCurves: ["km1", "km2", "km3"],
        date: "21/5/22",
        source: "source",
        comments: "comments",
      },
      {
        subpopulationName: "sub2",
        kmCurves: ["km1", "km2", "km3"],
        date: "21/5/22",
        source: "source",
        comments: "comments",
      },
      {
        subpopulationName: "sub3",
        kmCurves: ["km1", "km2", "km3"],
        date: "21/5/22",
        source: "source",
        comments: "comments",
      },
    ],
  },
  {
    indicationName: "ind1",
    subpopulation: [
      {
        subpopulationName: "sub1",
        kmCurves: ["km1", "km2", "km3"],
        date: "21/5/22",
        source: "source",
        comments: "comments",
      },
      {
        subpopulationName: "sub1",
        kmCurves: ["km1", "km2", "km3"],
        date: "21/5/22",
        source: "source",
        comments: "comments",
      },
      {
        subpopulationName: "sub1",
        kmCurves: ["km1", "km2", "km3"],
        date: "21/5/22",
        source: "source",
        comments: "comments",
      },
    ],
  },
];

let finalDataForSave: any = [];

export default function KmSelection() {
  const context = React.useContext(AppContext);
  const { Option } = Select;
  const [indication, setIndication] = React.useState<any>(null);
  const [subpopulation, setSubpopulation] = React.useState<any>([]);
  const [kmcurves, setKmcurves] = React.useState([]);
  const [valueFinal, setValueFinal] = React.useState<any>();
  const context2 = React.useContext(AppContext2);
  const [dummyData, setDummyData] = React.useState<any>();
  const [dataForSaving, setDataForSaving] = React.useState<any>([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const FetchData = async () => {
    // call api for getting values here
    console.log(context.contractId);

    getKmCurveDetails(context.contractId).then((val: any) => {
      val.map((d: any) => {
        d.kMcurveSelectionSubpopulationModel.map((kmc: any) => {
          kmc.sourceAlter = kmc.source;
        });
      });
      setDummyData(val);
      console.log(val);
    });
  };

  React.useEffect(() => {
    FetchData();
  }, []);

  React.useEffect(() => {}, [dummyData]);

  React.useEffect(() => {
    if (dummyData && finalDataForSave.length === 0) {
      dummyData.map((i: any) => {
        console.log(dummyData);
        i.kMcurveSelectionSubpopulationModel.map((km: any) => {
          let objTemp: any = {};
          objTemp.id = null;
          objTemp.contractIndication_ID = i.contractIndication_ID;
          objTemp.subpopulation_ID = km.subpopulation_ID;
          objTemp.selectedKMcurveSubpopulation_ID = null;
          finalDataForSave.push(objTemp);
        });
        console.log("Array without selected KM ID", finalDataForSave);
        setDataForSaving(finalDataForSave);
      });
    }
  }, [dummyData]);

  // const onIndicationChange = () => {};

  const [kmId, setKmId] = React.useState();
  const [subId, setSubId] = React.useState();

  function getValuesForSource(x: any, subpopulationId: any, selectedKmId: any) {
    console.log(x, subpopulationId, selectedKmId);
    if (selectedKmId && x.subpopulation_ID === subpopulationId) {
      x.kMcurveTypeModel.map((kmctm: any) => {
        if (kmctm.kMtype_SubpopulationModel[0].id === selectedKmId) {
          console.log(kmctm.kMtype_SubpopulationModel[0].source);
          return kmctm.kMtype_SubpopulationModel[0].source;
        }
      });
    }
  }

  const onKMChange = (
    selectedKmId?: any,
    contractIndId?: any,
    subpopulationId?: any,
    id?: any,
    indName?: any,
    subName?: any
  ) => {
    console.log("NAMES HERE", indName, subName);
    setKmId(selectedKmId);
    setSubId(subpopulationId);
    dummyData
      .filter((i: any) => i.indication_Name === indName)
      .map((ind: any) => {
        ind.kMcurveSelectionSubpopulationModel
          .filter((s: any) => s.subpopulation_Name === subName)
          .map((sub: any) => {
            sub.kMcurveTypeModel.map((km: any) => {
              if (km.curveType === selectedKmId) {
                console.log("KM CURVE TYPE HERE", km.curveType);
                let arrTemp: any = [];
                let objForData: any = {};
                objForData.id = km.curveType;
                objForData.color = "hsl(37, 70%, 50%)";
              }
            });
          });
      });

    console.log(finalDataForSave);
    let dataDummyForSave: any = [...finalDataForSave];

    dataDummyForSave.map((val: any) => {
      if (val.subpopulation_ID === subpopulationId && val.contractIndication_ID === contractIndId) {
        val.selectedKMcurveSubpopulation_ID = selectedKmId;
        console.log("INSIDE Value Change ");

        dummyData.map((d: any) => {
          if (d.contractIndication_ID === val.contractIndication_ID) {
            d.kMcurveSelectionSubpopulationModel.map((kms: any) => {
              if (kms.subpopulation_ID === val.subpopulation_ID) {
                console.log("INSIDE ID CHANGE ");
                val.id = kms.id;
              }
            });
          }
        });
      }
    });

    // for changing value of Source
    dummyData
      .filter((d: any) => d.contractIndication_ID === contractIndId)[0]
      .kMcurveSelectionSubpopulationModel.filter(
        (kms: any) => subpopulationId === kms.subpopulation_ID
      )[0]
      .kMcurveTypeModel.map((kmctm: any) => {
        if (kmctm.kMtype_SubpopulationModel[0].id === selectedKmId) {
          dummyData
            .filter((d: any) => d.contractIndication_ID === contractIndId)[0]
            .kMcurveSelectionSubpopulationModel.filter(
              (kms: any) => subpopulationId === kms.subpopulation_ID
            )[0].sourceAlter = kmctm.kMtype_SubpopulationModel[0].source;
        }
      });

    console.log(dummyData);
    setDummyData(dummyData);
    finalDataForSave = [...dataDummyForSave];
    // console.log(finalDataForSave, dataDummyForSave);
    setValueFinal(dataForSaving);
  };

  const onChange = (selectedKmId?: any, contractIndId?: any, subpopulationId?: any) => {
    setIndication(selectedKmId);
    let arrForSubList: any = [];
    dummyData
      .filter((d: any) => d.indication_Name === selectedKmId)
      .map((data: any) => {
        data.kMcurveSelectionSubpopulationModel.map((s: any) => {
          arrForSubList.push(s.subpopulation_Name);
        });
      });

    setSubpopulation(arrForSubList);
    setKmcurves([]);
  };

  const onSubpopChange = (value: string) => {
    let arrForKMList: any = [];

    dummyData
      .filter((d: any) => d.indication_Name === indication)
      .map((data: any) => {
        data.kMcurveSelectionSubpopulationModel
          .filter((s: any) => s.subpopulation_Name === value)
          .map((sub: any) => {
            sub.kMcurveTypeModel.map((km: any) => {
              arrForKMList.push(km.curveType);
            });
          });
      });

    setKmcurves(arrForKMList);
  };

  const onSearch = (value: string) => {
    //console.log("search:", value);
  };

  const [dataForChart, setDataForChart] = React.useState<any>();
  const [isChart, setIsChart] = React.useState<boolean>(false);
  const onFinish = (
    indName: any,
    subName: any,
    changedSelect: any,
    defaultSelect: any,
    index1: any,
    index2: any
  ) => {
    console.log(changedSelect, index1, index2, defaultSelect);
    setIsChart(true);
    let dataFinal: any = [];
    dummyData
      .filter((i: any) => i.indication_Name === indName)
      .map((ind: any) => {
        ind.kMcurveSelectionSubpopulationModel
          .filter((s: any) => s.subpopulation_Name === subName)
          .map((sub: any) => {
            sub.kMcurveTypeModel.map((km: any) => {
              if (
                changedSelect &&
                changedSelect[4 * index1 + index2].selectedKMcurveSubpopulation_ID !== null &&
                changedSelect[4 * index1 + index2].selectedKMcurveSubpopulation_ID ===
                  km.kMtype_SubpopulationModel[0].id
              ) {
                let objForData: any = {};
                objForData.id = km.curveType;
                objForData.color = "hsl(37, 70%, 50%)";
                let arrForData: any = [];

                km.kmCurveValuesModel.map((element: any) => {
                  let objForEntity: any = {};
                  objForEntity.x = element.monthIndex;
                  objForEntity.y = element.value;
                  arrForData.push(objForEntity);
                });
                arrForData.sort((a: any, b: any) => a.x - b.x);

                objForData.data = [...arrForData];
                dataFinal.push(objForData);
              } else if (
                (changedSelect === undefined ||
                  changedSelect[4 * index1 + index2].selectedKMcurveSubpopulation_ID === null) &&
                km.kMtype_SubpopulationModel[0].id === defaultSelect
              ) {
                let objForData: any = {};
                objForData.id = km.curveType;
                objForData.color = "hsl(37, 70%, 50%)";
                let arrForData: any = [];

                km.kmCurveValuesModel.map((element: any) => {
                  let objForEntity: any = {};
                  objForEntity.x = element.monthIndex;
                  objForEntity.y = element.value;
                  arrForData.push(objForEntity);
                });
                arrForData.sort((a: any, b: any) => a.x - b.x);

                objForData.data = [...arrForData];
                dataFinal.push(objForData);
              }
            });
          });
      });

    let arrFinal: any = [];
    dataFinal.map((d: any) => {
      if (d.data.length !== 0) {
        arrFinal.push(d);
      }
    });

    console.log(arrFinal);
    setDataForChart(arrFinal);
    setIsModalVisible(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const goPrevious = () => {
    context.setCurrent(context.current - 1);
  };

  const [form] = Form.useForm();

  const onNext = () => {
    console.log(dataForSaving);
    context.setCurrent(context.current + 1);
    saveKmCurveDetails(finalDataForSave);
  };

  const ModalContent = () => {
    return <div></div>;
  };

  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <div className="ml-8 mt-4 mr-8">
        <div className=" text-xl font-bold float-left"> KM Curve Selection</div>
        <br></br>
        <br></br>
        <div className="text-sm font-normal float-left text-[#75737D]">
          Mapping for subpopulations with KM curves
        </div>
        <br></br>
        <br></br>
        <div>
          <Row
            style={{
              backgroundColor: "#27a6a4",
              border: "solid 2px #27a6a4",
              borderRadius: "4px",
            }}
          >
            <Col span={4}>
              <p
                style={{
                  color: "whitesmoke",
                }}
                className="float-left mt-2 font-bold text-center px-2 ml-4"
              >
                Indication{" "}
              </p>
            </Col>
            <Col span={8}>
              <p
                style={{
                  color: "whitesmoke",
                }}
                className="float-left mt-2 font-bold text-center px-2 -ml-14 mt-2"
              >
                Sub Population
              </p>
            </Col>
            <Col span={8}>
              <p
                style={{
                  color: "whitesmoke",
                }}
                className="float-left mt-2 font-bold text-center px-2 -ml-36	mt-2"
              >
                KM Curve{" "}
              </p>
            </Col>

            <Col span={4}>
              <p
                style={{
                  color: "whitesmoke",
                }}
                className="float-left mt-2 font-bold text-center px-2 -ml-20	mt-2"
              >
                Source{" "}
              </p>
            </Col>
          </Row>
          <Divider style={{ backgroundColor: "#9C9AA1" }} className="mt-0" />

          <Modal
            title="KM Curve Graph"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1000}
          >
            <div style={{ height: 500 }}>{dataForChart && <KmCurveViz data={dataForChart} />}</div>
          </Modal>

          <List
            bordered={false}
            dataSource={dummyData}
            renderItem={(item: any, index1: any) => (
              <List.Item
                id={item.indication_ID}
                // style={{ marginTop: "2rem", marginBottom: "3rem" }}
              >
                <Row>
                  <span
                    className="font-bold text-base"
                    style={{
                      marginLeft: "1rem",
                      marginRight: "1rem",
                      maxWidth: "0rem",
                      marginTop:
                        item.kMcurveSelectionSubpopulationModel.length === 4
                          ? "5rem"
                          : item.kMcurveSelectionSubpopulationModel.length === 2
                          ? "2.5rem"
                          : "0rem",
                    }}
                  >
                    <Col className="ml-4" span={4}>
                      {item.indication_Name}
                    </Col>
                  </span>
                  {/* <Col span={4}></Col> */}

                  {/* <p className="float-left font-semibold text-xs"> */}
                  <List
                    bordered={false}
                    style={{ width: "52rem" }}
                    dataSource={item.kMcurveSelectionSubpopulationModel}
                    renderItem={(x: any, index2: any) => (
                      <List.Item
                        id={x.subpopulation_ID}
                        style={{
                          width: "110%",
                          marginLeft: "7rem",
                          // marginTop: "-2.5rem",
                          marginBottom:
                            index2 + 1 < item.kMcurveSelectionSubpopulationModel.length
                              ? "0rem"
                              : "0rem",
                        }}
                      >
                        <Col className="ml-4" span={4}>
                          {x.subpopulation_Name}
                        </Col>
                        <Col style={{ marginLeft: "2rem" }} span={8}>
                          <Select
                            defaultValue={x.selectedKMcurveSubpopulation_ID}
                            showSearch
                            placeholder="Select KM Curve Type"
                            optionFilterProp="children"
                            // style={{ minWidth: "10rem" }}
                            onChange={(e: any) =>
                              onKMChange(
                                e,
                                item.contractIndication_ID,
                                x.subpopulation_ID,
                                item.id,
                                item.indication_Name,
                                x.subpopulation_Name
                              )
                            }
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option!.children as unknown as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {x.kMcurveTypeModel.map((options: any) => (
                              <Option value={options.kMtype_SubpopulationModel[0].id}>
                                {options.curveType}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        {console.log(finalDataForSave)}
                        <Button
                          type="primary"
                          onClick={() =>
                            onFinish(
                              item.indication_Name,
                              x.subpopulation_Name,
                              valueFinal,
                              x.selectedKMcurveSubpopulation_ID,
                              index1,
                              index2
                            )
                          }
                        >
                          Open KM Graph
                        </Button>
                        <Col span={8} style={{ marginLeft: "1rem" }}>
                          {x.sourceAlter}
                        </Col>
                      </List.Item>
                    )}
                  />
                  {/* </p> */}
                  {/* </Col> */}
                </Row>
              </List.Item>
            )}
          />
        </div>
        <br></br>
        <br></br>
        <div style={{ marginTop: "1rem", paddingBottom: "1rem" }}>
          <Button className=" float-left" onClick={goPrevious}>
            Previous
          </Button>
          <Form form={form} name="pricingTab">
            <Form.Item>
              <Button type="primary" style={{ width: "5rem" }} onClick={onNext} className="-ml-28">
                Next
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
