import { useContext } from "react";
import { request } from "../layout";
import AppContext from "../store";

export const getAllContractsListData = async () => {
  // let context = useContext(AppContext);

  const fetchUrl = "https://api.icp.dev.zsservices.com/ContractInputs/ContractList";
  const sessionItem: any = window.sessionStorage.getItem("login");
  console.log("Session", sessionItem);
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  console.log("LOGIN DETAILS: ", loginDetails);

  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};

export const saveSimulatorConfigData = async (value: any) => {
  // let context = useContext(AppContext);

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;

  const fetchUrl = "https://api.icp.dev.zsservices.com/AppManagement/SaveSubpopulationData";

  const data = await request
    .post(fetchUrl, value, {
      headers: {
        "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
        "Content-Type": "application/json",
        "user-details": loginDetails,
      },
    })
    .then((response) => {
      console.log("Response...", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};

// For new contract
export const getDetailsForContractData = async (productId: any) => {
  // let context = useContext(AppContext);

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/ContractInputs/NewlyAddedContractDetails?productId=" +
    productId;
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

// For editing contract
export const getDetailsForContractDataOnEdit = async (contractId: any) => {
  // let context = useContext(AppContext);

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/ContractInputs/ContractDetails?contractId=" + contractId;

  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const getNewContractDetailsData = async () => {
  // let context = useContext(AppContext);

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const fetchUrl = "https://api.icp.dev.zsservices.com/ContractInputs/NewlyAddedContractDetails";
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const saveContractDetailsData = async (value: any) => {
  // let context = useContext(AppContext);

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const fetchUrl = "https://api.icp.dev.zsservices.com/ContractInputs/SaveContractDetails";

  const data = await request
    .post(fetchUrl, value, {
      headers: {
        "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
        "Content-Type": "application/json",
        "user-details": loginDetails,
        // "user-details": context.userInfo,
      },
    })
    .then((response) => {
      console.log("Response...", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};

export const getEpidemiologyDetailsData = async (contractId: any) => {
  // let context = useContext(AppContext);

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/ContractInputs/ContractEpidemiologyDetails?contractId=" +
    contractId;
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const saveEpidemiologyDetailsData = async (value: any) => {
  // let context = useContext(AppContext);

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/ContractInputs/SaveContractEpidemiologyDetails";

  const data = await request
    .post(fetchUrl, value, {
      headers: {
        "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
        "Content-Type": "application/json",
        "user-details": loginDetails,
        // "user-details": context.userInfo,
      },
    })
    .then((response) => {
      console.log("Response...", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};

export const getKmCurveDetailsData = async (contractId: any) => {
  // let context = useContext(AppContext);

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/ContractInputs/KMcurveSelectionDetails?contractId=" +
    contractId;
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const getPricingDetailsData = async (contractId: any) => {
  // let context = useContext(AppContext);

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/ContractInputs/PricingDetails?contractId=" + contractId;
  const data = await fetch(fetchUrl, {
    method: "get",
    headers: {
      "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
      "Content-Type": "application/json",
      "user-details": loginDetails,
      // "user-details": context.userInfo,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  // console.log("DAta from backend", data);

  return data;
};

export const deleteContractDetailsData = async (contractId: any) => {
  // let context = useContext(AppContext);

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/ContractInputs/DeleteContractDetails?contractId=" +
    contractId;

  const data = await request
    .post(fetchUrl, "", {
      headers: {
        "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
        "Content-Type": "application/json",
        "user-details": loginDetails,
        // "user-details": context.userInfo,
      },
    })
    .then((response) => {
      console.log("Response...", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};

export const saveKmCurveDetailsData = async (value: any) => {
  // let context = useContext(AppContext);

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const fetchUrl = "https://api.icp.dev.zsservices.com/ContractInputs/SaveKMcurveSelection";
  const data = await request
    .post(fetchUrl, value, {
      headers: {
        "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
        "Content-Type": "application/json",
        "user-details": loginDetails,
        // "user-details": context.userInfo,
      },
    })
    .then((response) => {
      console.log("Response...", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};

export const savePricingDetailsData = async (value: any) => {
  // let context = useContext(AppContext);

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const fetchUrl = "https://api.icp.dev.zsservices.com/ContractInputs/SavePricingDetails";
  const data = await request
    .post(fetchUrl, value, {
      headers: {
        "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
        "Content-Type": "application/json",
        "user-details": loginDetails,
        // "user-details": context.userInfo,
      },
    })
    .then((response) => {
      console.log("Response...", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};

export const CopyContractDetailsData = async (contractId: any, contractName: any) => {
  const fetchUrl =
    "https://api.icp.dev.zsservices.com/ContractInputs/CopyContractDetails?contractId=" +
    contractId +
    "&contractName=" +
    contractName;

  const sessionItem: any = window.sessionStorage.getItem("login");
  const loginDetails = sessionItem ? JSON.parse(sessionItem) : null;
  const data = await request
    .post(fetchUrl, "", {
      headers: {
        "x-api-key": "217e7a0a-4e05-45db-a132-09daa8d9875e",
        "Content-Type": "application/json",
        "user-details": loginDetails,
        // "user-details": context.userInfo,
      },
    })
    .then((response) => {
      console.log("Response...", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });

  return data;
};
