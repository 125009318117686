import { Button, Card, Col, Collapse, Divider, Form, Row, Select, Statistic, Tree } from "antd";
import React from "react";
import StepsLayout from "../../layout/body-layout/steps";
import { DollarCircle } from "@styled-icons/boxicons-regular/DollarCircle";
import { ArrowDown } from "@styled-icons/bootstrap/ArrowDown";
import { ChartBar } from "@styled-icons/fa-regular/ChartBar";
import { Download } from "@styled-icons/entypo/Download";
import background from "../../globalTheme/background2.png";
import { FactCheck } from "@styled-icons/material/FactCheck";
import ContractRevenueChart from "./contractRevenueChart";
import { useLocation } from "react-router";
export default function ContractPerformance() {
  let startYear: number = 2022;
  let endYear: number = 2027;
  let arrForYears: any = [];
  for (let year = startYear; year <= endYear; year++) {
    arrForYears.push(year);
  }

  const { Option } = Select;
  const { Panel } = Collapse;

  let loc: any = useLocation();
  let contract = loc.state;

  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      {/* <StepsLayout subTabs={subTabs} /> */}
      <div className="pl-4 pr-12 pt-3">
        <div style={{ overflowY: "auto", height: "auto", width: "104%" }}>
          <div
            style={{
              paddingRight: "1rem",
              marginTop: "-1rem",
              minHeight: "60rem",
            }}
          >
            {/* <div className="text-xl font-bold mt-8 ml-8 float-left">
                Contract Performance Summary
              </div>
              <div className="text-base mt-8 ml-8 float-left">
                Output summary of overall contract performance
              </div> */}
            <div className="text-xl font-bold float-left mt-8 ml-8">
              Contract Performance Summary
              <div className="text-sm font-normal text-[#75737D] mt-2">
                Output summary of overall contract performance
              </div>
            </div>

            <div className="pt-4 pr-8">
              <Row
                className="border-2 border-sky-500 ml-96 mt-3"
                style={{ width: "63%", marginLeft: "28rem" }}
              >
                <Col className="h-12 ml-4" span={24}>
                  <span className="text-sm">
                    Contract Name: <span className="font-semibold"> {contract.name} </span>
                  </span>
                  <span className="ml-4 text-sm">
                    Contract Period: <span className="font-semibold"> Dec 2024 - 2027 </span>
                  </span>
                  <span className="ml-4 text-sm">
                    Follow-up Period: <span className="font-semibold"> 36 Months </span>
                  </span>
                  {/* Follow-u p Period: <span className="font-semibold"> asdad </span> */}
                </Col>
                <Col className="mb-2 -mt-4 " span={24}>
                  <span className="ml-4 text-sm">
                    Constructs Used:
                    <span className="font-semibold"> Value Warranty, Cost Capitation </span>
                  </span>
                </Col>
              </Row>
            </div>
            {/* <Button
              // shape="round"
              className="mt-9 mr-12 float-right"
              style={{ marginTop: "2.5rem" }}
              size="small"
              type="primary"
              // icon={<DownloadOutlined style={{ fontSize: "16px" }} />}
              icon={<Download size={15} style={{ marginRight: "6px", marginBottom: "6px" }} />}
            >
              Download Report
            </Button> */}
            <div
              className="border-2 border-sky-500 h-10 bg-slate-300 mt-8 ml-8 mr-8"
              style={{ background: "#F4F3F3", borderColor: "#F4F3F3" }}
            >
              <div className="mt-2 font-bold mt-12 flex justify-center text-sm	">
                KPIs (Over contract period)
              </div>
            </div>

            <div className="site-card-wrapper mt-19 ml-8 mr-8 mt-8">
              <Row gutter={16}>
                <Col span={6}>
                  <Card bordered={true}>
                    <Statistic
                      title={<p className="flex justify-center">Total Gross Revenue</p>}
                      value={"$11.28"}
                      precision={2}
                      valueStyle={{ color: "gray", display: "flex", justifyContent: "center" }}
                      prefix={
                        <DollarCircle
                          size={60}
                          style={{ color: "#27a6a4", marginLeft: "-6rem", marginTop: "-2rem" }}
                        />
                      }
                      suffix=""
                    />
                  </Card>
                </Col>

                <Col span={6}>
                  <Card bordered={true}>
                    <Statistic
                      title={<p className="flex justify-center">Total Deductions</p>}
                      value={"$4.52"}
                      precision={2}
                      valueStyle={{ color: "gray", display: "flex", justifyContent: "center" }}
                      prefix={
                        <ArrowDown
                          size={60}
                          style={{ color: "#27a6a4", marginLeft: "-6rem", marginTop: "-2rem" }}
                        />
                      }
                      suffix=""
                    />
                  </Card>
                </Col>

                <Col span={6}>
                  <Card bordered={true}>
                    <Statistic
                      title={<p className="flex justify-center">Total Net Revenue</p>}
                      value={"$20.2"}
                      precision={2}
                      valueStyle={{ color: "gray", display: "flex", justifyContent: "center" }}
                      prefix={
                        <ChartBar
                          size={60}
                          style={{ color: "#27a6a4", marginLeft: "-6rem", marginTop: "-2rem" }}
                        />
                      }
                      suffix=""
                    />
                  </Card>
                </Col>

                <Col span={6}>
                  <Card bordered={true}>
                    <Statistic
                      title={<p className="flex justify-center">Average Net Price</p>}
                      value={"$30.3"}
                      precision={2}
                      valueStyle={{ color: "gray", display: "flex", justifyContent: "center" }}
                      prefix={
                        <FactCheck
                          size={60}
                          style={{ color: "#27a6a4", marginLeft: "-6rem", marginTop: "-2rem" }}
                        />
                      }
                      suffix=""
                    />
                  </Card>
                </Col>
              </Row>
            </div>

            <div className="text-xl font-bold float-left mt-8 ml-8">
              Detailed Contract Performance Outputs
              <div className="mt-8">
                <Form
                  name="chart"
                  labelCol={{ span: 4, offset: 1 }}
                  wrapperCol={{ span: 12 }}
                  initialValues={{ remember: true }}
                  // onFinish={onFinish}
                  layout="inline"
                  autoComplete="off"
                >
                  <Form.Item
                    style={{ paddingRight: "2rem", marginRight: "-3rem" }}
                    name="subpopulation"
                    rules={[{ required: true, message: "Please input constructs!" }]}
                  >
                    <div className="mb-2 font-normal">Construct</div>
                    <Select
                      style={{ width: "12rem" }}
                      showSearch
                      placeholder="Select construct"
                      optionFilterProp="children"
                    >
                      <Option>Value Warranty</Option>
                      <Option> Tiered Pricing</Option>
                      <Option>Cost Capitation</Option>
                      <Option>Duration Based Rebating</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{ paddingRight: "2rem", marginRight: "-3rem" }}
                    name="kmcurves"
                    rules={[{ required: true, message: "Please input indication!" }]}
                  >
                    <div className="mb-2 font-normal">Indication</div>

                    <Select
                      style={{ width: "12rem" }}
                      // mode="multiple"
                      showSearch
                      placeholder="Select indications"
                      optionFilterProp="children"
                      // onChange={onChange}
                      // onSearch={onSearch}
                      // filterOption={(input, option) =>
                      //   (option!.children as unknown as string)
                      //     .toLowerCase()
                      //     .includes(input.toLowerCase())
                      // }
                    >
                      {/* {kmcurves.map((km: any) => (
                    <Option value={km}> {km}</Option>
                  ))} */}
                      <Option>mBC</Option>
                      <Option>mGC</Option>
                      <Option>mNSCLC</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{ paddingRight: "2rem", marginRight: "-3rem" }}
                    name="kmcurves"
                    rules={[{ required: true, message: "Please input subpopulation!" }]}
                  >
                    <div className="mb-2 font-normal">Subpopulation</div>

                    <Select
                      style={{ width: "12rem" }}
                      // mode="multiple"
                      showSearch
                      placeholder="Select subpopulation"
                      optionFilterProp="children"
                      // onChange={onChange}
                      // onSearch={onSearch}
                      // filterOption={(input, option) =>
                      //   (option!.children as unknown as string)
                      //     .toLowerCase()
                      //     .includes(input.toLowerCase())
                      // }
                    >
                      {/* {kmcurves.map((km: any) => (
                    <Option value={km}> {km}</Option>
                  ))} */}
                      <Option>1L</Option>
                      <Option>2L</Option>
                      <Option>3L+</Option>
                      <Option>Overall (ITT)</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{ paddingRight: "2rem", marginRight: "-3rem" }}
                    name="kmcurves"
                    rules={[{ required: true, message: "Please input dosage form!" }]}
                  >
                    <div className="mb-2 font-normal">SKU</div>

                    <Select
                      style={{ width: "12rem" }}
                      // mode="multiple"
                      showSearch
                      placeholder="Select SKU"
                      optionFilterProp="children"
                      // onChange={onChange}
                      // onSearch={onSearch}
                      // filterOption={(input, option) =>
                      //   (option!.children as unknown as string)
                      //     .toLowerCase()
                      //     .includes(input.toLowerCase())
                      // }
                    >
                      {/* {kmcurves.map((km: any) => (
                    <Option value={km}> {km}</Option>
                  ))} */}
                      <Option>200mg/10ml</Option>
                      <Option>40mg/2ml</Option>
                    </Select>
                  </Form.Item>

                  {/* <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item> */}
                  <Form.Item
                    style={{ paddingRight: "2rem", marginRight: "-3rem" }}
                    // label="indication"
                    name="indication"
                    rules={[{ required: true, message: "Please input output metrics!" }]}
                  >
                    <Button
                      // shape="round"
                      className="mt-7 mr-12 float-right"
                      // style={{ marginTop: "2.5rem" }}
                      size="middle"
                      type="primary"
                      // icon={<DownloadOutlined style={{ fontSize: "16px" }} />}
                      icon={
                        <Download size={15} style={{ marginRight: "6px", marginBottom: "6px" }} />
                      }
                    >
                      Download Report
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
            <div
              className="border-2 border-sky-500 h-10 bg-slate-300 mt-48 ml-8 mr-8"
              style={{ background: "#F4F3F3", borderColor: "#F4F3F3" }}
            >
              <div className="mt-2 mb-4 font-bold mt-24 flex justify-center text-sm">
                Contract Level Outputs
              </div>
              <Row>
                <Col span={24}>
                  <div style={{ height: 500, background: "white" }}>
                    <p className="-mb-4 pt-12 font-bold" style={{ marginLeft: "35rem" }}>
                      Revenue
                    </p>
                    <ContractRevenueChart
                      anch={true}
                      legend="Revenue [in thousand]"
                      data={[
                        {
                          id: "Gross Revenue",
                          color: "hsl(120, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 1000,
                            },
                            {
                              x: "2023",
                              y: 1240,
                            },
                            {
                              x: "2024",
                              y: 2250,
                            },
                            {
                              x: "2025",
                              y: 3200,
                            },
                            {
                              x: "2026",
                              y: 3750,
                            },
                            {
                              x: "2027",
                              y: 4500,
                            },
                          ],
                        },
                        {
                          id: "Net Revenue",
                          color: "hsl(189, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 970,
                            },
                            {
                              x: "2023",
                              y: 850,
                            },
                            {
                              x: "2024",
                              y: 1970,
                            },
                            {
                              x: "2025",
                              y: 2850,
                            },
                            {
                              x: "2026",
                              y: 3500,
                            },
                            {
                              x: "2027",
                              y: 4320,
                            },
                          ],
                        },
                      ]}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <div style={{ height: 500, background: "white" }}>
                    <p className=" ml-56 font-bold">Pricing</p>

                    <ContractRevenueChart
                      anch={false}
                      legend="Price"
                      data={[
                        {
                          id: "Net Price",
                          color: "hsl(63, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 800,
                            },
                            {
                              x: "2023",
                              y: 1200,
                            },
                            {
                              x: "2024",
                              y: 1800,
                            },
                            {
                              x: "2025",
                              y: 2200,
                            },
                            {
                              x: "2026",
                              y: 2900,
                            },
                            {
                              x: "2027",
                              y: 3100,
                            },
                          ],
                        },
                        {
                          id: "Ind. Price",
                          color: "hsl(189, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 800,
                            },
                            {
                              x: "2023",
                              y: 860,
                            },
                            {
                              x: "2024",
                              y: 1200,
                            },
                            {
                              x: "2025",
                              y: 1300,
                            },
                            {
                              x: "2026",
                              y: 2000,
                            },
                            {
                              x: "2027",
                              y: 2500,
                            },
                          ],
                        },
                        {
                          id: "Benchmark Price",
                          color: "hsl(195, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 600,
                            },
                            {
                              x: "2023",
                              y: 800,
                            },
                            {
                              x: "2024",
                              y: 1200,
                            },
                            {
                              x: "2025",
                              y: 1400,
                            },
                            {
                              x: "2026",
                              y: 1500,
                            },
                            {
                              x: "2027",
                              y: 2000,
                            },
                          ],
                        },
                        {
                          id: "Gross Price",
                          color: "hsl(127, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 1000,
                            },
                            {
                              x: "2023",
                              y: 1500,
                            },
                            {
                              x: "2024",
                              y: 2000,
                            },
                            {
                              x: "2025",
                              y: 2500,
                            },
                            {
                              x: "2026",
                              y: 3000,
                            },
                            {
                              x: "2027",
                              y: 3500,
                            },
                          ],
                        },
                      ]}
                    />
                  </div>
                </Col>
                {/* <Col span={2}></Col> */}

                <Col span={12}>
                  <div style={{ height: 500, background: "white" }}>
                    <p className=" ml-56 font-bold">Patient Numbers</p>
                    <ContractRevenueChart
                      anch={false}
                      legend="Patient Numbers"
                      data={[
                        {
                          id: "mNSCLC",
                          color: "hsl(195, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 100,
                            },
                            {
                              x: "2023",
                              y: 120,
                            },
                            {
                              x: "2024",
                              y: 140,
                            },
                            {
                              x: "2025",
                              y: 150,
                            },
                            {
                              x: "2026",
                              y: 170,
                            },
                            {
                              x: "2027",
                              y: 250,
                            },
                          ],
                        },
                        {
                          id: "mBC",
                          color: "hsl(63, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 100,
                            },
                            {
                              x: "2023",
                              y: 120,
                            },
                            {
                              x: "2024",
                              y: 146,
                            },
                            {
                              x: "2025",
                              y: 150,
                            },
                            {
                              x: "2026",
                              y: 225,
                            },
                            {
                              x: "2027",
                              y: 420,
                            },
                          ],
                        },
                        {
                          id: "mGC",
                          color: "hsl(127, 70%, 50%)",
                          data: [
                            {
                              x: "2022",
                              y: 120,
                            },
                            {
                              x: "2023",
                              y: 140,
                            },
                            {
                              x: "2024",
                              y: 200,
                            },
                            {
                              x: "2025",
                              y: 230,
                            },
                            {
                              x: "2026",
                              y: 240,
                            },
                            {
                              x: "2027",
                              y: 280,
                            },
                          ],
                        },
                      ]}
                    />
                  </div>
                </Col>
              </Row>

              <div style={{ height: "52rem" }}>
                <div className="mb-2 mt-4">Time Period</div>
                <Select
                  style={{ width: "12rem" }}
                  showSearch
                  placeholder="Select Time Period"
                  optionFilterProp="children"
                >
                  <Option value="yearly"> Yearly</Option>
                  <Option value="monthly"> Monthly</Option>
                </Select>

                <Row className="mt-8">
                  <Col span={3}>
                    <p className="float-left font-semibold text-xs"> Metric </p>
                  </Col>
                  {/* <Col span={3}></Col> */}
                  {/* <Col span={4}>
                    <p className="float-left font-semibold text-xs ">KM Curve </p>
                  </Col> */}
                  {console.log(arrForYears)}
                  {/* <Col className=" flex justify-items-stretch" span={18 / arrForYears.length}> */}
                  {arrForYears.map((el: any) => (
                    <span className="text-xs font-semibold" style={{ marginLeft: "9rem" }}>
                      {el}{" "}
                    </span>
                  ))}
                  {/* </Col> */}
                </Row>

                <Divider style={{ backgroundColor: "#9C9AA1" }} className="mt-0" />
                <Row className="mb-4">
                  <Col span={3}>Gross Price</Col>
                  <Col span={21}>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "8.5rem", marginRight: "0rem" }}
                    >
                      {"$1,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.5rem", marginRight: "0.8rem" }}
                    >
                      {"$1,500"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.5rem", marginRight: "0.5rem" }}
                    >
                      {"$2,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.5rem", marginRight: "0.5rem" }}
                    >
                      {"$2,500"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.2rem", marginRight: "0.5rem" }}
                    >
                      {"$3,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.6rem", marginRight: "0.5rem" }}
                    >
                      {"$3,500"}
                    </span>
                  </Col>
                </Row>
                <Divider className="mt-0" />

                <Row className="mb-4">
                  <Col span={3}>Gross Revenue</Col>
                  <Col span={21}>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "6.5rem", marginRight: "0rem" }}
                    >
                      {"$1,000,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "6.5rem", marginRight: "0.8rem" }}
                    >
                      {"$1,240,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "5.5rem", marginRight: "0.5rem" }}
                    >
                      {"$2,250,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "5.5rem", marginRight: "0.5rem" }}
                    >
                      {"$3,200,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "5.8rem", marginRight: "0.5rem" }}
                    >
                      {"$3,750,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "5.9rem", marginRight: "0.5rem" }}
                    >
                      {"$4,500,000"}
                    </span>
                  </Col>
                </Row>

                <Divider className="mt-0" />
                <Row className="mb-4">
                  <Col span={3}>Mandatory Rebate</Col>
                  <Col span={21}>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.5rem", marginRight: "0rem" }}
                    >
                      {"$10,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.6rem", marginRight: "0.8rem" }}
                    >
                      {"$15,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "6.7rem", marginRight: "0.5rem" }}
                    >
                      {"$20,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                    >
                      {"$25,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                    >
                      {"$30,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                    >
                      {"$35,000"}
                    </span>
                  </Col>
                </Row>
                <Divider className="mt-0" />
                <Row className="mb-4">
                  <Col span={4}>Construct Based Rebate</Col>
                  <Col span={20}>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "4.3rem", marginRight: "0rem" }}
                    >
                      {"$20,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.6rem", marginRight: "0.8rem" }}
                    >
                      {"$35,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "6.7rem", marginRight: "0.5rem" }}
                    >
                      {"$10,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                    >
                      {"$15,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                    >
                      {"$10,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7rem", marginRight: "0.5rem" }}
                    >
                      {"$15,000"}
                    </span>
                  </Col>
                </Row>
                <Divider className="mt-0" />

                <Row className="mb-4">
                  <Col span={3}>Net Revenue</Col>
                  <Col span={21}>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "6.7rem", marginRight: "0rem" }}
                    >
                      {"$970,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "6.5rem", marginRight: "0.8rem" }}
                    >
                      {"$850,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "5.5rem", marginRight: "0.5rem" }}
                    >
                      {"$1,970,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "5.5rem", marginRight: "0.5rem" }}
                    >
                      {"$2,850,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "5.8rem", marginRight: "0.5rem" }}
                    >
                      {"$3,500,000"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "5.9rem", marginRight: "0.5rem" }}
                    >
                      {"$4,320,000"}
                    </span>
                  </Col>
                </Row>
                <Divider className="mt-0" />

                <Row className="mb-4">
                  <Col span={3}>Net Price</Col>
                  <Col span={21}>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "8.2rem", marginRight: "0rem" }}
                    >
                      {"$800"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.5rem", marginRight: "0.8rem" }}
                    >
                      {"$1,200"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.5rem", marginRight: "0.5rem" }}
                    >
                      {"$1,800"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.5rem", marginRight: "0.5rem" }}
                    >
                      {"$2,200"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.2rem", marginRight: "0.5rem" }}
                    >
                      {"$2,900"}
                    </span>
                    <span
                      className="text-sm font-semibold "
                      style={{ marginLeft: "7.6rem", marginRight: "0.5rem" }}
                    >
                      {"$3,100"}
                    </span>
                  </Col>
                </Row>

                <Collapse
                  collapsible="header"
                  bordered={false}
                  defaultActiveKey={["1"]}
                  onChange={onChange}
                ></Collapse>
              </div>

              {/* push dummy changes */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
