import React, { useEffect } from "react";
import { Form, Input, Button, Select, Row, Col, Space, Divider, Cascader } from "antd";
import { getAllConstructs, newlyAddedConstruct, saveConstruct } from "./service-helper";
import AppContext from "../../../store";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "../Constructs/index.css";
import background from "../../../globalTheme/background2.png";
// const dummyData = [
//   {
//     construct_Id: null,
//     name: null,
//     product_ID: "123",
//     selectedConstructType_ID: null,
//     constructTypeModel: [
//       {
//         constructTypeID: "1",
//         construct_Type: "Outcome Based",
//       },
//       {
//         constructTypeID: "2",
//         construct_Type: "Financial Based",
//       },
//       {
//         constructTypeID: "3",
//         construct_Type: "Others",
//       },
//     ],
//     selectedRebateTimePeriod_ID: null,
//     rebateTimePeriodModel: [
//       {
//         rebateTimePeriod_Id: "101",
//         timePeriod: "Rebate Period",
//       },
//       {
//         rebateTimePeriod_Id: "102",
//         timePeriod: "Specific # of Months",
//       },
//     ],
//     selectedIVSRebateType_ID: null,
//     ivsRebateTypeModel: [
//       {
//         ivsRebateTypeID: "1",
//         type: "Fixed Amount",
//       },
//       {
//         ivsRebateTypeID: "2",
//         type: "Percentage",
//       },
//     ],
//     selectedRebateCondition_ID: null,
//     rebateConditionModel: [
//       {
//         rebateConditionID: "100001",
//         condition: "Greater than Threshold",
//       },
//       {
//         rebateConditionID: "100002",
//         condition: "Less than Threshold",
//       },
//     ],
//     selectedRebateThresholdInputType_ID: null,
//     rebateThresInputTypeModel: [
//       {
//         rebateThresInputID: "1001",
//         rebateThresInputType: "Single Input",
//       },
//       {
//         rebateThresInputID: "1002",
//         rebateThresInputType: "Tier - based Input",
//       },
//     ],
//     selectedConstructEvaluationParameters_ID: null,
//     constructEvalParamsModel: [
//       {
//         constructEvalID: "101",
//         parameter: "Patients",
//       },
//       {
//         constructEvalID: "102",
//         parameter: "Volume Sold",
//       },
//       {
//         constructEvalID: "103",
//         parameter: "Drug Price",
//       },
//     ],
//     selectedParameterType_ID: null,
//     parameterTypeModel: [
//       {
//         parameterTypeID: "10001",
//         parameter_Type: "New Patients",
//         constructEvaluationParameters_ID: "101",
//       },
//       {
//         parameterTypeID: "10002",
//         parameter_Type: "Continuing Patients",
//         constructEvaluationParameters_ID: "101",
//       },
//       {
//         parameterTypeID: "10003",
//         parameter_Type: "Discontinued Patients",
//         constructEvaluationParameters_ID: "101",
//       },
//       {
//         parameterTypeID: "10004",
//         parameter_Type: "Per Patient",
//         constructEvaluationParameters_ID: "103",
//       },
//       {
//         parameterTypeID: "10005",
//         parameter_Type: "Overall Contract",
//         constructEvaluationParameters_ID: "103",
//       },
//     ],
//   },
// ];

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function ConstructSetup(props: {
  constructPage?: any;
  constructDataForID: any;
  setConstructPage?: any;
  setValueOfConstructPage: any;
  id: any;
  dataForNew?: any;
  data: Object;
  newConstruct: any;
}) {
  const [fetchedData, setFetchData] = React.useState<any>();
  const [dataSaved, setDataSaved] = React.useState<any>();
  const [options, setOptions] = React.useState();
  let constructTypeOptions: any = [];
  let rebateThresInputTypeOptions: any = [];
  let ivsRebateTypeOptions: any = [];
  let rebateConditionOptions: any = [];
  let rebateTimePeriodOptions: any = [];

  const intialVal: any = {
    users: [
      {
        first: "OCS Reduction",
      },
      {
        first: "Hospitalization due to severe flare",
      },
      {
        first: "Adverse Event – Severe flare",
      },
      {
        first: "Adverse Event – Atrial fibrillation",
      },
      {
        first: "Adverse Event – Bleeding",
      },
    ],
  };

  const FetchData = async () => {
    const constructOptions = await newlyAddedConstruct();
    // console.log(constructOptions);
    setFetchData(constructOptions);
  };

  useEffect(() => {
    if (dataSaved) {
      props.setValueOfConstructPage();
    }
  }, [dataSaved]);

  useEffect(() => {
    if (fetchedData) {
      let optionsTemp: any = [];
      fetchedData.constructEvalParamsModel.map((i: any) => {
        let children: any = [];
        fetchedData.parameterTypeModel
          .filter((j: any) => j.constructEvaluationParameters_ID === i.constructEvalID)
          .map((k: any) => {
            children.push({ label: k.parameter_Type, value: k.parameterTypeID });
          });
        let optionsInternal = {
          label: i.parameter,
          value: i.constructEvalID,
          children: children,
        };
        optionsTemp.push(optionsInternal);
      });

      let temp = new Object();
      //  console.log("fetch", fetchedData);
      [fetchedData].map((x: any) => {
        x.constructTypeModel.map((y: any) => {
          temp = {
            label: y.construct_Type,
            value: y.constructTypeID,
          };

          constructTypeOptions.push(temp);
        });
      });

      temp = {};

      [fetchedData].map((x: any) => {
        x.rebateThresInputTypeModel.map((y: any) => {
          temp = {
            label: y.rebateThresInputType,
            value: y.rebateThresInputID,
          };
          rebateThresInputTypeOptions.push(temp);
        });
      });

      temp = {};

      [fetchedData].map((x: any) => {
        x.rebateConditionModel.map((y: any) => {
          temp = {
            label: y.condition,
            value: y.rebateConditionID,
          };
          rebateConditionOptions.push(temp);
        });
      });

      temp = {};
      [fetchedData].map((x: any) => {
        x.ivsRebateTypeModel.map((y: any) => {
          temp = {
            label: y.type,
            value: y.ivsRebateTypeID,
          };
          ivsRebateTypeOptions.push(temp);
        });
      });

      temp = {};

      [fetchedData].map((x: any) => {
        x.rebateTimePeriodModel.map((y: any) => {
          temp = {
            label: y.timePeriod,
            value: y.rebateTimePeriod_Id,
          };
          rebateTimePeriodOptions.push(temp);
        });
      });
      //console.log("OPTIONS", optionsTemp);
      setOptions(optionsTemp);
    }
    //  console.log("CTO", constructTypeOptions);
  }, [fetchedData]);

  useEffect(() => {
    FetchData();
  }, []);

  let context = React.useContext(AppContext);

  const freeText =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt. Cursus mattis molestie a iaculis at. Nibh praesent tristique magna sit amet purus gravida quis. Viverra suspendisse potenti nullam ac tortor vitae purus. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Massa tincidunt dui ut ornare lectus sit amet est. In hendrerit gravida rutrum quisque non. Id volutpat lacus laoreet non curabitur. Sapien nec sagittis aliquam malesuada bibendum arcu vitae.";
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log(values);

    fetchedData.product_ID = context.brandId;
    fetchedData.name = values.constructName;

    // console.log(props.constructDataForID);
    if (props.constructDataForID != undefined) {
      fetchedData.construct_Id = props.constructDataForID.constructId;
    } else {
      fetchedData.construct_Id = null;
    }

    fetchedData.selectedConstructEvaluationParameters_ID = values.constructEvaluationParameters[0];

    fetchedData.selectedConstructType_ID = "1";
    // values.constructType;
    fetchedData.selectedIVSRebateType_ID = "2";
    //  values.ivsRebateType;
    fetchedData.selectedParameterType_ID = "10001";

    // values.constructEvaluationParameters.length > 1
    //   ? values.constructEvaluationParameters[1]
    //   : null;

    fetchedData.selectedRebateCondition_ID = "100001";
    // values.rebateCondition;
    fetchedData.selectedRebateThresholdInputType_ID = "1002";
    //  values.rebateThresholdInputType;
    fetchedData.selectedRebateTimePeriod_ID = "101";
    // values.rebateTimePeriod;

    // console.log(fetchedData);
    // saveConstruct(fetchedData);
    let finalOutput: any = saveConstruct(fetchedData);
    setDataSaved(finalOutput);
  };

  const onReset = () => {
    form.resetFields();
  };

  const [outcomeMeasure, setOutcomeMeasure] = React.useState<boolean>(false);
  function onChange(value: any) {
    console.log(value);
    if (value[0] === "101") {
      setOutcomeMeasure(true);
    }
  }

  return (
    <>
      {fetchedData && (
        <div>
          <h4 className="ml-8 pt-8 text-2xl float-left font-bold">Construct Setup</h4>

          <Button className="float-right mt-8 mr-4" htmlType="button" onClick={onReset}>
            Clear
          </Button>

          <Divider className="pl-8 pr-8" />
          <Form
            id={props.id}
            layout="vertical"
            {...layout}
            form={form}
            name="constructSetup"
            onFinish={onFinish}
            initialValues={props.constructDataForID}
          >
            <Row className="ml-8">
              <Col span={12}>
                <Form.Item
                  name="constructName"
                  label={<p className="-mb-1">1. Construct Name</p>}
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter construct name here"></Input>
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item
                  name="constructType"
                  label="2. Construct Type"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select construct type" allowClear>
                    {fetchedData.constructTypeModel.map((i: any) => (
                      <Option value={i.constructTypeID}> {i.construct_Type} </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
            <Divider />
            <Row className="ml-8">
              <Col span={12}>
                <Form.Item
                  name="constructEvaluationParameters"
                  label="2. Construct Evaluation Parameters"
                  rules={[{ required: true }]}
                >
                  {options && (
                    <Cascader
                      options={options}
                      onChange={onChange}
                      placeholder="Please select parameter"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="extraInfo"
                  label="3. Construct Details"
                  // rules={[{ required: true }]}
                >
                  <Input.TextArea
                    className="textArea1"
                    // defaultValue={freeText}
                    placeholder="Enter construct details"
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            {(outcomeMeasure ||
              (props.constructDataForID &&
                props.constructDataForID.constructName === "Value Warranty")) && (
              <Row className="ml-8">
                <Form
                  initialValues={!props.newConstruct && intialVal}
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    name="extraInfo"
                    label="4. Define Outcome Measures"
                    // rules={[{ required: true }]}
                  >
                    <Form.List name="users">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index: any) => (
                            <Space
                              key={key}
                              style={{ display: "flex", marginBottom: 8 }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "first"]}
                                rules={[{ required: true, message: "Missing first name" }]}
                              >
                                <Input placeholder={"Outcome Measure - " + ++index} />
                              </Form.Item>
                              {/* <Form.Item
                            {...restField}
                            name={[name, "last"]}
                            rules={[{ required: true, message: "Missing last name" }]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item> */}
                              <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add field
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                </Form>
              </Row>
            )}

            {/* <Row className="ml-8">
              <Col span={12}>
                <Form.Item
                  name="rebateThresholdInputType"
                  label="4. Rebate Threshold Input Type"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select construct type" allowClear>
                    {fetchedData.rebateThresInputTypeModel.map((i: any) => (
                      <Option value={i.rebateThresInputID}> {i.rebateThresInputType} </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="rebateCondition"
                  label="5. Rebate Condition"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select construct type" allowClear>
                    {fetchedData.rebateConditionModel.map((i: any) => (
                      <Option value={i.rebateConditionID}> {i.condition} </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider />

            <Row className="ml-8">
              <Col span={12}>
                <Form.Item name="ivsRebateType" label="6. Rebate Type" rules={[{ required: true }]}>
                  <Select placeholder="Select construct type" allowClear>
                    {fetchedData.ivsRebateTypeModel.map((i: any) => (
                      <Option value={i.ivsRebateTypeID}> {i.type} </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="rebateTimePeriod"
                  label="7. Rebate Time Period"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select construct type" allowClear>
                    {fetchedData.rebateTimePeriodModel.map((i: any) => (
                      <Option value={i.rebateTimePeriod_Id}> {i.timePeriod} </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}

            <Form.Item style={{}}>
              <Button
                // onClick={props.setValueOfConstructPage}
                type="primary"
                htmlType="submit"
                className="mt-20 -ml-24"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
}
