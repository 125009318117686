import {
  getKmCurveDetailsData,
  saveKmCurveDetailsData,
  savePricingDetailsData,
} from "../../../services/ContractInput";

export const getKmCurveDetails: (contractId: any) => Promise<unknown> = async (contractId: any) => {
  const data = await getKmCurveDetailsData(contractId);

  if (data === null || data === undefined) return null;

  return data;
};

export const saveKmCurveDetails: (value: any) => Promise<unknown> = async (value: any) => {
  const data = await saveKmCurveDetailsData(value);

  if (data === null || data === undefined) return null;

  return data;
};

export const savePricingDetails: (value: any) => Promise<unknown> = async (value: any) => {
  const data = await savePricingDetailsData(value);

  if (data === null || data === undefined) return null;

  return data;
};
