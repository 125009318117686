import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import { useLocation } from "react-router";
import {
  Form,
  Input,
  Button,
  Space,
  Row,
  Col,
  Select,
  Collapse,
  Table,
  Popconfirm,
  message,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "../BrandProductSetup/index.css";
import "../../../globalTheme/background2.png";
import AppContext from "../../../store";
import { Bin2 } from "styled-icons/icomoon";
import { v4 as uuidv4 } from "uuid";
import { AddCircle } from "@styled-icons/fluentui-system-filled/AddCircle";
import background from "../../../globalTheme/background2.jpg";
import "bootstrap/dist/css/bootstrap.css";
import ViewOnlyTable from "./viewOnlyTable";
import { getEmptyProduct, getProductDetails, saveProductDetails } from "./service-helper";
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function BrandProductSetup() {
  const [uuidArray, setUUIDArray] = React.useState<any>([]);
  const [fetchedData, SetFetchedData] = React.useState<any>({ sku1: [], sku2: [] });

  const context = React.useContext(AppContext);

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
  };

  let jsonDataPage1: any = {
    page1: {},
  };

  const [errors, setErrors] = React.useState({});

  // getting initial values from the API

  const [productData, setProductData] = React.useState<any>();
  const [inputFields, setInputFields] = useState<any>([]);
  const [inputFields2, setInputFields2] = useState<any>({ sku1: [], sku2: [] });
  const [skuDetails, setSkuDetails] = React.useState<any>();
  const [isNewProduct, setIsNewProduct] = React.useState<boolean>(true);
  let loc: any = useLocation();

  const FetchData = async () => {
    let productId = loc.state;

    if (productId) {
      const dataOfProduct: any = await getProductDetails(productId);
      console.log(dataOfProduct);
      setProductData(dataOfProduct);
      setIsNewProduct(true);
    } else {
      if (context.brandId) {
        const dataOfProduct: any = await getProductDetails(context.brandId);
        console.log(dataOfProduct);
        setIsNewProduct(true);
        setProductData(dataOfProduct);
      } else {
        const dataOfProduct: any = await getEmptyProduct();
        console.log(dataOfProduct);
        setIsNewProduct(false);
        setProductData(dataOfProduct);
      }
    }
  };

  useEffect(() => {
    FetchData();
  }, []);

  const [datadummy, setDatadummy] = React.useState<any>();
  const [dosage1, setDosage1] = React.useState<any>("");
  const [dosage2, setDosage2] = React.useState<any>("");

  useEffect(() => {
    if (productData) {
      // console.log(productData.dosage_SKUModel);
      setDosage1(productData.dosage_SKUModel ? productData.dosage_SKUModel[0].dosageSKU_mg : "");
      if (productData.dosage_SKUModel) {
        setDosage2(
          productData.dosage_SKUModel.length > 1 ? productData.dosage_SKUModel[1].dosageSKU_mg : ""
        );
      } else {
        setDosage2("");
      }

      if (productData.selectedProductType === "101") {
        setProdType("Tablet");
      } else if (productData.selectedProductType === "102") {
        setProdType("Capsule");
      } else if (productData.selectedProductType === "103") {
        setProdType("Vial");
      }

      let intialValuesObject: any = {};
      intialValuesObject.isKMcurve = productData.isKMcurve;
      intialValuesObject.productName = productData.productName;
      intialValuesObject.selectedProductType = productData.selectedProductType;
      // intialValuesObject.packlevel =
      //   productData.packsModel && (productData.packsModel.length > 0 ? "yes" : "no");
      let packObj: any = [];
      if (productData.packsModel) {
        if (productData.packsModel.length > 0) {
          productData.packsModel.map((i: any) => {
            packObj.push(i.size);
          });
          intialValuesObject.packsModel = productData.packsModel;
        } else {
          intialValuesObject.packsModel = null;
        }
      }
      intialValuesObject.dosageForms =
        productData.dosage_SKUModel && productData.dosage_SKUModel.length + "";
      intialValuesObject.dosageForm1 =
        productData.dosage_SKUModel && productData.dosage_SKUModel[0].dosageSKU_mg;
      intialValuesObject.dosageForm2 =
        productData.dosage_SKUModel &&
        (productData.dosage_SKUModel.length === 2
          ? productData.dosage_SKUModel[1].dosageSKU_mg
          : null);

      let arrIndications: any = [];
      productData.indications &&
        productData.indications.map((indi: any) => {
          arrIndications.push({
            uuid: indi.indication_ID,
            indicationName: indi.indication_Name,
            selectedDosageType: indi.selectedDosageType,
            isYearly: indi.isYearly,
            indication_ID: indi.indication_ID,
          });
        });

      let arrIndication2: any = { sku1: [], sku2: [] };
      let objIndiSku: any = {
        uuid: null,
        avgWtOfPatient: null,
        prescribedDose: null,
        volumeofProductPerUnit: null,
        noOfUnitsPerDose: null,
        id: null,
        dosage_SKU: {
          dosageSKU_ID: null,
          dosageSKU_mg: null,
        },
      };

      let skuobj: any = {
        sku1: productData.dosage_SKUModel && productData.dosage_SKUModel[0].dosageSKU_ID,
        sku2:
          productData.dosage_SKUModel && productData.dosage_SKUModel.length > 1
            ? productData.dosage_SKUModel[1].dosageSKU_ID
            : null,
      };

      setSkuDetails(skuobj);

      productData.indications &&
        productData.indications.map((indi: any) => {
          for (let counter = 1; counter <= indi.dose.length; counter++) {
            let index: any = 0;
            for (let indexTemp = 0; indexTemp < indi.dose.length; indexTemp++) {
              if (indi.dose[indexTemp].dosage_SKU.dosageSKU_ID == skuobj["sku" + counter]) {
                index = indexTemp;
                break;
              }
            }

            arrIndication2["sku" + counter].push({
              indicationID: indi.indication_ID,
              // uuid: indi.dose[index].id,
              uuid: indi.indication_ID,

              avgWtOfPatient: indi.dose[index].avgWtOfPatient,
              prescribedDose: indi.dose[index].prescribedDose,
              volumeofProductPerUnit: indi.dose[index].volumeofProductPerUnit,
              noOfUnitsPerDose: indi.dose[index].noOfUnitsPerDose,
              id: indi.dose[index].id,
              dosage_SKU: indi.dose[index].dosage_SKU,
            });
          }

          if (indi.dose.length === 1) {
            arrIndication2["sku2"].push({
              indicationID: indi.indication_ID,
              uuid: null,
              avgWtOfPatient: null,
              prescribedDose: null,
              volumeofProductPerUnit: null,
              noOfUnitsPerDose: null,
              id: null,
              dosage_SKU: {
                dosageSKU_ID: null,
                dosageSKU_mg: null,
              },
            });
          }
        });

      let fetchDataFromAPI: any = { sku1: [], sku2: [] };
      let obj: any = [];
      let obj2: any = [];

      productData.indications &&
        productData.indications.map((i: any) => {
          console.log("INDICATION VALUE ", i);

          let sku1_ID = productData.dosage_SKUModel[0].dosageSKU_ID;
          let sku2_ID =
            productData.dosage_SKUModel.length > 1 && productData.dosage_SKUModel[1].dosageSKU_ID;

          obj.push({
            // dose_ID: i.dosesEachMonth
            indication_ID: i.indication_ID,
            indication: i.indication_Name,
            1:
              i.dosesEachMonth.length == 0
                ? ""
                : i.dosesEachMonth.filter(
                    (d: any) => d.skUdetails.dosageSKU_ID === sku1_ID && d.monthIndex === 1
                  )[0].dosage + "",
            2:
              i.dosesEachMonth.length == 0
                ? ""
                : i.dosesEachMonth.filter(
                    (d: any) => d.skUdetails.dosageSKU_ID === sku1_ID && d.monthIndex === 2
                  )[0].dosage + "",
            3:
              i.dosesEachMonth.length == 0
                ? ""
                : i.dosesEachMonth.filter(
                    (d: any) => d.skUdetails.dosageSKU_ID === sku1_ID && d.monthIndex === 3
                  )[0].dosage + "",
            4:
              i.dosesEachMonth.length == 0
                ? ""
                : i.dosesEachMonth.filter(
                    (d: any) => d.skUdetails.dosageSKU_ID === sku1_ID && d.monthIndex === 4
                  )[0].dosage + "",
            5:
              i.dosesEachMonth.length == 0
                ? ""
                : i.dosesEachMonth.filter(
                    (d: any) => d.skUdetails.dosageSKU_ID === sku1_ID && d.monthIndex === 5
                  )[0].dosage + "",
            6:
              i.dosesEachMonth.length == 0
                ? ""
                : i.dosesEachMonth.filter(
                    (d: any) => d.skUdetails.dosageSKU_ID === sku1_ID && d.monthIndex === 6
                  )[0].dosage + "",
            7:
              i.dosesEachMonth.length == 0
                ? ""
                : i.dosesEachMonth.filter(
                    (d: any) => d.skUdetails.dosageSKU_ID === sku1_ID && d.monthIndex === 7
                  )[0].dosage + "",
            8:
              i.dosesEachMonth.length == 0
                ? ""
                : i.dosesEachMonth.filter(
                    (d: any) => d.skUdetails.dosageSKU_ID === sku1_ID && d.monthIndex === 8
                  )[0].dosage + "",
            9:
              i.dosesEachMonth.length == 0
                ? ""
                : i.dosesEachMonth.filter(
                    (d: any) => d.skUdetails.dosageSKU_ID === sku1_ID && d.monthIndex === 9
                  )[0].dosage + "",
            10:
              i.dosesEachMonth.length == 0
                ? ""
                : i.dosesEachMonth.filter(
                    (d: any) => d.skUdetails.dosageSKU_ID === sku1_ID && d.monthIndex === 10
                  )[0].dosage + "",
            11:
              i.dosesEachMonth.length == 0
                ? ""
                : i.dosesEachMonth.filter(
                    (d: any) => d.skUdetails.dosageSKU_ID === sku1_ID && d.monthIndex === 11
                  )[0].dosage + "",
            12:
              i.dosesEachMonth.length == 0
                ? ""
                : i.dosesEachMonth.filter(
                    (d: any) => d.skUdetails.dosageSKU_ID === sku1_ID && d.monthIndex === 12
                  )[0].dosage + "",
            totalDoses: "12",
          });

          if (productData.dosage_SKUModel.length > 1) {
            obj2.push({
              // dose_ID: i.dosesEachMonth
              indication_ID: i.indication_ID,
              indication: i.indication_Name,
              1:
                i.dosesEachMonth.length == 0
                  ? ""
                  : i.dosesEachMonth.filter(
                      (d: any) => d.skUdetails.dosageSKU_ID === sku2_ID && d.monthIndex === 1
                    )[0].dosage + "",
              // 1: i.dosesEachMonth.length == 0 ? "" : i.dosesEachMonth[0].dosage + "",
              2:
                i.dosesEachMonth.length == 0
                  ? ""
                  : i.dosesEachMonth.filter(
                      (d: any) => d.skUdetails.dosageSKU_ID === sku2_ID && d.monthIndex === 2
                    )[0].dosage + "",
              3:
                i.dosesEachMonth.length == 0
                  ? ""
                  : i.dosesEachMonth.filter(
                      (d: any) => d.skUdetails.dosageSKU_ID === sku2_ID && d.monthIndex === 3
                    )[0].dosage + "",
              4:
                i.dosesEachMonth.length == 0
                  ? ""
                  : i.dosesEachMonth.filter(
                      (d: any) => d.skUdetails.dosageSKU_ID === sku2_ID && d.monthIndex === 4
                    )[0].dosage + "",
              5:
                i.dosesEachMonth.length == 0
                  ? ""
                  : i.dosesEachMonth.filter(
                      (d: any) => d.skUdetails.dosageSKU_ID === sku2_ID && d.monthIndex === 5
                    )[0].dosage + "",
              6:
                i.dosesEachMonth.length == 0
                  ? ""
                  : i.dosesEachMonth.filter(
                      (d: any) => d.skUdetails.dosageSKU_ID === sku2_ID && d.monthIndex === 6
                    )[0].dosage + "",
              7:
                i.dosesEachMonth.length == 0
                  ? ""
                  : i.dosesEachMonth.filter(
                      (d: any) => d.skUdetails.dosageSKU_ID === sku2_ID && d.monthIndex === 7
                    )[0].dosage + "",
              8:
                i.dosesEachMonth.length == 0
                  ? ""
                  : i.dosesEachMonth.filter(
                      (d: any) => d.skUdetails.dosageSKU_ID === sku2_ID && d.monthIndex === 8
                    )[0].dosage + "",
              9:
                i.dosesEachMonth.length == 0
                  ? ""
                  : i.dosesEachMonth.filter(
                      (d: any) => d.skUdetails.dosageSKU_ID === sku2_ID && d.monthIndex === 9
                    )[0].dosage + "",
              10:
                i.dosesEachMonth.length == 0
                  ? ""
                  : i.dosesEachMonth.filter(
                      (d: any) => d.skUdetails.dosageSKU_ID === sku2_ID && d.monthIndex === 10
                    )[0].dosage + "",
              11:
                i.dosesEachMonth.length == 0
                  ? ""
                  : i.dosesEachMonth.filter(
                      (d: any) => d.skUdetails.dosageSKU_ID === sku2_ID && d.monthIndex === 11
                    )[0].dosage + "",
              12:
                i.dosesEachMonth.length == 0
                  ? ""
                  : i.dosesEachMonth.filter(
                      (d: any) => d.skUdetails.dosageSKU_ID === sku2_ID && d.monthIndex === 12
                    )[0].dosage + "",
              totalDoses: "12",
            });
          }
        });

      fetchDataFromAPI["sku1"] = [...obj];

      fetchDataFromAPI["sku2"] = [...obj2];
      SetFetchedData(fetchDataFromAPI);

      setInputFields(arrIndications);
      setInputFields2(arrIndication2);

      console.log(intialValuesObject);
      setDatadummy(intialValuesObject);
    }
  }, [productData]);

  const deleteRow = (indication_ID: any) => {
    let obj: any = {};
    // console.log(inputFields2);

    let variableCopy: any = fetchedData;
    const indicationDetails2: any = inputFields2;
    const indicationDetails: any = inputFields;
    for (let skuIndex = 1; skuIndex <= 2; skuIndex++) {
      let index = variableCopy["sku" + skuIndex].findIndex(
        (ind: any) => ind.indication_ID === indication_ID
      );
      variableCopy["sku" + skuIndex].splice(index, 1);
      obj["sku" + skuIndex] = [...variableCopy["sku1"]];

      index = indicationDetails2["sku" + skuIndex].findIndex(
        (ind: any) => ind.indication_ID === indication_ID
      );
      indicationDetails2["sku" + skuIndex].splice(index, 1);
    }
    // console.log(indicationDetails);
    let index: any = indicationDetails.findIndex((ind: any) => ind.indication_ID === indication_ID);
    indicationDetails.splice(index, 1);

    let uuidTemp = [...uuidArray];
    let uuidArrIndex: any = uuidTemp.findIndex((ind: any) => ind === indication_ID);
    // console.log("uuidArrIndex Index", uuidArrIndex);
    if (uuidArrIndex >= 0) {
      uuidTemp.splice(uuidArrIndex, 1);
      setUUIDArray(uuidTemp);
    }

    // context.setTableData(variableCopy);
    SetFetchedData(obj);
    setInputFields(indicationDetails);
    setInputFields2(indicationDetails2);
  };

  function confirm(indication_ID: any) {
    // console.log(inputFields, inputFields2, indication_ID);
    // const values: any = [...inputFields2];

    // for (let skuIndex = 1; skuIndex <= 2; skuIndex++) {
    //   let index = values["sku" + skuIndex].findIndex(
    //     (ind: any) => ind.indication_ID === indication_ID
    //   );
    // }

    // const values: any = [...inputFields];
    // const values2: any = [...inputFields2];

    // values2.splice(index, 1);
    // values.splice(index, 1);
    // setInputFields(values);
    // setInputFields2(values2);
    message.info("Indication deleted successfully!");
  }

  const onFinish = async (values: any) => {
    console.log(jsonDataPage1.page1, datadummy);
    // jsonDataPage1.page1 = values;
    jsonDataPage1.page1.dosageForm1 = values.dosageForm1;
    jsonDataPage1.page1.dosageForm2 = values.dosageForm2;
    jsonDataPage1.page1.dosageForms = values.dosageForms;
    jsonDataPage1.page1.productName = values.productName;

    jsonDataPage1.page1.packsModel =
      values["packsModel"] === undefined ? null : values["packsModel"];

    jsonDataPage1.page1.selectedProductType = values.selectedProductType;
    jsonDataPage1.page1.isKMcurve = values.isKMcurve;

    jsonDataPage1.page1.productId = context.brandId ? context.brandId : null;
    jsonDataPage1.page1.addedBy = null;
    jsonDataPage1.page1.addedOn = null;

    jsonDataPage1.page1.indications = [...inputFields];

    delete jsonDataPage1.page1.indications.uuid;
    jsonDataPage1.page1.indications.map((i: any) => {
      i.indication_Name = i.indicationName;
      i.addedOn = null;
      i.addedBy = null;
      i.updatedOn = null;
      i.updatedBy = null;
      // i.indication_ID = null;
      inputFields2["sku1"].forEach((element: any) => {
        element.dosage_SKU.dosageSKU_mg = jsonDataPage1.page1.dosageForm1;
      });
      i.dose = [];

      i.dose.push(
        inputFields2["sku1"].filter((indicationId: any) => indicationId.uuid === i.indication_ID)[0]
      );

      if (values.dosageForms === "2") {
        inputFields2["sku2"].forEach((element: any) => {
          element.dosage_SKU.dosageSKU_mg = jsonDataPage1.page1.dosageForm2;
        });
        i.dose.push(
          inputFields2["sku2"].filter(
            (indicationId: any) => indicationId.uuid === i.indication_ID
          )[0]
        );
      }

      // if (i.dosesEachMonth) {
      // console.log("inside edit product loop");
      // i.dosesEachMonth.map((d: any) => {
      //   if (d.skUdetails.dosageSKU_ID === jsonDataPage1.page1.dosage_SKUModel[0].dosageSKU_ID) {
      //     d.dosage = fetchedData["sku1"].filter(
      //       (indi: any) => indi.indication_ID === i.indication_ID
      //     )[0][d.monthIndex];
      //   } else {
      //     d.dosage = fetchedData["sku2"].filter(
      //       (indi: any) => indi.indication_ID === i.indication_ID
      //     )[0][d.monthIndex];
      //   }
      // });
      // } else {
      // console.log("inside new product loop");

      let dosesEachMonthObject: any = [];

      let dataInTable = fetchedData;
      dataInTable["sku1"]
        .filter((x: any) => x.indication_ID === i.indication_ID)
        .map((j: any) => {
          for (let k = 1; k < 13; k++) {
            let doseObject: any = {
              //add dose_ID for editing else null
              dose_ID: null,
              indication_ID: i.indication_ID,
              monthIndex: null,
              dosage: null,
            };

            doseObject.monthIndex = k;
            doseObject.dosage = parseInt(j["" + k]);
            doseObject.skUdetails = {
              dosageSKU_ID: inputFields2["sku1"][0].dosage_SKU.dosageSKU_ID,
              dosageSKU_mg: inputFields2["sku1"][0].dosage_SKU.dosageSKU_mg,
            };
            dosesEachMonthObject.push(doseObject);
          }
        });

      if (values.dosageForms === "2") {
        dataInTable["sku2"]
          .filter((x: any) => x.indication_ID === i.indication_ID)
          .map((j: any) => {
            for (let k = 1; k < 13; k++) {
              let doseObject: any = {
                dose_ID: null,
                indication_ID: i.indication_ID,
                monthIndex: null,
                dosage: null,
              };

              doseObject.monthIndex = k;
              doseObject.dosage = parseInt(j["" + k]);
              doseObject.skUdetails = {
                dosageSKU_ID: inputFields2["sku2"][0].dosage_SKU.dosageSKU_ID,
                dosageSKU_mg: inputFields2["sku2"][0].dosage_SKU.dosageSKU_mg,
              };
              dosesEachMonthObject.push(doseObject);
            }
          });
      }

      i.dosesEachMonth = dosesEachMonthObject;
      // }
    });

    // For getting values of dosage_SKUModel in the JSON
    const dosage_SKUModel1 = {
      dosageSKU_ID: inputFields2["sku1"][0].dosage_SKU.dosageSKU_ID,
      dosageSKU_mg: inputFields2["sku1"][0].dosage_SKU.dosageSKU_mg,
    };

    jsonDataPage1.page1.dosage_SKUModel = [];
    jsonDataPage1.page1.dosage_SKUModel.push(dosage_SKUModel1);

    if (values.dosageForms === "2") {
      const dosage_SKUModel2 = {
        dosageSKU_ID: inputFields2["sku2"][0].dosage_SKU.dosageSKU_ID,
        dosageSKU_mg: inputFields2["sku2"][0].dosage_SKU.dosageSKU_mg,
      };
      jsonDataPage1.page1.dosage_SKUModel.push(dosage_SKUModel2);
    }

    context.setCurrent(context.current + 1);
    if (context.current === 1) {
      context.setSubmit1(true);
    }

    let uuidTemp: any = [...uuidArray];
    uuidTemp.forEach((idToBeDeleted: any) => {
      jsonDataPage1.page1.indications
        .filter((x: any) => x.indication_ID === idToBeDeleted)
        .map((indi: any) => {
          indi.indication_ID = null;
          indi.dosesEachMonth.map((y: any) => {
            y.indication_ID = null;
          });
          indi.dose.map((z: any) => {
            z.indicationID = null;
          });
        });
    });

    setUUIDArray(uuidTemp);

    console.log("DATA SENDING", jsonDataPage1);
    context.setProductJson(jsonDataPage1);
    if (context.brandId) {
      const data: any = await saveProductDetails(true, jsonDataPage1.page1);

      if (data.productId) {
        context.setBrandId(data.productId);
      }
    } else {
      const data: any = await saveProductDetails(false, jsonDataPage1.page1);
      if (data.productId) {
        context.setBrandId(data.productId);
      }
    }
  };

  const { Panel } = Collapse;

  const [counter, setCounter] = useState(1);
  const addRow = (uuid: any) => {
    let obj: any = {};

    let variableCopy: any = fetchedData;
    // context.setTableData(variableCopy);

    let toAdd = {
      indication_ID: uuid,
      indication: null,
      1: "0",
      2: "0",
      3: "0",
      4: "0",
      5: "0",
      6: "0",
      7: "0",
      8: "0",
      9: "0",
      10: "0",
      11: "0",
      12: "0",
      totalDoses: "",
    };

    variableCopy["sku1"].push(toAdd);
    obj["sku1"] = [...variableCopy["sku1"]];
    variableCopy["sku2"].push(toAdd);
    obj["sku2"] = [...variableCopy["sku2"]];

    // context.setTableData(variableCopy);
    SetFetchedData(obj);
    setCounter(counter + 1);
  };

  const handleAddFields = () => {
    let uuid = uuidv4();
    let uuidTemp = [...uuidArray];
    uuidTemp.push(uuid);
    setUUIDArray(uuidTemp);
    const values = [...inputFields];
    const values2: any = inputFields2;

    values.push({
      uuid: uuid,
      indication_ID: uuid,
      indicationName: "",
      selectedDosageType: "",
      isYearly: null,
    });

    values2["sku1"].push({
      uuid: uuid,
      indicationID: uuid,
      avgWtOfPatient: null,
      prescribedDose: null,
      volumeofProductPerUnit: null,
      noOfUnitsPerDose: null,
      id: null,
      dosage_SKU: {
        dosageSKU_ID: skuDetails["sku1"],
        dosageSKU_mg: null,
      },
    });

    values2["sku2"].push({
      uuid: uuid,
      indicationID: uuid,
      avgWtOfPatient: null,
      prescribedDose: null,
      volumeofProductPerUnit: null,
      noOfUnitsPerDose: null,
      id: null,
      dosage_SKU: {
        dosageSKU_ID: skuDetails["sku2"],
        dosageSKU_mg: null,
      },
    });

    setInputFields(values);
    setInputFields2(values2);

    addRow(uuid);
  };

  const handleInputChange = (index: any, event: any, skuId: any) => {
    const values = [...inputFields];
    const values2: any = inputFields2;
    if (event.target.name === "indicationName") {
      values[index].indicationName = event.target.value;
    } else if (event.target.name === "selectedDosageType") {
      values[index].selectedDosageType = event.target.value;
    }
    if (event.target.name === skuId.toUpperCase() + "_avgWtOfPatient") {
      values2[skuId][index].avgWtOfPatient = parseInt(event.target.value);
    } else if (event.target.name === skuId.toUpperCase() + "_prescribedDose") {
      values2[skuId][index].prescribedDose = parseInt(event.target.value);
    } else if (event.target.name === skuId.toUpperCase() + "_volumeofProductPerUnit") {
      values2[skuId][index].volumeofProductPerUnit = parseInt(event.target.value);
    } else if (event.target.name === skuId.toUpperCase() + "_noOfUnitsPerDose") {
      values2[skuId][index].noOfUnitsPerDose = parseInt(event.target.value);
    } else if (event.target.name === "isYearly") {
      values[index].isYearly = event.target.value === "true" ? true : false;
    } else {
      // event.target.name
    }
    setInputFields(values);
    setInputFields2(values2);
    if (event.target.name === "indicationName") {
      let tempVal: any = fetchedData;
      tempVal["sku1"][index].indication = event.target.value;
      tempVal["sku2"][index].indication = event.target.value;
      SetFetchedData({ ...tempVal });
    }
  };

  const resetForm = () =>
    setInputFields([{ uuid: "", indicationName: "", selectedDosageType: "" }]);

  const getFieldValueOfDosage = (e: any) => {
    console.log(e.target.value);
    if (e.target.name === "form1") setDosage1(e.target.value);
    else if (e.target.name === "form2") setDosage2(e.target.value);
  };

  // const genExtra = () => (
  //   <>
  //     {
  //       <div style={{ width: "62rem" }}>
  //         <Form.Item
  //           rules={[
  //             {
  //               required: true,
  //               message: "Missing Indication Name",
  //             },
  //           ]}
  //         >
  //           <Input
  //             value={inputField.indicationName}
  //             // key={"indicationName" + inputField.uuid}
  //             name={"indicationName"}
  //             style={{
  //               width: "11rem",
  //               marginLeft: "-4rem",
  //               height: "30px",
  //             }}
  //             onChange={(event) => handleInputChange(index, event, "sku")}
  //             placeholder="Enter Indication Name"
  //           />
  //         </Form.Item>
  //         <Popconfirm
  //           placement="topLeft"
  //           title={"Confirm Delete?"}
  //           onConfirm={() => {
  //             // confirm(inputField.indication_ID);
  //             deleteRow(inputField.indication_ID);
  //           }}
  //           okText="Yes"
  //           cancelText="No"
  //         >
  //           <Bin2
  //             color="black"
  //             size={15}
  //             style={{
  //               marginTop: "-46px",
  //               marginLeft: "2px",
  //               marginRight: "0.5rem",
  //               float: "right",
  //             }}
  //           />
  //         </Popconfirm>
  //       </div>
  //     }
  //   </>
  // );

  const [prodType, setProdType] = React.useState<any>();
  const ChangeProductType = (e: any) => {
    if (e === "101") {
      setProdType("Tablet");
    } else if (e === "102") {
      setProdType("Capsule");
    } else if (e === "103") {
      setProdType("Vial");
    }
  };

  return (
    <div>
      {datadummy && (
        <div className="h-auto" style={{ backgroundImage: `url(${background})` }}>
          {console.log("JSON DATA", datadummy, productData)}
          <Collapse collapsible="header" defaultActiveKey={["1"]}>
            <div style={{ backgroundImage: `url(${background})`, marginTop: "-2rem" }}>
              <div className="ml-8 mt-8 mr-8">
                <div>
                  <div className=" font-bold float-left text-2xl text-zsgray mt-2">
                    Product Information
                  </div>
                  <Button
                    className="float-right"
                    htmlType="button"
                    onClick={function (event) {
                      resetForm();
                      onReset();
                    }}
                  >
                    Reset
                  </Button>
                  <br></br>
                  <br></br>
                  <div className="text-sm font-normal float-left">
                    Input basic details for the product
                  </div>
                  <br></br>
                  <br></br>

                  <Form
                    layout="vertical"
                    {...layout}
                    form={form}
                    name="productInfo"
                    onFinish={onFinish}
                    initialValues={datadummy}
                  >
                    <Row>
                      <Col span={6}>
                        <Form.Item
                          name="productName"
                          label="Product Name"
                          rules={[{ required: true }]}
                          // initialValue={productData.productName}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="selectedProductType"
                          label="Product Type"
                          rules={[{ required: true }]}
                          // initialValue={productData.selectedProductType}
                        >
                          <Select
                            onChange={(e: any) => ChangeProductType(e)}
                            placeholder=""
                            allowClear
                          >
                            <Option value="101">Tablet </Option>
                            <Option value="102"> Capsule </Option>
                            <Option value="103"> Vial </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="isKMcurve"
                          label="Discontinuation using KM Curve"
                          rules={[{ required: true }]}
                        >
                          <Select placeholder="" allowClear>
                            <Option value={true}>Yes </Option>
                            <Option value={false}>No</Option>
                            {/* <Option value={false}>Discontinuation Rate</Option>
                          <Option value={true}>KM Curve </Option> */}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="dosageForms"
                          label="Number of SKUs"
                          rules={[{ required: true }]}
                          // disabled={isNewProduct ? false : true}
                        >
                          <Select disabled={isNewProduct ? true : false} placeholder="" allowClear>
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.dosageForms !== currentValues.dosageForms
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("dosageForms") === "2" ? (
                            <>
                              <Col span={6}>
                                <Form.Item
                                  name="dosageForm1"
                                  label="SKU-1"
                                  rules={[{ required: true }]}
                                >
                                  <Input name="form1" onChange={getFieldValueOfDosage}></Input>
                                </Form.Item>
                              </Col>

                              <Col span={4}>
                                <Form.Item
                                  name="dosageForm2"
                                  label="SKU-2"
                                  rules={[{ required: true }]}
                                >
                                  <Input name="form2" onChange={getFieldValueOfDosage}></Input>
                                </Form.Item>
                              </Col>
                              <Col offset={2} span={4}>
                                {/* <Form.Item
                                name="isKMcurve"
                                label="KM Curve"
                                rules={[{ required: true }]}
                              >
                                <Select placeholder="" allowClear>
                                  <Option value={true}>KM Curve </Option>
                                  <Option value={false}>Discontinuation</Option>
                                </Select>
                              </Form.Item> */}
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col span={6}>
                                <Form.Item
                                  name="dosageForm1"
                                  label="SKU-1"
                                  rules={[{ required: true }]}
                                >
                                  <Input name="form1" onChange={getFieldValueOfDosage}></Input>
                                </Form.Item>
                              </Col>
                              {/* <Col span={4}>
                              <Form.Item
                                name="kmCurve"
                                label="KM Curve"
                                rules={[{ required: true }]}
                              >
                                <Select placeholder="" allowClear>
                                  <Option value="1">KM Curve </Option>
                                  <Option value="0">Discontinuation</Option>
                                </Select>
                              </Form.Item>
                            </Col> */}
                            </>
                          )
                        }
                      </Form.Item>
                    </Row>

                    {/* <Form.Item
                    name="packlevel"
                    label="Pack level calculations required?"
                    rules={[{ required: true }]}
                    // initialValue={productData.length > 0 ? "yes" : "no"}
                  >
                    <Select style={{ width: "240px", float: "left" }} placeholder="" allowClear>
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.packlevel !== currentValues.packlevel
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("packlevel") === "yes" ? (
                        <>
                          <Form.Item label="Specify pack sizes:"></Form.Item>
                          <Row className="-mt-12">
                            <Form.List name="packsModel">
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                      key={key}
                                      style={{
                                        display: "flex",
                                        marginBottom: 8,
                                        marginLeft: "-1rem",
                                      }}
                                      align="baseline"
                                    >
                                      <Form.Item
                                        style={{ display: "none" }}
                                        {...restField}
                                        name={[name, "id"]}
                                        initialValue={null}
                                      ></Form.Item>
                                      <Form.Item
                                        style={{ display: "none" }}
                                        {...restField}
                                        name={[name, "product_ID"]}
                                        initialValue={null}
                                      ></Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "size"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Missing pack size ",
                                          },
                                        ]}
                                      >
                                        <Input placeholder="" />
                                      </Form.Item>
                                      <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => add()}
                                      block
                                      style={{ width: "15rem", float: "left" }}
                                      icon={<PlusOutlined />}
                                    >
                                      Add More Pack Sizes
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </Row>
                        </>
                      ) : null
                    }
                  </Form.Item> */}
                    <div style={{ marginTop: "3rem" }}>
                      <div>
                        <div>
                          <div className="text-2xl font-bold float-left">Indications</div>
                          <br></br>
                          {/* <br></br>
                        <div className="text-sm font-normal float-left">
                          Specify the different indications to be included within the simulator
                        </div>
                        <br></br> */}
                          <br></br>
                        </div>
                      </div>

                      <div>
                        {inputFields.map((inputField: any, index: any) => (
                          <div>
                            <Collapse
                              collapsible="header"
                              style={{ width: "72rem" }}
                              key={"col" + inputField.uuid}
                            >
                              <Panel
                                key={"Panel" + inputField.uuid}
                                header={
                                  <p className="font-semibold">{"Indication #" + (index + 1)}</p>
                                }
                                extra={
                                  <div style={{ width: "62rem" }}>
                                    <Form.Item
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing Indication Name",
                                        },
                                      ]}
                                    >
                                      <Input
                                        value={inputField.indicationName}
                                        // key={"indicationName" + inputField.uuid}
                                        name={"indicationName"}
                                        style={{
                                          width: "11rem",
                                          marginLeft: "-4rem",
                                          height: "30px",
                                        }}
                                        onChange={(event) => handleInputChange(index, event, "sku")}
                                        placeholder="Enter Indication Name"
                                      />
                                    </Form.Item>
                                    <Popconfirm
                                      placement="topLeft"
                                      title={"Confirm Delete?"}
                                      onConfirm={() => {
                                        // confirm(inputField.indication_ID);
                                        deleteRow(inputField.indication_ID);
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Bin2
                                        color="black"
                                        size={15}
                                        style={{
                                          marginTop: "-46px",
                                          marginLeft: "2px",
                                          marginRight: "0.5rem",
                                          float: "right",
                                        }}
                                      />
                                    </Popconfirm>
                                  </div>
                                }
                              >
                                {/* <div className="float-left padding-bott">SKU - 1</div> */}
                                <div form-group>
                                  <div
                                    key={"div2" + inputField.uuid}
                                    className="form-group col-sm-4"
                                    style={{ width: "46%" }}
                                  >
                                    <Col span={12}>
                                      <label
                                        style={{ marginLeft: "-5rem", width: "78%" }}
                                        key={"lbl2" + inputField.uuid}
                                        htmlFor="selectedDosageType"
                                      >
                                        Dosage Type
                                      </label>
                                      <select
                                        key={"selectedDosageType" + inputField.uuid}
                                        name="selectedDosageType"
                                        className="form-control"
                                        value={inputField.selectedDosageType}
                                        onChange={(event) =>
                                          handleInputChange(index, event, inputField.uuid)
                                        }
                                      >
                                        <option> Select</option>
                                        <option value="1002">Weight-Based</option>
                                        <option value="1001">Fixed</option>
                                      </select>
                                    </Col>
                                    <Col
                                      span={12}
                                      style={{ marginTop: "-3.7rem", marginLeft: "20rem" }}
                                    >
                                      <label htmlFor="isYearly">Time Period</label>
                                      <select
                                        key={"isYearly" + inputField.uuid}
                                        name="isYearly"
                                        className="form-control"
                                        value={inputField.isYearly}
                                        onChange={(event) =>
                                          handleInputChange(index, event, inputField.uuid)
                                        }
                                      >
                                        <option style={{ display: "none" }} disabled selected>
                                          -- select an option --
                                        </option>
                                        <option value="true">One Year (Yearly Repeating)</option>
                                        <option value="false"> More than 1 year</option>
                                      </select>
                                    </Col>
                                  </div>

                                  {inputFields2["sku1"]
                                    .filter((x: any) => x.indicationID === inputField.indication_ID)
                                    .map((inputField2: any) => (
                                      <>
                                        <p
                                          className="font-semibold mt-4"
                                          style={{ marginLeft: "-66rem" }}
                                        >
                                          SKU - 1
                                        </p>

                                        {inputField.selectedDosageType === "1002" && (
                                          <div key={"div3" + inputField.uuid}>
                                            <Row>
                                              <Col span={7}>
                                                <Form.Item
                                                  style={{ width: "15rem" }}
                                                  label="Average weight of Patient"
                                                  initialValue=""
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Missing Patient Weight",
                                                    },
                                                  ]}
                                                >
                                                  <Input
                                                    defaultValue={inputField2.avgWtOfPatient}
                                                    onChange={(event) =>
                                                      handleInputChange(index, event, "sku1")
                                                    }
                                                    name={"SKU1_avgWtOfPatient"}
                                                    placeholder=""
                                                  />
                                                </Form.Item>
                                              </Col>
                                              <Col span={7}>
                                                <Form.Item
                                                  style={{ width: "15rem" }}
                                                  label="Prescribed Dose (per kg)"
                                                  initialValue=""
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Missing Prescribed Dose",
                                                    },
                                                  ]}
                                                >
                                                  <Input
                                                    defaultValue={inputField2.prescribedDose}
                                                    onChange={(event) =>
                                                      handleInputChange(index, event, "sku1")
                                                    }
                                                    name={"SKU1_prescribedDose"}
                                                    placeholder=""
                                                  />
                                                </Form.Item>
                                              </Col>
                                              <Col span={7}>
                                                <Form.Item
                                                  style={{ width: "15rem" }}
                                                  label="No. of Units for Recommended Dosage"
                                                  initialValue=""
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Missing Volume of Product per Unit",
                                                    },
                                                  ]}
                                                >
                                                  <Input
                                                    defaultValue={
                                                      inputField2.volumeofProductPerUnit
                                                    }
                                                    onChange={(event) =>
                                                      handleInputChange(index, event, "sku1")
                                                    }
                                                    name={"SKU1_volumeofProductPerUnit"}
                                                    placeholder=""
                                                  />
                                                </Form.Item>
                                              </Col>
                                            </Row>
                                          </div>
                                        )}

                                        {inputField.selectedDosageType === "1001" && (
                                          <>
                                            <Col span={7}>
                                              <Form.Item
                                                style={{ width: "15rem" }}
                                                label="No. of units per dose"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Missing Volume of Product per Unit",
                                                  },
                                                ]}
                                              >
                                                <Input
                                                  value={inputField2.noOfUnitsPerDose}
                                                  onChange={(event) =>
                                                    handleInputChange(index, event, "sku1")
                                                  }
                                                  name={"SKU1_noOfUnitsPerDose"}
                                                  placeholder=""
                                                />
                                              </Form.Item>
                                            </Col>
                                          </>
                                        )}
                                      </>
                                    ))}
                                </div>
                                <div>
                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                      prevValues.dosageForms !== currentValues.dosageForms
                                    }
                                  >
                                    {({ getFieldValue }) =>
                                      getFieldValue("dosageForms") === "2" ? (
                                        <>
                                          <p
                                            className="font-semibold mt-4"
                                            style={{ marginLeft: "-66rem" }}
                                          >
                                            SKU - 2
                                          </p>
                                          {inputFields2["sku2"]
                                            .filter(
                                              (x: any) =>
                                                x.indicationID === inputField.indication_ID
                                            )
                                            .map((inputField2: any) => (
                                              <>
                                                {inputField.selectedDosageType === "1002" && (
                                                  <div key={"div3" + inputField.uuid}>
                                                    <Row>
                                                      <Col span={7}>
                                                        <Form.Item
                                                          style={{ width: "15rem" }}
                                                          label="Average weight of Patient"
                                                          initialValue=""
                                                          rules={[
                                                            {
                                                              required: true,
                                                              message: "Missing Patient Weight",
                                                            },
                                                          ]}
                                                        >
                                                          <Input
                                                            value={inputField2.avgWtOfPatient}
                                                            onChange={(event) =>
                                                              handleInputChange(
                                                                index,
                                                                event,
                                                                "sku2"
                                                              )
                                                            }
                                                            name={"SKU2_avgWtOfPatient"}
                                                            placeholder=""
                                                          />
                                                        </Form.Item>
                                                      </Col>
                                                      <Col span={7}>
                                                        <Form.Item
                                                          style={{ width: "15rem" }}
                                                          label="Prescribed Dose (per kg)"
                                                          initialValue=""
                                                          rules={[
                                                            {
                                                              required: true,
                                                              message: "Missing Prescribed Dose",
                                                            },
                                                          ]}
                                                        >
                                                          <Input
                                                            value={inputField2.prescribedDose}
                                                            onChange={(event) =>
                                                              handleInputChange(
                                                                index,
                                                                event,
                                                                "sku2"
                                                              )
                                                            }
                                                            name={"SKU2_prescribedDose"}
                                                            placeholder=""
                                                          />
                                                        </Form.Item>
                                                      </Col>
                                                      <Col span={7}>
                                                        <Form.Item
                                                          style={{ width: "15rem" }}
                                                          label="No. of Units for Recommended Dosage"
                                                          initialValue=""
                                                          rules={[
                                                            {
                                                              required: true,
                                                              message:
                                                                "Missing Volume of Product per Unit",
                                                            },
                                                          ]}
                                                        >
                                                          <Input
                                                            value={
                                                              inputField2.volumeofProductPerUnit
                                                            }
                                                            // defaultValue={
                                                            //   inputField.volumeofProductPerUnit
                                                            // }
                                                            onChange={(event) =>
                                                              handleInputChange(
                                                                index,
                                                                event,
                                                                "sku2"
                                                              )
                                                            }
                                                            name={"SKU2_volumeofProductPerUnit"}
                                                            placeholder=""
                                                          />
                                                        </Form.Item>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                )}

                                                {inputField.selectedDosageType === "1001" && (
                                                  <>
                                                    <Col span={7}>
                                                      <Form.Item
                                                        style={{ width: "15rem" }}
                                                        label="No. of units per dose"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message:
                                                              "Missing Volume of Product per Unit",
                                                          },
                                                        ]}
                                                      >
                                                        <Input
                                                          value={inputField2.noOfUnitsPerDose}
                                                          onChange={(event) =>
                                                            handleInputChange(index, event, "sku2")
                                                          }
                                                          name={"SKU2_noOfUnitsPerDose"}
                                                          placeholder=""
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                  </>
                                                )}
                                              </>
                                            ))}
                                        </>
                                      ) : (
                                        <></>
                                      )
                                    }
                                  </Form.Item>
                                </div>
                              </Panel>
                            </Collapse>
                          </div>
                        ))}
                      </div>

                      <Form.Item>
                        <Button
                          type="text"
                          className="mt-8"
                          onClick={function (event) {
                            handleAddFields();
                            // add();
                          }}
                          block
                          icon={<AddCircle className="mr-4" size={30} color={"#27a6a4"} />}
                        >
                          Add New Indication
                        </Button>
                      </Form.Item>

                      <br />
                      <br />

                      {/* <pre>{JSON.stringify(inputFields, null, 2)}</pre> */}

                      <div className="text-base font-bold float-left text-xl  ">
                        Dosing Schedule
                      </div>
                      <div
                        className="mt-14 font-semibold -ml-36 float-left"
                        style={{ color: "#75737D" }}
                      >
                        {dosage1} of {prodType}
                      </div>
                      {console.log(fetchedData)}
                      <ViewOnlyTable
                        fetchedData={fetchedData}
                        SetFetchedData={SetFetchedData}
                        counter={counter}
                        key="table1"
                        id="table1"
                        valueTable="sku1"
                      />
                    </div>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.dosageForms !== currentValues.dosageForms
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue("dosageForms") === "2" ? (
                          <>
                            <div
                              className="ml-4 mt-14 font-semibold  float-left"
                              style={{ color: "#75737D" }}
                            >
                              {dosage2} of {prodType}
                            </div>
                            <ViewOnlyTable
                              fetchedData={fetchedData}
                              SetFetchedData={SetFetchedData}
                              id="table2"
                              key="table2"
                              valueTable="sku2"
                            />
                          </>
                        ) : (
                          <></>
                        )
                      }
                    </Form.Item>

                    <Form.Item style={{ marginLeft: "-5.5rem", marginTop: "2rem" }}>
                      <Button className="mb-4" type="primary" htmlType="submit">
                        Next
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      )}
    </div>
  );
}
