import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBump } from "@nivo/bump";
// const data = [
//   {
//     id: "KM3",
//     color: "hsl(37, 70%, 50%)",
//     data: [
//       {
//         x: "2022",
//         y: 187,
//       },
//       {
//         x: "2023",
//         y: 288,
//       },
//       {
//         x: "2024",
//         y: 116,
//       },
//       {
//         x: "2025",
//         y: 22,
//       },
//       {
//         x: "2026",
//         y: 113,
//       },
//       {
//         x: "2027",
//         y: 102,
//       },
//       {
//         x: "2028",
//         y: 288,
//       },
//       {
//         x: "2029",
//         y: 184,
//       },
//       {
//         x: "2030",
//         y: 273,
//       },
//       {
//         x: "2031",
//         y: 50,
//       },
//       {
//         x: "2032",
//         y: 103,
//       },
//       {
//         x: "2033",
//         y: 224,
//       },
//     ],
//   },
//   {
//     id: "KM4",
//     color: "hsl(63, 70%, 50%)",
//     data: [
//       {
//         x: "2022",
//         y: 226,
//       },
//       {
//         x: "2023",
//         y: 118,
//       },
//       {
//         x: "2024",
//         y: 146,
//       },
//       {
//         x: "2025",
//         y: 137,
//       },
//       {
//         x: "2026",
//         y: 225,
//       },
//       {
//         x: "2027",
//         y: 70,
//       },
//       {
//         x: "2028",
//         y: 108,
//       },
//       {
//         x: "2029",
//         y: 84,
//       },
//       {
//         x: "2030",
//         y: 195,
//       },
//       {
//         x: "2031",
//         y: 192,
//       },
//       {
//         x: "2032",
//         y: 211,
//       },
//       {
//         x: "2033",
//         y: 123,
//       },
//     ],
//   },
//   {
//     id: "KM5",
//     color: "hsl(127, 70%, 50%)",
//     data: [
//       {
//         x: "2022",
//         y: 183,
//       },
//       {
//         x: "2023",
//         y: 296,
//       },
//       {
//         x: "2024",
//         y: 186,
//       },
//       {
//         x: "2025",
//         y: 262,
//       },
//       {
//         x: "2026",
//         y: 179,
//       },
//       {
//         x: "2027",
//         y: 2,
//       },
//       {
//         x: "2028",
//         y: 38,
//       },
//       {
//         x: "2029",
//         y: 133,
//       },
//       {
//         x: "2030",
//         y: 177,
//       },
//       {
//         x: "2031",
//         y: 284,
//       },
//       {
//         x: "2032",
//         y: 256,
//       },
//       {
//         x: "2033",
//         y: 290,
//       },
//     ],
//   },
//   {
//     id: "KM2",
//     color: "hsl(195, 70%, 50%)",
//     data: [
//       {
//         x: "2022",
//         y: 9,
//       },
//       {
//         x: "2023",
//         y: 281,
//       },
//       {
//         x: "2024",
//         y: 47,
//       },
//       {
//         x: "2025",
//         y: 235,
//       },
//       {
//         x: "2026",
//         y: 106,
//       },
//       {
//         x: "2027",
//         y: 156,
//       },
//       {
//         x: "2028",
//         y: 273,
//       },
//       {
//         x: "2029",
//         y: 72,
//       },
//       {
//         x: "2030",
//         y: 11,
//       },
//       {
//         x: "2031",
//         y: 52,
//       },
//       {
//         x: "2032",
//         y: 133,
//       },
//       {
//         x: "2033",
//         y: 184,
//       },
//     ],
//   },
//   {
//     id: "KM1",
//     color: "hsl(189, 70%, 50%)",
//     data: [
//       {
//         x: "2022",
//         y: 283,
//       },
//       {
//         x: "2023",
//         y: 39,
//       },
//       {
//         x: "2024",
//         y: 117,
//       },
//       {
//         x: "2025",
//         y: 53,
//       },
//       {
//         x: "2026",
//         y: 53,
//       },
//       {
//         x: "2027",
//         y: 212,
//       },
//       {
//         x: "2028",
//         y: 234,
//       },
//       {
//         x: "2029",
//         y: 209,
//       },
//       {
//         x: "2030",
//         y: 55,
//       },
//       {
//         x: "2031",
//         y: 194,
//       },
//       {
//         x: "2032",
//         y: 29,
//       },
//       {
//         x: "2033",
//         y: 230,
//       },
//     ],
//   },
// ];

const data = [
  {
    id: "OS",
    color: "hsl(37, 70%, 50%)",
    data: [
      {
        x: 1,
        y: 0,
      },
      {
        x: 2,
        y: 0,
      },
      {
        x: 3,
        y: 0,
      },
      {
        x: 4,
        y: 0,
      },
      {
        x: 5,
        y: 0,
      },
      {
        x: 6,
        y: 0,
      },
      {
        x: 7,
        y: 0,
      },
      {
        x: 8,
        y: 0,
      },
      {
        x: 9,
        y: 0,
      },
      {
        x: 10,
        y: 0,
      },
      {
        x: 11,
        y: 0,
      },
      {
        x: 12,
        y: 0,
      },
      {
        x: 13,
        y: 0,
      },
      {
        x: 14,
        y: 0,
      },
      {
        x: 15,
        y: 0,
      },
      {
        x: 16,
        y: 0,
      },
      {
        x: 17,
        y: 0,
      },
      {
        x: 18,
        y: 0,
      },
      {
        x: 19,
        y: 0,
      },
      {
        x: 20,
        y: 0,
      },
      {
        x: 21,
        y: 0,
      },
      {
        x: 22,
        y: 0,
      },
      {
        x: 23,
        y: 0,
      },
      {
        x: 24,
        y: 0,
      },
      {
        x: 25,
        y: 0,
      },
      {
        x: 26,
        y: 0,
      },
      {
        x: 27,
        y: 0,
      },
      {
        x: 28,
        y: 0,
      },
      {
        x: 29,
        y: 0,
      },
      {
        x: 30,
        y: 0,
      },
      {
        x: 31,
        y: 0,
      },
      {
        x: 32,
        y: 0,
      },
      {
        x: 33,
        y: 0,
      },
      {
        x: 34,
        y: 0,
      },
      {
        x: 35,
        y: 0,
      },
      {
        x: 36,
        y: 0,
      },
      {
        x: 37,
        y: 0,
      },
      {
        x: 38,
        y: 0,
      },
      {
        x: 39,
        y: 0,
      },
      {
        x: 40,
        y: 0,
      },
    ],
  },
];
export default function KmCurveViz(props: { data: any; curveTypeSelected?: any }) {
  let dataForChart = props.data;
  console.log(dataForChart, props.curveTypeSelected);
  return (
    <div style={{ height: 420, maxWidth: "100%" }}>
      <ResponsiveLine
        data={dataForChart}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        curve="cardinal"
        enableGridX={false}
        axisBottom={{
          // orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "year",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          // orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "count",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}
