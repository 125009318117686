import { Button, Checkbox, Divider, Form, Input, InputNumber, Select, Tag } from "antd";
import React from "react";
import ConstructTable from "../ConstructThresholdTable.tsx/table";
import { Card, Col, Row } from "antd";
import "../ConstructPage.tsx/construct.css";
import CostCapitationTable from "../ConstructThresholdTable.tsx/table2";
import CostCapitationTable2 from "../ConstructThresholdTable.tsx/table3";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ["Sub1", "Sub2", "Sub3"];
const plainOptions2 = ["Sub1", "Sub2"];

const defaultCheckedList = ["Sub1", "Sub3"];
const defaultCheckedList2 = ["Sub1"];

export default function Construct(props: { type: any; key: any }) {
  const [form] = Form.useForm();

  const [outcomeMeasure, setOutcomeMeasure] = React.useState<boolean>(true);
  const [constructLevel, setConstructLevel] = React.useState<boolean>(true);
  const [rebateType, setRebateType] = React.useState<boolean>();

  const handleChange = (value: any, fieldName: any) => {
    console.log(value, fieldName);
    if (fieldName === "outcomeType") {
      setOutcomeMeasure(value);
    }

    if (fieldName === "rebateType") {
      setRebateType(value);
    }

    if (fieldName === "constructLevel") {
      setConstructLevel(value);
    }

    if (fieldName === "rebateParameter") {
      setParameterName(value);
    }
  };

  const [checkedList, setCheckedList] = React.useState<any>(defaultCheckedList);
  // const [checkedList2, setCheckedList2] = React.useState<any>(defaultCheckedList2);
  const [parameterName, setParameterName] = React.useState("");
  const [indeterminate, setIndeterminate] = React.useState(true);
  const onChange = (list: any) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    // setCheckAll(list.length === plainOptions.length);
  };

  const finish = (values: any) => {
    console.log(values);
  };

  return (
    <div className="back">
      <div className="p-4 ">
        <Form
          key={props.key}
          layout="horizontal"
          {...layout}
          form={form}
          name="constructSetup1"
          onFinish={finish}
        >
          <Row className="ml-2">
            <Col span={8}>
              <Form.Item
                name="constructSelectNew"
                label="Select Construct"
                rules={[{ required: true }]}
                style={{ marginLeft: "1.5rem" }}
              >
                <Select
                  onChange={(e) => handleChange(e, "rebateParameter")}
                  placeholder="Select outcome measure"
                  allowClear
                  // disabled={true}
                  defaultValue={
                    props.type === "1" ? "Value Warranty - New Patients" : "Cost Capitation"
                  }
                >
                  <Option value={"Value Warranty - New Patients"}>
                    Value Warranty - New Patients
                  </Option>
                  <Option value={"Value Warranty - Continuing Patients"}>
                    Value Warranty - Continuing Patients
                  </Option>
                  <Option value={"Tiered Pricing"}>Tiered Pricing</Option>
                  <Option value={"Cost Capitation"}> Cost Capitation</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <h4 className="text-2xl">
            {props.type === "1" ? (
              <p style={{ marginLeft: "-48rem" }}> Value Warranty - New Patients </p>
            ) : (
              <p style={{ marginLeft: "-58rem" }}>Cost Capitation</p>
            )}
          </h4>

          <br></br>
        </Form>
        {props.type === "1" ? (
          <Form
            // id={props.id}
            layout="vertical"
            {...layout}
            form={form}
            name="constructSetup2"
            initialValues={{
              outcomeType: 1,
              outcomeMeasure: 2,
              rebateEligibility: "20",
              constructType: true,
              rebateType: true,
              extraInfo: "Extra information about the construct here",
            }}
          >
            <Row className="ml-8">
              <Col span={8}>
                <Form.Item name="outcomeType" label="Outcome Measure" rules={[{ required: true }]}>
                  <Select
                    onChange={(e) => handleChange(e, "outcomeType")}
                    placeholder="Select outcome measure"
                    allowClear
                  >
                    <Option value={1}> Yes </Option>
                    <Option value={0}> No </Option>
                  </Select>
                </Form.Item>
              </Col>

              {outcomeMeasure ? (
                <>
                  {/* <Row className="ml-8"> */}
                  <Col span={8}>
                    <Form.Item
                      name="outcomeMeasure"
                      label="Select outcome measure"
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Select outcome measure" allowClear>
                        <Option value={1}> OCS Reduction </Option>
                        <Option value={2}> Hospitalization due to severe flare </Option>
                        <Option value={3}> Adverse Event – Severe flare </Option>
                        <Option value={4}>Adverse Event – Atrial fibrillation</Option>
                        <Option value={5}>Adverse Event – Bleeding </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="rebateEligibility"
                      label="New patient eligible for rebate as per outcome"
                      rules={[{ required: true }]}
                    >
                      <InputNumber addonAfter="%" />
                    </Form.Item>
                  </Col>
                  {/* </Row> */}
                </>
              ) : (
                <></>
              )}

              <Col span={8}>
                <Form.Item
                  name="constructType"
                  label="Apply Construct at"
                  rules={[{ required: true }]}
                >
                  <Select
                    onChange={(e) => handleChange(e, "constructLevel")}
                    placeholder="Select construct level"
                    allowClear
                  >
                    <Option value={true}> Indication Level </Option>
                    <Option value={false}> Contract Level </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="rebateType" label="Type of rebate" rules={[{ required: true }]}>
                  <Select
                    onChange={(e) => handleChange(e, "rebateType")}
                    placeholder="Select rebate type"
                    allowClear
                  >
                    <Option value={true}> Rebate % </Option>
                    <Option value={false}> Discrete Rebate Amount </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Divider />
            </Row>
            {props.type === "1" && (
              <>
                {constructLevel ? (
                  <>
                    {props.type === "1" ? (
                      <>
                        {" "}
                        <div className="site-card-wrapper">
                          <Row gutter={16}>
                            <Col span={12}>
                              <Card
                                title={
                                  <>
                                    <span>Indication : </span>
                                    <Tag color="cyan">IND1</Tag>
                                  </>
                                }
                                bordered={false}
                              >
                                <ConstructTable key={1} rebateType={rebateType} />
                              </Card>
                            </Col>
                            <Col span={12}>
                              <Card
                                title={
                                  <>
                                    <span>Indication : </span>
                                    <Tag color="cyan">IND2</Tag>
                                  </>
                                }
                                bordered={false}
                              >
                                <ConstructTable key={2} rebateType={rebateType} />
                              </Card>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ) : (
                      <div className="ml-8">
                        <ConstructTable key={3} rebateType={rebateType} />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="ml-8">
                    <ConstructTable key={3} rebateType={rebateType} />
                  </div>
                )}
              </>
            )}

            {props.type === "2" && (
              <>
                {constructLevel ? (
                  <>
                    <CostCapitationTable2 />
                  </>
                ) : (
                  <>
                    <CostCapitationTable />
                  </>
                )}
              </>
            )}

            <Row>
              <Col className="ml-8 mt-12" span={12}>
                <Form.Item
                  name="extraInfo"
                  label="Additional Considerations"
                  // rules={[{ required: true }]}
                >
                  <Input.TextArea
                    className="textArea1"
                    placeholder="Enter additional consideration here"
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          <>
            <Form
              // id={props.id}
              layout="vertical"
              {...layout}
              form={form}
              name="constructSetup2"
              initialValues={{
                outcomeType: 1,
                outcomeMeasure: 2,
                rebateEligibility: "20",
                constructType: true,
                rebateType: true,
                extraInfo: "Extra information about the construct here",
              }}
            >
              <Row className="ml-8">
                <Col span={8}>
                  <Form.Item
                    name="constructType"
                    label="Apply Construct at"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(e) => handleChange(e, "constructLevel")}
                      placeholder="Select construct level"
                      allowClear
                    >
                      <Option value={true}> Indication Level </Option>
                      <Option value={false}> Contract Level </Option>
                    </Select>
                  </Form.Item>
                </Col>
                {props.type === "1" && (
                  <>
                    {constructLevel ? (
                      <>
                        {props.type === "1" ? (
                          <>
                            {" "}
                            <div className="site-card-wrapper">
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Card
                                    title={
                                      <>
                                        <span>Indication : </span>
                                        <Tag color="cyan">IND1</Tag>
                                      </>
                                    }
                                    bordered={false}
                                  >
                                    <ConstructTable key={1} rebateType={rebateType} />
                                  </Card>
                                </Col>
                                <Col span={12}>
                                  <Card
                                    title={
                                      <>
                                        <span>Indication : </span>
                                        <Tag color="cyan">IND2</Tag>
                                      </>
                                    }
                                    bordered={false}
                                  >
                                    <ConstructTable key={2} rebateType={rebateType} />
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : (
                          <div className="ml-8">
                            <ConstructTable key={3} rebateType={rebateType} />
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="ml-8">
                        <ConstructTable key={3} rebateType={rebateType} />
                      </div>
                    )}
                  </>
                )}

                {props.type === "2" && (
                  <>
                    {constructLevel ? (
                      <>
                        <CostCapitationTable2 />
                      </>
                    ) : (
                      <>
                        <CostCapitationTable />
                      </>
                    )}
                  </>
                )}

                <Row>
                  <Col className="ml-8 mt-12" span={12}>
                    <Form.Item
                      name="extraInfo"
                      label="Additional Considerations"
                      // rules={[{ required: true }]}
                    >
                      <Input.TextArea
                        className="textArea1"
                        placeholder="Enter additional consideration here"
                        rows={4}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Row>
            </Form>
          </>
        )}

        <div className="mt-16">
          {/* <Button className=" float-left" onClick={goPrevious}>
            Previous
          </Button> */}
          <Form form={form} name="pricingTab">
            <Form.Item>
              <Button type="primary" htmlType="submit" className="-ml-28">
                Save Inputs
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
