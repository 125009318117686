import {
  getAllProductDetailsData,
  getEmptyProductData,
  getProductDetailsData,
  saveProductDetailsData,
} from "../../../services/SimulatorConfiguration";

export const saveProductDetails: (isUpdate: boolean, value: any) => Promise<unknown> = async (
  isUpdate,
  value
) => {
  const data = await saveProductDetailsData(isUpdate, value);

  if (data === null || data === undefined) return null;
  return data;
};

export const getAllProductDetails: () => Promise<unknown> = async () => {
  const data = await getAllProductDetailsData();

  if (data === null || data === undefined) return null;

  return data;
};

export const getProductDetails: (productId: string) => Promise<unknown> = async (productId) => {
  const data = await getProductDetailsData(productId);

  if (data === null || data === undefined) return null;

  return data;
};


export const getEmptyProduct: () => Promise<unknown> = async () => {
  const data = await getEmptyProductData();

  if (data === null || data === undefined) return null;

  return data;
};
